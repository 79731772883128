import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Place } from '../../../models/workplace/place.model';
import { Offer } from '../../../models/workplace/offer.model';
import { CardInfo, MyspotCardComponent } from '../myspot-card.component';
import { initBooleanInput } from '../../../util/utils';
import { Zone, ZoneType } from '../../../models/workplace/zone.model';
import { ILodgingBusiness } from '../../../models/workplace/lodging-business.model';

@Component({
  selector: 'ms-place-card',
  templateUrl: './place-card.component.html',
  styleUrls: ['../myspot-card.component.scss', './place-card.component.scss']
})
export class PlaceCardComponent extends MyspotCardComponent implements OnInit, AfterViewInit {

  @Input() place: Place;
  @Input() offer: Offer;
  @Input() fullyAvailable: boolean;
  @Input() showDetails: boolean;
  @Input() showOffer: boolean;
  // @Input() disabled: boolean;

  @ViewChild('cardTitle', { static: false }) cardTitle: ElementRef;
  @ViewChild('cardAddress', { static: false }) cardAddress: ElementRef;
  @ViewChild('cardBody', { static: false }) cardBody: ElementRef;

  titleToScroll = false;
  addressToScroll = false;

  floor: Zone;
  lodging: ILodgingBusiness;

  disabled: boolean = true;
  ngOnInit(): void {
    this.info = this.place as CardInfo;
    this.showDetails = initBooleanInput(this.showDetails);
    this.showOffer = initBooleanInput(this.showOffer);

    if (this.place.containedInPlace) {
      this.lodging = this.place.containedInPlace;
    }

    if (this.place.containedInZones?.length) {
      this.floor = this.place.containedInZones.find(z => z.zoneType = ZoneType.FLOOR);
    }
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkScrollable();
    }, 1000);

  }

  checkScrollable(): void {
    const cardBodyW = this.cardBody.nativeElement.getBoundingClientRect().width;
    if (this.titleToScroll) {
      this.titleToScroll = this.cardTitle.nativeElement.getBoundingClientRect().width > cardBodyW - 30;
    }

    if (this.cardAddress) {
      this.addressToScroll = this.cardAddress.nativeElement.getBoundingClientRect().width > cardBodyW - 30;
    }
  }

}

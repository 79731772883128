<img
    *ngIf="!person?.photo; else avatar;"
    alt="{{person.displayName}}"
    class="ms-avatar m-0"
    [ngStyle]="{
        'width.px': size,
        'min-width.px': size,
        'height.px': size,
        'line-height': size
        }"
    src="assets/images/avatars/profile.jpg" >

<!--
{{object?.givenName | slice:0:1}}{{object?.surname | slice:0:1}} -->

<ng-template #avatar>
    <img
        alt="{{person.displayName}}"
        class="ms-avatar m-0"
        [ngStyle]="{
        'width.px': size,
        'min-width.px': size,
        'height.px': size,
        'line-height': size
        }"
        src="{{CLOUDINARY_IMG_URL}}/c_fill,g_center,h_{{size}},w_{{size}}/{{person.photo}}">
</ng-template>

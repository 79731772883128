<div class="card-container">
    <div class="check"><mat-icon >check</mat-icon></div>
    <div class="fuse-card place-card">
        <div class="position-relative">
            <img src="/assets/images/placeholders/{{offer.additionalType}}-{{offer.priceCurrency}}.jpg" class="card-img">
            <div class="position-absolute card-title p-16 align-left align-bottom">
                <ng-container *ngIf="offer.name?.length > 20; else normalTitle">
                <span class="scroll">
                    {{offer.name}}
                </span>
                </ng-container>

                <ng-template #normalTitle>
                    {{offer.name}}
                </ng-template>
            </div>
        </div>


        <div class="p-8 line-height-1.75">
            {{offer.totalPrice}}{{offer.priceCurrency | translate}} | {{offer.priceUnit | translate}}
        </div>
        <div class="p-8 line-height-1.75">
            {{offer.description}} - {{offer.offeredBy?.name}}
        </div>

        <div *ngIf="options?.showButtons" class="p-16 pt-0" fxLayout="row" fxLayoutAlign="end top" fxLayoutGap="5px">
            <button mat-icon-button color="accent" (click)="modify()">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="delete()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</div>

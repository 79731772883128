import { IMeetingRoom } from '../../models/workplace/meeting-room.model';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IDesk } from '../../models/workplace/desk.model';
import { PlaceAPIService } from './place.service';
import {IEquipment} from '../../models/workplace/equipment.model';


@Injectable()
export class MeetingRoomAPIService extends PlaceAPIService {

    protected resourceUrl = '/myspotworkplaceservice';
    protected link = '/api/meeting-rooms';

    constructor(protected http: HttpClient) {
        super(http);
    }

    get(filter?, sortBy?: string, sortDirection?: string, page?: number, size?: number, link?: string, params?: HttpParams): Observable<IMeetingRoom[]> {
        return super.get(filter, sortBy, sortDirection, page, size, link, params);
    }

    getById(id: number): Observable<IMeetingRoom> {
        return super.getById(id);
    }

    getByParams(params: HttpParams): Observable<IMeetingRoom[]>{
        return super.getByParams(params);
      }

    save(place: IMeetingRoom): Observable<IMeetingRoom>{
        return super.save(place);
    }

    query(req?: any): Observable<IMeetingRoom[]>  {
        return super.query(req);
    }

    enableEquipment(roomId: number, eq: IEquipment): Observable<IEquipment> {
        return this.http.post(this.resourceUrl + this.link + `/${roomId}/equipment`, eq);
    }

}

export const locale = {
    lang: 'en',
    data: {
        'INTERFACE': {
            SAVE: 'Save',
            ADD: 'Add',
            NEW: 'New',
            DETAIL: 'Detail',
            CONFIRM: 'Confirm',
            CONFIRMATION: 'I confirm',
            CANCEL: 'Cancel',
            CLEAR: 'Clear',
            GO_BACK: 'Go back',
            DELETE: 'Delete',
            DELETE_ALL: 'Delete All',
            REJECT: 'Reject',
            REJECT_ALL: 'Reject All',
            REJECT_SELECTED: 'Reject Selected',
            EDIT: 'Edit',
            UPDATE: 'Update'
        },
        ROOM_PANEL: {
          RESERVED: 'Reserved',
          FREE: 'Available',
          UNTIL: 'Until',
          MEETING_IN_PROGRESS: 'Meeting in progress',
          NEXT_MEETING: 'Next Meeting',
          CLOSED: 'Closed',
          TODAY_OPENING_HOURS: 'Today\'s opening hours',
          DAILY_CLOSURE: 'Daily closure',
          LOOKING: 'Looking for new bookings...',
          NEW_MEETING: 'New Meeting',
          TITLE_LABEL: 'Title',
          DAY_LABEL: 'Day',
          START_LABEL: 'Start',
          END_LABEL: 'End',
          TRY_ANOTHER_DATE: 'Closed, try another day',
          CONFIRM_WITH: 'Confirm with the app',
          OPEN: 'Open the app',
          USE_READER_FUNCTIONALITY: 'Use the functionality',
          READER: 'Reader',
          SCAN_CODE: 'Scan the code to confirm',
          BACK: 'Back',
          DOWNLOAD_APP: 'Download the app',
          PANEL_RESET_TIMER: 'Panel reset timer',
          NEXT: 'Next'
        },
        'CAN_LEAVE': {
            TITLE: 'Warning!',
            BODY: 'Do you want to leave without saving?',
            NO: 'No Thanks',
            OK: 'Leave now',
        },
        'OFFERS': {
            OFFER: 'Offer'
        },
        'TUTS': {
            ORGANIZATION: {
                BASIC: 'Write here your Organization basic info',
                CONTACT: 'Let we know Who is the reference person we will contact',
                LODGIN: 'Here you can write your Lodging Businesses',
                SAVE: 'Hey, don\t forget to save your data!',
                HELP: 'If your want to rewatch the Help, click here',
            },
            LODGING: {
                BASIC: 'The basic information of your Lodging Business',
                SEARCH: 'Use Google to find your place. If it can\'t find it, you can always write the address manually',
                PHOTO: 'Let you know how beautiful your work place is, thanks to the magnificent photo shots',
                FEAT: 'What features does your place have?',
                HOURS: 'Opening and closing times',
                SPECIAL: 'At Christmas, do you want to stay with your family? Here you can write special opening and closing times',
                SAVE: 'Hey, don\t forget to save your data!',
                MEETS: 'Once you have created your place, you can create your own spaces here',
                OFFERS: 'And here is your price list',
            },
            MEET: {
                BASIC: 'The information of your space',
                QUICK: 'Activate the option if you want your customers to book in a flash',
                PHOTO: 'Create the photo album of your space. You customers will love it',
                OFFERS: 'Here you will assign price lists to the space',
                FEAT: 'What are the features of your space?',
                HOURS: 'Schedules for booking this room',
                SPECIAL: 'Are there any special days or periods when you need to indicate a different time? You can enter them here',
                SAVE: 'Hey, don\t forget to save your data!',
            },
            OFFER: {
                BASIC: 'The information on your price list',
                UNIT: 'The unit of time with which you want to reserve your space for this price',
                LIMITED: 'If it\'s a limited offer, activate the time period',
                ACTIVITIES: 'For which activities is this offer valid?',
                ITEMS: 'The spaces connected to this offer',
                SAVE: 'Hey, don\t forget to save your data!',
            }
        },
        'meeting-room': 'Meeting Room',
        'desk': 'Desk',
        'parking-slot': 'Parking slot',
        'presence': 'Presence',
        'PARKING_SLOT': 'Parking slot',
        'PARKING_FACILITY': 'Parking facility',
        'MEETINGROOM': 'Meeting room',
        'DESK': 'Desk',
        'SEAT': 'Presence',
        'SPOT': 'Spot',
        'EVENT': 'Event',
        'FULL_DAY': 'Day',
        'HALF_DAY': '½ Day',
        'PER_HOUR': 'Hour',
        'EUR': '€',
        'MONTH': 'Month',
        'LODGINGBUSINESS': 'Spot',
        'ROLE_HOST': 'Host',
        'ROLE_ADMIN': 'Admin',
        'ROLE_USER': 'Freelancer',
        'ROLE_USER_CORPORATE': 'Corporate',
        'ROLE_BUILDING_MANAGER': 'Building manager',
        'ROLE_DEMO': 'Demo',
        'ROLE_RECEPTIONIST': 'Reception',
        'ROLE_FACILITY_CUSTOMER': ' Fac. Customer',
        'ROLE_FACILITY_VENDOR': ' Fac. Vendor',
        'ROLE_CASH_MANAGER': 'Cash manager',
        'ROLE_PROJECT_MANAGER': 'Project manager',
        'ROLE_FACILITY_MANAGER': 'Facility manager',
        'ROLE_HR_MANAGER': 'HR Manager',
        'ROLE_SUPERVISOR': 'Supervisor',
        'ALERT_STRIPE': 'Warning! To receive payments, you have to complete the Stripe registration',
        'RESOURCE': 'Resource',
        'RESOURCE_TYPE': 'Resource type',

        RESOURCES: {
            'meeting-room': 'Meeting Room{count, plural, =0{} one{} other{s}}',
            desk: 'Desk{count, plural, =0{} one{} other{s}}',
            'parking-slot': 'Parking slot{count, plural, =0{} one{} other{s}}',
            presence: 'Presence{count, plural, =0{} one{} other{s}}',
            PARKING_SLOT: 'Parking slot{count, plural, =0{} one{} other{s}}',
            PARKING_FACILITY: 'Parking facilit{count, plural, =0{ies} one{y} other{ies}}',
            MEETINGROOM: 'Meeting room{count, plural, =0{} one{} other{s}}',
            DESK: 'Desk{count, plural, =0{} one{} other{s}}',
            SEAT: 'Presence{count, plural, =0{} one{} other{s}}',
            SPOT: 'Spot{count, plural, =0{} one{} other{s}}',
            EVENT: 'Event{count, plural, =0{} one{} other{s}}',
        },

        'COMMON': {
            ACCOUNTHOLDER: 'Sccountholder',
            ADD: 'Add',
            ADDRESS: 'Address',
            ANYTHING_ELSE: 'Anything else',
            ATTENDEE: 'Attendee{count, plural, =0{} one{} other{s}}',
            ATTENDEES: 'Attendees',
            BUILDING_PROTOCOL : 'Building protocol',
            CLOSES: 'Closes',
            CODE: 'Code',
            COMPANY_PROTOCOL: 'Company protocol',
            COMPANY_PROTOCOL_COVID: 'Security document',
            COMPANY_PROTOCOL_TEXT: 'Company protocol',
            DATE: 'Date',
            DELETED_MESSAGE: ' item{count, plural, =0{} one{} other{s}} deleted',
            DESCRIPTION: 'Description',
            DESK_DETAILS_INFO: 'Place Details',
            DETAIL: 'Detail{count, plural, =0{} one{} other{s}}',
            EMPLOYEE: 'Employee',
            END: 'End',
            ERROR: 'We\'re sorry, but an error has occurred.',
            FEATURES: 'Features',
            FILTER: 'Filter{count, plural, =0{} one{} other{s}}',
            FLOOR: 'Floor',
            HOW: 'How',
            LIKED: 'You like it!',
            LOGOUT: 'Logout',
            MEETINGROOM_DETAILS_INFO: 'Meeting Room Details',
            NAME: 'Name',
            NET_PRICE: 'Net price',
            NOTES: 'Notes',
            NOT_LIKED: 'Like',
            NO_RESULTS: 'No results',
            OFFER: 'Offer',
            OFFERS: 'Offers',
            OPENINGS: 'Opening hours',
            OPENS: 'Opens',
            PARKING_SLOT_DETAILS_INFO: 'Parking Slot Details',
            PAYMENT_REDIRECT_MESSAGE: 'Complete the payment after the redirect',
            PERSON: 'Owner',
            PHOTO: 'Photo',
            PURPOSE: 'Purpose',
            PRICE: 'Price',
            PRIVACY_POLICY: 'Privacy policy',
            REFERENT: 'Referent',
            REQUESTOR: 'Requestor',
            SEARCH: 'Search',
            SEATS: 'Seats',
            SELF_CERTIFICATION: 'Self-certification',
            SPECIAL_OPENINGS: 'Special Opening hours',
            START: 'Start',
            SUBJECT: 'Subject',
            TICKET: 'Ticket',
            TIME: 'Time',
            TIMEZONE: 'Timezone',
            TITLE: 'Title',
            TOTAL_PRICE: 'Total price',
            T_AND_C: 'Terms & Conditions',
            VALID_FROM: 'Valid from',
            VALID_UNTIL: 'Valid until',
            VAT_PRICE: 'Vat price',
            WEEK_CLOSE: 'Weekly closing',
            WHAT: 'What',
            WHEN: 'When',
            WHERE: 'Where',
            ZONE: 'Zone',
        },

        ALERT: {
            TITLE: 'Attention!',
            DELETE_ALL: 'All items depending on the selected ones<br> will be deleted'
        },

        'PHOTO': {
            TITLE: 'Title',
            MANDATORY: 'The title is mandatory',
            ADD: 'Add photo',
            INFO: 'Photos will uploaded after save process',
            TO_UPLOAD: 'To upload',
            QUEUE: 'Quee',
            PROGRESS: 'Upload progress',
            ABORT_ALL: 'Abort all',
            REMOVE_ALL: 'Remove all',
            STATUS: 'Status',
            DELETE: 'Delete',
            IN_PROGRESS: 'In progress',
            COMPLETED: 'Upload completed with status code ',
            UPLOAD_TITLE: 'Upload a photo of this place'
        },

        'MANAGER' : {
            PHOTOS : {
                M1: 'MYSPOT works best with 3:2 horizontal pictures',
                M2: 'The first picture will be used as the profile cover, while the others will be shown in the gallery',
                M3: 'To delete a picture, select it and click on the red Delete button. Click on Save to confirm it'
            },
            ASSIGN_EMPLOYEE: {
                TITLE: 'Allocation',
                HEADING: 'Employee assigned to this place',
                HINT: 'If none, please search by email and select employee.',
                SEARCH_BY_EMAIL: 'Search by Email',
                RESET: 'Reset to None',
            },
            VIEW: 'View',
            SELECT: 'Select',
            LAST_SELECTED_PLACE: 'Last selected place',
            CLICK_MODE: 'Click mode',
            SELECT_FLOOR: 'Select floor',
            SELECT_PLACE_TYPE: 'Select place type',
            FILTER_BY: 'Filter by',
            BASIC_INFO: 'Basic info',
            NAME: 'Name',
            OFFERED_BY_LODGING: 'Offered by Lodging Business',
            TIME_SLOT: 'Time slot',
            PRICE: 'Price',
            CURRENCY: 'Currency',
            VAT: 'Vat',
            NO_VAT: 'Not VAT',
            LIMITED_TIME_OFFER: 'Limited Time Offer',
            VALID_FROM: 'Valid from',
            VALID_THROUGH: 'Valid through',
            DESCRIPTION: 'Description',
            ITEM_LIST: 'Item list',
            ITEM_LIST_TIP: 'Click ADD to activate this area',
            CHECK_ITEM_OFFER: 'Check the items available for this offer',
            SAVE_BEFORE_ADD: 'You must to save your offer before adding items to it',
            ITEM_OFFERED: 'Item{count, plural, =0{} one{} other{s}} offered (click to remove)',
            ITEM_OFFERED_TITLE: 'Item{count, plural, =0{} one{} other{s}} offered',
            AVAILABLE_CLICK: 'available (click to toggle)',
            ITEM_MAP: 'Item map',
            ITEM_MAP_TIP: 'Click ADD to activate this area',
        },

        'ORG' : {
            NEW:            'New Organization',
            DETAIL:         'Organization Detail',
            CONTACT_MYSPOT: 'Contact MYSPOT team to add your organization',
            BASIC_INFO:     'Basic Info',
            COMPANY_NAME:   'Company Legal Name',
            TAX:            'Tax Code',
            VAT:            'Vat Id',
            TEL:            'Telephone',
            DESCRIPTION:    'Description',
            CONTACT:        'Contact Person',
            NAME:           'Full Name ',
            SPOTS:          'Spots',
            ADD:            'Add New Spot',
            SPOT_NAME:      'Name',
            TYPE:           'Type',
            BUILDING:       'Building ',
            SELECTED:       'selected',
            TOTAL:          'total',
            PREMIUM_FEATURES: 'Premium Features',
            SENDER_EMAIL: 'Custom email sender',
            SENDER_ALIAS: 'Alias email sender',
            PLACE_DETAIL: 'Place Details'
        },

        'VEND': {
            NEW:            'New Organization',
            DETAIL:         'Organization Detail',
            CONTACT_MYSPOT: 'Contact MYSPOT team to add your organization',
            BASIC_INFO:     'Basic Info',
            COMPANY_NAME:   'Company Legal Name',
            TAX:            'Tax Code',
            VAT:            'Vat Id',
            TEL:            'Telephone',
            DESCRIPTION:    'Description',
            CONTACT:        'Contact Person',
            NAME:           'Full Name ',
            ADD:            'Add New Spot',
            TYPE:           'Type',
            TOTAL:          'total',
            CURRENCY:       'Currency',
            NEMPLOYEES: 'Number of employees',
            GEOPRESENCE: 'Geographical Presence',
            PARENTCOMPANY: 'Do you have a Parent Company?',
            SUBCOMPANY: 'Do you have a Subsidiary Company?',
            TERRITORY: 'Geographical area and states covered',
            CONTACTS: 'Contacts',
            ADDRESS: 'Address',
            CITY: 'City',
            ZIP: 'Zip code',
            COUNTRY: 'Country',
            SITE: 'Web site',
            CONTACTPEOPLE: 'Contact people',
            HISTORY: 'History',
            YEARS: 'Years in Existence',
            CLIENTS: 'Main clients',
            SUMMARY: 'Brief Summary of Company',
            CERTIFICATIONS: 'Certificate',
            SERVICES: 'Service: prodide a list',
            OTHERINFO: 'Other info',

        },


        'BOOKING': {
            DESKBUSY: 'The resource has already been busy.',
            COLLEGUE: 'Colleague',
            STATUS: {
                REQUESTED: 'Requested',
                CONFIRMED: 'Waiting to Check-In',
                IN_PROGRESS: 'Confirmed',
                CLOSED: 'Closed',
                CANCELLED: 'Cancelled',
                AWAITING_PAYMENT: 'Awaiting payment',
                AWAITING_PAYMENT_RESPONSE: 'Awaiting payment confirmation',
                EXPIRED: 'Expired',
                PAID: 'PAID',
                REJECTED: 'Rejected',
            },
            CLEAR_FILTERS: 'Clear filters',
            RESERVATION: 'Reservation Detail{count, plural, =0{} one{} other{s}}',
            COLLEAGUE: 'Do you want to book for a colleague?',
            COLLEAGUE_INFO: 'Colleague',
            DESK: 'Desk',
            MEETINGROOM: 'Room',
            PARKING_SLOT: 'Parking slot',
            LODGINGBUSINESS: 'Building',
            EVENT: 'Event',
            BROADCASTEVENT: 'Broadcast event',
            SEARCH_TEXT: {
                BASE: 'You are looking',
                POURPOSE: 'for a',
                ATTENDEE: 'for <strong>{attendee}</strong> attendees',
                DAY: 'on',
                START: 'from',
                END: 'to',
                PLACE: 'in',
                FLOOR: 'for the floor',
                ALL_FLOORS: 'on all floors',
                ZONE: 'for the zone',
                ALL_ZONES: 'on all zones',
            },
            ENTER_PASS: 'Enter your pass code to book easily',
            LOOK_AGENDA: 'Check the reservation agenda',
            HIDE_AGENDA: 'Hide reservation agenda',
            FEATURES: 'Amenities',
            INVALID_DATE: 'Invalid date range',
            SELECT_PERIOD: 'Select this period',
            AWAITING_SUGGESTIONS: 'Awaiting suggestions',
            SUGGESTED_TIMESLOT: 'Suggested time slots',
            NO_SUGGESTION: 'No suggestions avaiable',
            PROPOSALS: 'proposals',
            FILTER_PROPOSALS: 'proposals with those filters',
            ASSIGNED_SPACE: 'Assigned Space',
            RESOURCES: 'resources',
            FILTER_RESOURCES: 'resourcers with those filters',
            TIME_GRANULARITY: 'Change minute picker',
            NO_TIME_AVAIABLE: 'Weekly closing',
            NO_TIME_BLOCK: 'Time limit',
            USE_FILTER: 'Use the filters to start your search',
            CHANGE_FILTER: 'Try to change the filters to get more proposals',
            FILTER_BY_NAME: 'Filter by name',
            SEARCH_BY_EMAIL: 'Write the email',
            INVITE_ATTENDEES: 'Invite attendees...',
            INSERT_VALID_EMAIL: 'Please enter a valid email address',
            SELECT_START: 'Select start time',
            SELECT_END: 'Select end time',
            ALL_FLOORS: 'All Floors',
            ALL_ZONES: 'All Zones',
            QR_CODE: 'Please check-in via mobile',
            CONFIRM_DIALOG: {
                TITLE: 'Reservation confirmed!',
                BODY: 'Do you want to book something else?',
                CALENDAR: 'No, thanks',
                RESERVE: 'Yes!',
                REFRESH_TOKEN: {
                    TITLE: 'Session expired',
                    BODY: 'You have been inactive for too long, please refresh your session to continue',
                    BODY_DONE: 'Your session is now refreshed,<br> please continue to confirm your reservation',
                    REFRESH: 'Refresh',
                    RETRY: 'Try again',
                    DONE: 'Continue',
                    WAIT: 'Refreshing...',
                }
            },
            RESERVATIONS: {
                REJECT_DIALOG_TEXT: 'Please insert a reason for the rejection.',
                REJECT_REASON: 'Reject Reason',
                REJECT_SUCCESS: 'Reservation/s Successfully Rejected',
                REJECT_ERROR: 'Error In Rejecting Reservation/s'
            },
            SEARCH_FROM_DATE: 'From',
            SEARCH_TO_DATE : 'To',
            SEAT: 'Seat',
            NO_LODGING: 'No headquarters found',
            FIND_COLLEAGUE: 'Look for a colleague',
            TIMEZONE_BOOKING: 'You are now booking with the time zone of',
            MAP_LEGEND: {
                OK : 'Available',
                NOT_OK : 'Not Available',
                PARTIAL : 'Available (only some days)',
                ASSIGNED : 'My Desk',
                OWNED: 'Owned',
                COLLEAGUE : 'Colleague',
                COLLEAGUE_SELECTED : 'Colleague (selected)',
                SELECTED : 'Selected',
                DEFAULT : 'Unavailable',
            },
            ERROR: {
                COVID_LIMIT: {
                    TITLE: 'Safe Limit Reached',
                    MESSAGE: 'For health and safety reasons, we are limiting the number of allowable bookings',
                    TIPS: 'Please select a different day, time or floor',
                    DETAILS: '',
                },
                TIME_LIMIT: 'Booking past ',
                TIME_LIMIT1: 'days is not permitted',
                TIME_LIMIT_TITLE: 'Limit reached',
                NOT_PROPOSAL: 'Sorry, but an error has occurred. Try searching again.'
            },
            NOT_FIND_COLLEAGUE: 'No colleague present',
            FULL_DAY: 'Full day',
            NO_Place: 'There are no results for your search',
            NO_RESERVATION: 'The colleague has no reservations at this time',
            NO_RESERVATION_FLOOR: `The colleague has a reservation for another floor`,
            N0_ORDER: 'A reservation already exists during the selected date and time. Change your search to book.'
        },


        'WEEK': {
            SUNDAY : 'SUNDAY',
            MONDAY : 'MONDAY',
            TUESDAY : 'TUESDAY',
            WEDNESDAY : 'WEDNESDAY',
            THURSDAY : 'THURSDAY',
            FRIDAY : 'FRIDAY',
            SATURDAY : 'SATURDAY',
        },

        'EVENTS': {
            STATUS: {
                REQUESTED: 'Requested',
                CONFIRMED: 'Confirmed',
                IN_PROGRESS: 'Confirmed',
                CLOSED: 'Closed',
                CANCELLED: 'Cancelled',
                AWAITING_PAYMENT: 'Awaiting payment',
                AWAITING_PAYMENT_RESPONSE: 'Awaiting payment confirmation',
                EXPIRED: 'Expired',
                PAID: 'PAID',
            },
            DATE_HOURS : 'Dates & Hours',
            LOCATION : 'Location',
            NAME : 'Name',
            RESERVE_TICKET: 'Reserve a Ticket',
            JOIN_TEAM: 'Join Event',
            SUBSCRIBE: 'Subscribe',
            UNSUBSCRIBE: 'Unsubscribe',
            COUNTDOWN: 'Event start: {countdown}',
            CLOSED: 'Closed',
            SEARCH_LOCATION: 'Search the location',
            SEARCH_EVENT_NAME: 'Search by event name',
            SEARCH_WHERE: 'Where?',
            EVENT_NAME: 'Event Name',
            SEARCH_FROM_DATE: 'Date From',
            SEARCH_TO_DATE : 'Date To',
            HIDE_FILTERS : 'Hide filters',
            SHOW_MORE_FILTERS : 'Show filters',
            FOUNDED : 'Events found:',
            EVENTS : 'Events',
            EVENTS_BY_FILTERS : 'events with those filters',
            NO_RESULTS : 'Try to change the filters to get more events',
            PAST_EVENT: 'Past events',
            ONLY_PAST: 'There are no events scheduled in this area, but you can take a look at past events.',
            YOU_ARE_HERE : 'You are here',
            CHOOSE_TICKET: 'Choose your ticket',
            HOW_MANY_TICKETS: 'How many tickets?',
            TICKETS: 'Tickets',
            NO_OFFERS: 'Sorry,<br>this event has not ticket type avaiable right now.<br>Please, try again later.',
            NO_TICKETS: 'Sorry,<br>the seats for this ticket are sold out',
            NO_OFFERS_VIRTUAL: 'Sorry,<br>registration is not yet open for this event<br>Please, try again later.',
            TICKET_RESERVED: 'Your tickets are reserved',
            TICKET_RESERVED_VIRTUAL: 'Registration confirmed!',
            TICKET_RESERVED_TEMP: 'Your tickets are temporaly reserved',
            TICKET_RESERVED_VIRTUAL_TEMP: 'Registration temporaly reserved',
            CHECK_CALENDAR: `If you logged in with your Office 365 account,<br>We have synched the event on your Outlook calendar.`,
            ICS_TEXT: `Otherwise, download the ICS file and add it to your calendar`,
            DOWNLOAD_ICS: 'Download ICS file',
            ERROR_RESERVATION: 'We are so sorry! An error occured. Please try later',
            GO_CALENDAR: 'Show me my calendar',
            RESERVE: 'Reserve',
            LOGIN_REQUIRED: 'You need to be logged to buy a ticket',
            OFFER_AVAILABILITY: 'Offer availability',
            OFFER_AVAILABILITY_FROM: 'From: ',
            OFFER_AVAILABILITY_TO: 'Through: ',
            PARTECIPANTS: 'Participants',
        },

        'RECEPTION': {
            NOT_REGISTERED: 'The guest not yet registered',
            GUESTS: 'Guests',
            ON_BEHALF_OF: 'On Behalf of',
            N_GUESTS: 'N. Guests',
            VALID_FROM: 'Start',
            VALID_THROUGH: 'End',
            BUILDING: 'Building',
            REQUESTOR: 'Requestor',
            ADD_ACCESS_REQUEST: 'Create Access Request',
            SEARCH: 'Search Access request',
            INSERT_REFERENT: 'Type the name or the email',
            ADD_GUESTS_TIP: 'Add <strong>guest</strong> in the opposite panel',
            GRANT_ACCESS: 'Grant <strong>Access</strong>',
            INSERT_ACCESS_INFO: 'Insert <strong>Access request</strong> information',
            ACCESS_READY_TITLE: 'The <strong>Access request</strong> is ready!',
            REPEAT_PROCESS: 'Repeat the process from step <strong>2</strong> to add another <strong>Guest</strong> or press the button to send the <strong>Access request</strong> to your <strong>Guests</strong>',
            HELLO: 'Hello, <strong>{name}</strong>!',
            WELCOME_MESSAGE: 'To begin, complete all <strong>Access request</strong> information',
            STEP2: {
                TITLE: 'Add at least one <strong>Guest</strong>',
                SEARCH_LABEL: 'Type the email of the <strong>Guest</strong>',
                SEARCH_HINT: 'Try to search if the <strong>Guest</strong> is in our archive. If not, you will able to register a new one'
            },
            STEP3: {
                TITLE: 'Select the right <strong>Guest</strong>',
                TITLE2: 'No <strong>Guest</strong> found. Register a new one!',
                TITLE_ERROR: 'Colleagues not allowed as guests',
                INSERT_VALID_EMAIL: 'Please enter a valid email address',
            },
            STEP4: {
                TITLE: 'Fill all <strong>Guest</strong> info',
                PRIVACY_BUTTON: 'Print privacy agreements'
            },
            STEP5: {
                BUTTON: 'Add <strong>Guest</strong>',
            },
            OK_MESSAGE: 'Your <strong>guests</strong> will receive an e-mail with the access instructions',
            OK_TITLE: 'Access granted',
            STATUS: 'Status',
        },

        INVITATION: {
            ADD_ACCESS_REQUEST: 'Create Invitation',
            SEARCH: 'Search Invitation',
            GRANT_ACCESS: 'Send <strong>Invitation</strong>',
            INSERT_ACCESS_INFO: 'Insert <strong>Invitation</strong> information',
            ACCESS_READY_TITLE: 'The <strong>Invitation</strong> is ready!',
            REPEAT_PROCESS: 'Repeat the process from step <strong>2</strong> to add another <strong>Guest</strong> or press the button to send the <strong>Invitation</strong> to your <strong>Guests</strong>',
            WELCOME_MESSAGE: 'To begin, complete all <strong>Invitation</strong> information',
            OK_TITLE: 'Invite sent',
        },

        ACCESS: {
            REGISTRATION: {
                TITLE : 'Registration',
                PERSONAL_DATA : 'Personal information',
                REQUIRED: 'This field is mandatory',
                NAME : 'Name',
                SURNAME : 'Surname',
                BIRTHDAY : 'Date of birth',
                COMPANY : 'Company',
                DOCUMENT : {
                    TITLE : 'Identity document',
                    TYPE : 'Type',
                    ID : 'Personal ID',
                    DRIVING_LICENSE : 'Driving license',
                    PASSPORT : 'Passport',
                    NUMBER : 'Document Number',
                    ISSUED_BY : 'Issued by',
                    EXPIRING : 'Expiring date',
                    EXPIRED: 'Expired',
                    'Identity Card': 'Personal ID',
                    'Driving License': 'Driving license',
                    'Passport': 'Passport'
                },
                PRIVACY : {
                    TITLE : 'Privacy and data protection',
                    SUBTITLE : 'INFORMATION ON THE PROCESSING OF PERSONAL DATA',
                    TEXT : 'The undersigned, read the information above, consents to the processing of their personal data and is aware of their rights as provided above.'
                },
                POLICY_CONFIRM: 'I accept the privacy conditions',
                CONFIRM: 'Confirm Registration',
                COMPLETE: {
                    TITLE_1: 'Your registration is completed.',
                    TITLE_2: 'You will receive an email shortly before the appointment with instructions for requesting access.'
                },
                EMAIL: 'Email',
                INSERT_VALID_EMAIL: 'Please enter a valid email address',
            },
            ACCEPTANCE: {
                TITLE: 'Reception',
                ME: 'The undersigned',
                REQUIRED_MESSAGE: 'This field is mandatory',
                NAME: 'Name',
                SURNAME: 'Surname',
                COMPANY: 'Company',
                TODAY: 'Date',
                SIGNAGE: 'Signature',
                DELETE_SIGNAGE: 'Delete signature',
                CONFIRM: 'Confirm Registration',
                COVID: {
                    TITLE: 'COVID-19 Declaration',
                    CHECK: 'I confirm the Covid-19 declaration',
                    MESSAGE: {
                        ROW1: 'Due to the ongoing and rapidly changing situation with the novel-coronavirus (COVID-19).',
                        ROW2: 'To prevent the spread of COVID-19 and reduce the potential risk of exposure to others, I will adopt all the needed precautionary measures.',
                        ROW3: 'And:',
                        UL_1: {
                            LI_1: 'I am NOT presenting with any of the symptoms of COVID-19;',
                            LI_2: 'I have not been identified as a contact of someone who has tested positive for COVID -19 or been asked to self-isolate;',
                            LI_3: 'I have not travelled to high-risk areas, or outside of my home country by car, air, sea, bus or train in the past 14 days.'
                        },
                        ROW4: 'and, at the end I declare:',
                        UL_2: {
                            LI_none2: 'that I observe the travel limitations indicated in the D.P.C.M. in force or that I do not come from the areas identified in "orange" and "red" except for proven work needs;',
                            LI_3: 'that I comply with the provisions relating to returns to Italy from foreign countries established in the D.P.C.M. in force.'
                        }
                    }
                },
                SECURITY: {
                    TITLE: 'Security Information',
                    DESCRIPTION: 'I declare that I have read the information on security.',
                    DOWNLOAD: 'Download the document',
                    CHECK: 'I confirm the security declaration'
                },
                PRIVACY: {
                    CHECK: 'I accept the privacy conditions',
                    TEXT: 'The interested party confirms that the privacy policy has been viewed and each time expresses their consent to the automated processing of their personal data and are aware of their rights as provided for in the aforementioned information.',
                },
                NOT_REGISTERED: {
                    TITLE: 'Not registered yet?',
                    DESCRIPTION: 'You have to completete the registration in order to complete this step.'
                },
                COMPLETE: {
                    TITLE_1: 'Your data has been sent successfully.',
                    TITLE_2: 'You will receive an access email.',
                    DESCRIPTION: 'You can leave this page open while waiting for the badge, or come back later to check that it has been enabled.'
                },
                NOT_VALID_TIME_TITLE: 'Operation Not Allowed',
                NOT_VALID_TIME_DESCRIPTION: 'Sorry, the meeting has been postponed. You\'ll receive a new acceptance email just before the appointment.',
            },
            BADGE: {
                GUEST_EXPIRED: 'Your badge has expired.',
                GUEST_REJECTED: 'We\'re sorry, but your badge has been cancelled.',
                GUEST_DEFAULT_ERROR: 'We\'re sorry, but an error has occurred.',
                GUEST_ACCESS_GRANTED: 'Please enter.',
                GUEST_SCAN_REJECTED: 'We\'re sorry, but access is not possible at the moment.',
                GUEST_TIME_NOT_VALID: 'Es tut uns leid, aber das Abzeichen ist für heute nicht gültig.',
                WELCOME: 'WELCOME',
                ENJOY_YOUR_STAY: 'Enjoy your stay.',
                GOODBYE: 'Goodbye',
                GOODBYE_GREET: 'Have a nice day',
                COMPLETE: {
                    AUTHORIZATION_TEXT: 'The guest has completed acceptance and is waiting for your authorization.',
                    AUTHORIZATION_BUTTON: 'Authorize Access',
                    SUCCESS: 'The authorization is confirmed. Your guest is now able to enter the building.',
                    ERROR: 'An error has occurred. Please try again later.',
                },
            },
            GUEST: {
                TITLE: 'Guest Access',
                CHECK_IN: 'Check-in',
                CHECK_OUT: 'Check-out',
                QR_CODE_ENTRANCE: 'Entra o esci tramite QR Code',
                REGISTER: 'Register',
                WELCOME: 'Welcome',
                WELCOME_BACK: 'Welcome back',
                WELCOME_TO: 'Welcome to',
                HAS_QR_CODE: 'Do you have a QR code? Please check-in here:',
                NOT_REGISTERED: 'If you are not registered, please register here​:',
                CREATE_NEW_REGISTRATION: 'If no registration found, please create a new one:',
                QRCODE_TO_SCANNER: 'Please present your code',
                CHOOSE_HOW_TO_CONTINUE: 'Please, choose how to continue your registration',
                SCAN_QRCODE: 'Please use you device to scan the code above',
                CONTINUE_VIA_APP_TEXT: 'Continue your registration via mobile app.',
                CONTINUE_VIA_APP_BUTTON: 'Mobile App',
                CONTINUE_VIA_TABLET_TEXT: 'To continue your registration here, please press the Next button above.',
                CONTINUE_VIA_TABLET_BUTTON: 'Continue',
                HOST_EMAIL_TEXT: 'Please insert the email of your host and then select from the list below',
                OWN_EMAIL_TEXT: 'Please insert your email address',
                TABLET: 'Continue here',
                MOBILE: 'Use my smartphone',
                HOST_MAIL: 'Host E-Mail',
                YOUR_MAIL: 'Your E-Mail',
                YOUR_DATA: 'Your Data',
                GUEST_STATUS: 'Status',
                STATUS: {
                    GUEST_AWAITED: 'Registered',
                    GUEST_ACCESS_REQUESTED: 'Not yet authorised',
                    GUEST_ACCESS_GRANTED: 'Enabled',
                    GUEST_ENTERED: 'Entered',
                    GUEST_EXITED: 'Exited',
                    GUEST_EXPIRED: 'Disabled',
                    GUEST_NOT_REGISTERED: 'Not registered',
                    GUEST_AWAITED_DESC: `The guest has registered`,
                    GUEST_ACCESS_REQUESTED_DESC: `The guest is awaiting authorization`,
                    GUEST_ACCESS_GRANTED_DESC: `The guest can enter`,
                    GUEST_ENTERED_DESC: `The guest has entered`,
                    GUEST_EXITED_DESC: `The guest left the building`,
                    GUEST_EXPIRED_DESC: `The appointment is no longer valid`,
                    GUEST_NOT_REGISTERED_DESC: `The guest has not registered yet`,
                },
            },
            SELF: {
                SELECT_LODGING: 'Select the building',
            }
        },

        PROFILE: {
            MENU: 'Profile',
            SAVED: 'Profile saved!',
            SAVED_ERROR: 'Ops, something goes wrong. Try again',
            PROFILE_PIC: 'Profile pic',
            UPLOAD_PIC: 'Upload your photo',
            NO_NAME: 'Missing name!',
            TITLE: 'Profile',
            JOB_TITLE: 'Job title',
            SHORT_BIO: 'Short bio',
            DESCRIPTION: 'Bio',
            SKILLS_LABEL: 'Skills',
            INTERESTS_LABEL: 'Interests',
            NEW_SKILL: 'Add skill',
            NEW_INTEREST: 'Add interest',
            LINKEDIN: 'LinkedIn Profile Link',

            PHOTOS : {
                M1: 'MYSPOT works best with 3:4 vertical pictures',
                M2: 'The first picture will be used as the profile cover, while the others will be shown in the gallery',
                M3: 'To delete a picture, select it and click on the red Delete button. Click on Save to confirm it'
            },

            VISIBILITY: {
                LABEL: 'Profile visibility',
                COLLEAGUES : 'Colleagues',
                NOT_VISIBLE : 'Private',
                COMMUNITIES : 'Community',
                MYSPOT : 'MYSPOT',
                COLLEAGUES_CLAIM: 'Do you really know each and every colleague of yours?',
                NOT_VISIBLE_CLAIM: 'Use MYSPOT while being not visible to others.',
                COMMUNITIES_CLAIM: 'Having common ideas and projects enables you to reach far beyond the company boundaries.',
                MYSPOT_CLAIM: 'In this interconnected world, growth and business opportunities are around the corner.',
                COLLEAGUES_DESC : 'Share talents and passions with them: you could discover unexpected opportunities.',
                NOT_VISIBLE_DESC : 'You can start making yourself known to the community at any time.',
                COMMUNITIES_DESC : 'Find who shares your interests and keep in touch with them.',
                MYSPOT_DESC : 'Let the whole MYSPOT network get to know you.',
            },
        },

        TIPS: {
            TITLE: 'Title',
            TAG: 'Tag',
            TAGS: 'Tags',
            SEARCH: 'Search',
            SEARCH_TIPS: 'Search tips',
            SEARCH_TAG: 'Search by tag',
            DESCRIPTION: 'Description',
            DATE_CREATED: 'Date Created',
            LINK: 'Link',
            OPEN: 'Open',
            PREVIOUS: 'previous',
            NEXT: 'next',
            NO_RESULTS: 'There are currently no tips available.'
        },

        PARKING_SLOTS : {
            ADD_NEW: 'Add New Parking Slot',
            LOCATION: 'Location',
            CAPACITY: 'Capacity',
            PARKING_SLOT_SUBTITLE: 'Parking Slot Details',
            NEW_PARKING_SLOT_TITLE: 'New Parking Slot',
            PARKING_SLOT_PHOTOS: 'Parking Slot Photos',
            FEATURES_TITLE: 'Select the features of this parking slot',
            CHANGE_INFO: 'Change those values only if the spot and this parking slot have differtents opening hours.'
        },

        EMPLOYEES: {
            FIRST_NAME: 'First Name',
            LAST_NAME: 'Last Name',
            EMAIL: 'Email',
            EMPLOYEE_NUMBER: 'Employee Number',
            DEFAULT_BUILDING: 'Default Building',
            DEFAULT_FLOOR: 'Default Floor',
            CURRENT: 'Current',
            AVAILABLE: 'Available',
            CLUSTERS: 'Clusters',
            CLUSTER_UPDATED: 'Cluster Updated',
            CLUSTER_UPDATED_ERROR: 'Cluster Not Updated',
            SELECT_FROM_BELOW: 'Please select from the list below.',
            ADD_BUTTON: 'Add',
            DELETE_BUTTON: 'Remove',
            ASSIGNED: 'Assigned clusters',
            TO_ASSIGN: 'Cluster to be assigned',
            CHECK: 'Seleziona',
            NAME: 'Name',
            DESCRIPTION: 'Description',
            FILTER: 'Filter',
            DESK_TITLE: 'Assigned desks',
            DESK_CODE: 'Code Desk',
            DESK_NAME: 'Name Desk',
            DESK_FLOOR: 'Floor',
            BUILDING_CODE: 'Code Building',
            BUILDING_NAME: 'Name Building',
            USER_ACTIVE: 'Activate',
            USER_DEACTIVATE: 'Deactivate',
            DESK_ASSIGNED: 'Assigned Resources',
            ERROR: 'Inborn error',
            MESSAGE_INFO: 'the user is in another state for more info ',
            EMAIL_BUTTON: 'contact us',
            DESK_ERROR_CODE: 'Error removing desks',
            MESSAGE_INFO_ERROR: 'The user is in the state ',
            MESSAGE_INFO_ERROR1: ' manageable via helpdesk. To edit, open a ticket',
            MESSAGE_INFO_ERROR2: 'clicking here'
        },
        CLUSTERS: {
            ADD_NEW: 'Add New Cluster',
            CLUSTER_TITLE: 'Cluster Profile',
            NAME: 'Name',
            DESCRIPTION: 'Description',
            BASIC_INFO: 'Basic Info',
            EMPLOYEES: 'Employees',
            NEW_CLUSTER_DIALOG: 'Please insert new cluster details.',
            DELETE_CLUSTER_DIALOG: 'Please confirm the deletion of the following cluster:',
        },
        ZONES: {
            ADD_NEW_FLOOR: 'Add New Floor',
            OBJECTS: 'Objects',
            NEW_FLOOR_TITLE: 'New Floor',
            FLOOR_SUBTITLE: 'Floor Details',
            CONTAINED_OBJECTS: 'Contained Objects',
            LOGICAL_REPRESENTATION_OF: 'Logical Representation Of',
            ZONE_TYPE: 'Zone Type',
            CURRENT_OBJECTS: 'No. of objects currently contained on this floor:',
            REMOVE_OBJECT: '(Click on an object to remove it from this floor. Then press Save.)',
            ADD_OBJECT: '(Click on an object to add it to this floor. Then press Save.)',
            AVAILABLE_OBJECTS_TITLE: 'Available Objects',
            AVAILABLE_OBJECTS: 'No. of available objects:'
        },
        RESERVATIONS: {
            RESERVATION_SUBTITLE: 'Reservation Detail',
            RESERVATION_DATA: 'Reservation Information',
            RESERVATION_ID: 'Reservation ID',
            OWNER_NAME: 'Name'
        },

        PRIVACY_TITLE: 'By continuing you agree our',
        PRIVACY: 'Terms of Service & Privacy Policy'
    },
};

const { CLIENT_ID, ISSUER, OKTA_TESTING_DISABLEHTTPSCHECK } = process.env;
const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = (window.location.port) ? `:${window.location.port}` : '';
const baseUrl = `${protocol}//${hostname}${port}`;

export default {
  oidc: {
    clientId: `${CLIENT_ID}`,
    issuer: `${ISSUER}`,
    redirectUri: `${baseUrl}/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: `${OKTA_TESTING_DISABLEHTTPSCHECK}`
    }
  },
  resourceServer: {
    messagesUrl: `${baseUrl}/api/messages`,
  },
};

import { Observable } from 'rxjs';
import { ILodgingBusiness } from '../../models/workplace/lodging-business.model';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { PlaceAPIService } from './place.service';
import {DaysOfWeekValues, OpeningHoursSpecification} from '../../models/workplace/opening-hours-specification.model';
import * as moment from 'moment';


@Injectable()
export class LodgingBusinessAPIService extends PlaceAPIService {

    protected resourceUrl = '/myspotworkplaceservice';
    protected link = '/api/lodging-businesses';

    constructor(protected http: HttpClient) {
        super(http);
    }

    get(filter?, sortBy?: string, sortDirection?: string, page?: number, size?: number, link?: string, params?: HttpParams): Observable<ILodgingBusiness[]> {
        return super.get(filter, sortBy, sortDirection, page, size, link, params);
    }

    getById(id: number): Observable<ILodgingBusiness> {
        return super.getById(id);
    }

    findByName(name: string, sortBy?, sortDirection?, page?, size?, ): Observable<ILodgingBusiness[]> {
        return super.findByName(name, sortBy, sortDirection, page, size);
    }

    getByParams(params: HttpParams): Observable<ILodgingBusiness[]>{
        return super.getByParams(params);
      }

    save(place: ILodgingBusiness): Observable<ILodgingBusiness>{
        return super.save(place);
    }

    query(req?: any): Observable<ILodgingBusiness[]>  {
        return super.query(req);
    }

    getOpeningHourByDay(oHs: OpeningHoursSpecification[], day: moment.Moment): OpeningHoursSpecification {
        return oHs.find(o => DaysOfWeekValues[o.dayOfWeek] === day.day());
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import {CloudinaryModule} from '@cloudinary/angular-5.x';



@NgModule({
  declarations: [AvatarComponent],
  imports: [
    CommonModule,
    CloudinaryModule,
  ],
  exports: [AvatarComponent],
})
export class MyspotAvatarModule { }

import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute} from '@angular/router';
import {PlatformService} from '@trilon/ng-universal';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {getQueryParameter} from '../../util/utils';

export enum DataLocationCode {
  EU = 'EU',
  US = 'US',
}

export interface DataLocation {
  id?: number;
  domain: string;
  location: DataLocationCode;
}


@Injectable({
  providedIn: 'root'
})
export class PrivacyService {

  private _dataLocation: DataLocationCode = DataLocationCode.EU;

  protected resourceUrl = '/myspotpeopleservice';
  protected linkDataLocation = '/api/data-locations';

  onDataLocationChange: BehaviorSubject<DataLocationCode>;

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService,
    protected route: ActivatedRoute,
    protected platformService: PlatformService,
  ) {
    console.log('PrivacyService');
    this.initDataLocation();
  }

  setDataLocationCodeByDomain(domain: string, reload = true): Observable<DataLocationCode> {
    return this.getDataLocationCodeByDomain(domain.toLowerCase()).pipe(map(dataLocationCode => {
      dataLocationCode = dataLocationCode ? dataLocationCode : DataLocationCode.EU;
      this.setDataLocation(dataLocationCode, reload);
      return dataLocationCode;
    }));
  }

  getDataLocationCodeByDomain(domain: string): Observable<DataLocationCode> {
    const params = new HttpParams().set('domain', domain.toLowerCase());

    return this.http.get<DataLocation[]>(this.resourceUrl + this.linkDataLocation, {params}).pipe(map(dataLocations => {
      if (dataLocations?.length) {
        return dataLocations[0].location;
      }
      return undefined;
    }));
  }

  initDataLocation(): void {
    console.log('PrivacyService init');
    const dataLocationCookie = this.platformService.isBrowser ? this.cookie.get('myspot-data-location') : 'EU';
    const dataLocation = dataLocationCookie || getQueryParameter('dataLocation');

    if (!dataLocationCookie && dataLocation) {
      this.cookie.set('myspot-data-location', dataLocation, undefined, undefined, undefined, true, 'Strict');
    }

    console.log('DataLocation', dataLocationCookie, dataLocation);
    this._dataLocation = DataLocationCode[dataLocation] || DataLocationCode.EU;
    this.onDataLocationChange = new BehaviorSubject<DataLocationCode>(this._dataLocation);
  }

  setDataLocation(dO = DataLocationCode.EU, reload = true): boolean {

    if (!Object.values(DataLocationCode).includes(dO)) {
      return false;
    }

    const previousDataLocation = this._dataLocation;
    this._dataLocation = dO;

    if (this.platformService.isServer) {
      return true;
    }

    this.cookie.set('myspot-data-location', dO, undefined, undefined, undefined, true, 'Strict');

    if (previousDataLocation !== dO) {
      this.onDataLocationChange.next(this._dataLocation);
      if (reload) {
        window.location.reload(true);
      }
    }

    return true;
  }

  getDataLocation(): DataLocationCode {
    console.log('getDataLocation', this._dataLocation);
    return this._dataLocation;
  }

  getStringDataLocation(lowerCase = false): string {
    console.log('getStringDataLocation', this._dataLocation);
    return lowerCase ? this._dataLocation.toLowerCase() : this._dataLocation;
  }

}

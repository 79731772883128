import { LodgingReservation } from '../reservation/lodging-reservation.model';
import { Person } from '@myspot/models/people/person';
import { Logo } from '../../services/logo.service';
import { Guest } from '@myspot/models/people/guest';
import { LodgingBusiness } from '../workplace/lodging-business.model';
import { Moment } from 'moment';
import {User} from '../people/user';
export enum AccessGuestStatus {
    GUEST_AWAITED = 'GUEST_AWAITED',
    GUEST_ACCESS_REQUESTED = 'GUEST_ACCESS_REQUESTED',
    GUEST_ACCESS_GRANTED = 'GUEST_ACCESS_GRANTED',
    GUEST_ENTERED = 'GUEST_ENTERED',
    GUEST_EXITED = 'GUEST_EXITED',
    GUEST_EXPIRED = 'GUEST_EXPIRED',
    GUEST_REJECTED = 'GUEST_REJECTED',
    GUEST_SCAN_REJECTED = 'GUEST_SCAN_REJECTED',
    GUEST_NOT_REGISTERED = 'GUEST_NOT_REGISTERED',
}

export enum AccessRequestStatus {
    REQUESTED = 'REQUESTED',
    CANCELLED = 'CANCELLED',
}

export interface AccessGuest {
    additionalType?: 'GUEST';
    firstName?: string;
    lastName?: string;
    birthDate?: Date;
    companyName?: string;
    covidCheck?: boolean;
    securityCheck?: boolean;
    email?: string;
    id?: string;
    invitationId?: number;
    signature?: string;
    signatureContentType?: string;
    status?: AccessGuestStatus;
    token?: string;
    verified?: boolean;
    documentType?: 'Identity Card' | 'Driving License' | 'Passport';
    documentNumber?: string;
    documentProvider?: string;
    documentExpireDate?: Date;
    acceptedPrivacyPolicy?: boolean;
    privacyPolicyAddendum?: boolean;
    invitation?: AccessRequest;
}

export interface AccessRequest {
    accessFor: LodgingBusiness;
    additionalType?: string;
    dateCreated?: Moment;
    externalId?: string;
    guests: AccessGuest[];
    id?: number;
    name: string;
    requestor: string;
    onBehalfOf?: string;
    status?: AccessRequestStatus;
    validFrom: Moment;
    validThrough: Moment;
}
export interface AccessData {
    company?: string;
    mail?: string;
    token?: string;
    logo?: Logo;
    user?: User;
    qrcode?: string;
    errorMessage?: string;
    tenantCode?: string;
    guest?: Guest;
    accessGuest?: AccessGuest;
    invitationId?: number;
    authToken?: string;
    request?: AccessRequest;
    reservation?: LodgingReservation;
    acceptedPrivacyPolicy?: boolean;
    lang?: any;
}

export interface AccessDomain {
    'id': number;
    'name': string;
    'url': string;
    'tenantCode': string;
}

export enum AccessState {
    START,
    LOADING,
    COMPLETE,
    REJECTED,
    ERROR,
    NOTVALIDTIME,
}

import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as momentTz from 'moment-timezone';
import {Employee} from '@myspot/models/people/employee';
import {initBooleanInput, setImages} from '../../util/utils';

export class CardInfo {
  name: string;
  images: string[];
  additionalType: string;
  [k: string]: any;
}

@Component({
  selector: 'ms-myspot-card',
  templateUrl: './myspot-card.component.html',
  styleUrls: ['./myspot-card.component.scss']
})
export class MyspotCardComponent implements OnInit {

  @Input() info: CardInfo;
  @Input() flat: boolean;
  @Input() layout = 'vertical';
  @Input() options: any;
  @Input() owner: Employee;
  @Input() wide: boolean;
  @Output() clicked =  new EventEmitter<string>();

  tz = momentTz.tz.guess();
  locale: string;

  cloudPath = 'https://res.cloudinary.com/dlo69v6hu/image/upload/c_fill,dpr_1.0,f_jpg,w_600/';

  photoUrls = {
    custom: this.cloudPath + '$',
    default: this.cloudPath + 'placeholder/$.jpg'
  };

  cardImg: string;

  constructor(public translateService: TranslateService) { }

  ngOnInit(): void {

    this.flat = initBooleanInput(this.flat);
    this.wide = initBooleanInput(this.wide);

    if (this.info) {
      this.info = setImages(this.info);
      this._setPhoto(this.info.additionalType, this.info.images);
    }

    this.locale = this.translateService.currentLang;

    this.translateService.onLangChange.subscribe(lang => {
      this.locale = lang.lang;
    });


  }

  protected _setPhoto(additionalType: string, images?: string[]): void {
      if (images) {
        this.cardImg = this.photoUrls.custom.replace('$', images[0]);
      }else {
        this.cardImg = this.photoUrls.default.replace('$', additionalType);
      }
  }

  modify() {
    this.clicked.emit('modify');
  }

  delete() {
    this.clicked.emit('delete');
  }

}

// import { UserService } from '@myspot/services/user.service';
import { Injectable } from '@angular/core';
// import { fromPromise } from 'rxjs/observable/fromPromise';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
// import { OktaAuthService } from '@okta/okta-angular';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
// import { Router } from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {fromPromise} from 'rxjs-compat/observable/fromPromise';
// import { MyspotAlertService } from '@myspot/services/alert.service';
import { switchMap } from 'rxjs/operators';
import {OktaAuthService} from '@okta/okta-angular';
import {PrivacyService} from '@myspot/services/people/privacy.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {


  isDialogOpen = false;

  constructor(
    public auth: OktaAuthService,
    // private router: Router,
    // public alertService: MyspotAlertService,
    // private userService: UserService,
    // protected platformService: PlatformService,
    private cookieService: CookieService,
    private privacyService: PrivacyService,
    ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any> | any> {

    request = request.clone({url : `/api-${this.privacyService.getStringDataLocation(true)}` + request.url});

    if (request.headers && request.headers.has('Authorization')) {
      return this.makeCall(request, next);
    }

    const cookieToken = this.cookieService.get('myspotToken');

    if (cookieToken) {
      request = request.clone({
        setHeaders: {
          'X-MYSPOT-AuthToken': `${cookieToken}`
        }
      });
      // TODO per motivi infrastrutturali, il token non può essere usato dal registry pertanto questa parte è momentaneamente commentata
/*      this.auth.getUser().then((u) => {
        if (request.url === `/api-eu/myspotregistry/config/${u?.organization}/myspotreservationservice-default.json`) {
          return fromPromise(this.auth.tokenManager.get('accessToken')).pipe(
            switchMap((token: any) => {
              if (token) {
                request = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${token.accessToken}`
                  }
                });
                return this.makeCall(request, next);
              }
            })
          );
        }
      });*/
    } else {
      return fromPromise(this.auth.tokenManager.get('accessToken')).pipe(
        switchMap((token: any) => {


          console.log('TokenInterceptor', token);
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token.accessToken}`
              }
            });
          }

          return this.makeCall(request, next);

        })
      );
    }

    return this.makeCall(request, next);
  }

  makeCall(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let hideError = false;

    if (request.params.has('angHideError')) {
      hideError = (request.params.get('angHideError') === 'true');
      request  = request.clone({
        params: request.params.delete('angHideError')
      });
    }

    console.log('Request', request);

    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

}

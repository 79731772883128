import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ms-alert-snackbar',
  templateUrl: './alert-snackbar.component.html',
  styleUrls: ['./alert-snackbar.component.scss']
})
export class AlertSnackbarComponent {

  public message: string;
  constructor() { }


}
import { OfferCardComponent } from './offer-card/offer-card.component';
import { MomentModule } from 'ngx-moment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { MyspotCardComponent } from './myspot-card.component';
import { PlaceCardComponent } from './place-card/place-card.component';
import { EventTicketCardComponent } from './event-ticket-card/event-ticket-card.component';
import { EventCardComponent } from './event-card/event-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { TipCardComponent } from './tip-card/tip-card.component';
import {MyspotAvatarModule} from '../avatar/avatar.module';
import { GuestCardComponent } from './guest-card/guest-card.component';
import {CloudinaryModule} from '@cloudinary/angular-5.x';
import {MatDividerModule} from '@angular/material/divider';
import {FuseDirectivesModule} from '@fuse/directives/directives';

@NgModule({
    declarations: [
        MyspotCardComponent,
        PlaceCardComponent,
        EventTicketCardComponent,
        EventCardComponent,
        TipCardComponent,
        OfferCardComponent,
        GuestCardComponent,
    ],
  exports: [
    MyspotCardComponent,
    PlaceCardComponent,
    EventTicketCardComponent,
    EventCardComponent,
    TipCardComponent,
    OfferCardComponent,
    GuestCardComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MomentModule,
    TranslateModule.forChild(),
    MyspotAvatarModule,
    CloudinaryModule,
    MatDividerModule,
    FuseDirectivesModule,
  ]
})
export class MyspotCardModule {}

import {Component, Input, OnInit} from '@angular/core';
import {Person} from '@myspot/models/people/person';
import {CLOUDINARY_IMG_URL} from '../../constants/other.contants';

@Component({
  selector: 'ms-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input() person: Person;
  @Input() size = 32;

  CLOUDINARY_IMG_URL = CLOUDINARY_IMG_URL;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component } from '@angular/core';
import {PlaceType} from '@myspot/models/workplace/place.model';
import {TranslateService} from '@ngx-translate/core';

import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';
import { locale as deutsch } from './i18n/de';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'myspot-crestron-ngx';

  place = {
    id: 123,
    maximumAttendeeCapacity: 34,
    name: 'Name',
    alternateName: 'Non so',
    description: 'Che te frega',
    disambiguatingDescription: 'string',
    placeType: PlaceType.BUILDING,
    additionalType: 'Add Type',
    address: 'Via dei matti',
    amenityFeatures: [],
    containsPlaces: [],
    faxNumber: '231321312',
    isAccessibleForFree: true,
    isQuickBookable: true,
    openingHoursSpecifications: [],
    oldOpenings: [],
    publicAccess: true,
    specialOpeningHoursSpecifications: [],
    oldSpecialOpenings: [],
    telephone: '2132132',
    containedInOffers: [],
    oldOffers: [],
    longitude: 123231,
    latitude: 123123,
    position: undefined,
    tenantCode: 'EFM',
    calendarAccount: 'Pippo',
    containedInZones: [],
    docSecurity: '1232331',
  };

  selected(): void {
    console.log('selected');
  }

  constructor(public translateService: TranslateService) {
    this.translateService.addLangs(['en', 'it', 'de']);
    this.translateService.setTranslation(english.lang, english.data, true);
    this.translateService.setTranslation(italian.lang, italian.data, true);
    this.translateService.setTranslation(deutsch.lang, deutsch.data, true);
  }
}

import { NgModule } from '@angular/core';
import { ItemSelectorDirective } from './item-selector.directive';
import { ItemDisablerDirective } from './item-disabler.directive';
import { ImagePreviewDirective } from './image-preview.directive';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { CommonModule } from '@angular/common';
import { ItemRemoverDirective } from './item-remover.directive';

@NgModule({
    declarations: [
        ItemSelectorDirective,
        ItemDisablerDirective,
        ImagePreviewDirective,
        ItemRemoverDirective,
        DragAndDropDirective
    ],
    exports: [
        ItemSelectorDirective,
        ItemDisablerDirective,
        ImagePreviewDirective,
        ItemRemoverDirective,
        DragAndDropDirective
    ],
    imports: [
        CommonModule
    ]
})
export class MyspotDirectiveModule {}

import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';


import { PageService } from '../apps/page.service';
import { map, catchError } from 'rxjs/operators';
import { setImages } from '../util/utils';
import { Tip } from '../models/tip/tip';


@Injectable()
export class TipsAPIService extends PageService {


    protected resourceUrl = '/myspoteventservice';
    protected link = '/api/tips?sort=dateCreated%2Cdesc';
    protected likesLink = '/api/likes';

    constructor(protected http: HttpClient) {
        super(http);
    }

    get(filter?, sortBy = 'id', sortDirection = 'asc', page = 0, size = 10, link = this.link, params = new HttpParams(), hideError = false): Observable<Tip[]> {

        if (hideError) {
            params = params.set('angHideError', 'true');
        }

        return super.get(filter, sortBy, sortDirection, page, size, link, params).pipe(map(events => events.map(e => this._configEvent(e))));
    }

    getById(id: number, hideError = false, getSchema = false): Observable<Tip | any> {

        console.log('getById');

        let params = new HttpParams();

        if (hideError) {
            params = params.set('angHideError', 'true');
        }

        return this.http
        .get<Tip>(`${this.resourceUrl + this.link}/${id}`, {params}).pipe(map(tip => {
            if (tip.about) {
                tip.tags = tip.about.split(',');
            }
            return tip;
        }));
    }



    getLikesCount(id: number): Observable<number>{
        return this.http.get<number>(this.resourceUrl + this.likesLink + '/tips/' + id + '/count');
    }

    isLiked(id: number): Observable<boolean> {
        return this.http.get<boolean>(this.resourceUrl + this.likesLink + '/tips/' + id ).pipe(map(l => true), catchError(e => of(false)));
    }

    addLike(tipsLikedId: number): Observable<boolean> {
        return this.http.post(this.resourceUrl + this.likesLink, {tipsLikedId}).pipe(map(l => true), catchError(e => of(false)));
    }

    removeLike(tipsLikedId: number): Observable<boolean> {
        return this.http.delete(this.resourceUrl + this.likesLink + '/tips/' + tipsLikedId).pipe(map(l => true), catchError(e => of(false)));
    }


    _configEvent(tip: Tip): Tip {
        tip = setImages(tip);
        if (tip.about) {
            tip.tags = tip.about.split(',');
        }
        return tip;
    }


    delete(id: number): Observable<any> {
        return this.http.delete<any>(`${this.resourceUrl + this.link}/${id}`);
    }

    deleteBatch(ids: number[]): Promise<any> {
        return Promise.all(
            ids.map(id => this.delete(id).toPromise())
        );
    }



}

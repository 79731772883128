import { FlexModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { AlertSnackbarComponent } from './alert-snackbar/alert-snackbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [AlertDialogComponent, AlertSnackbarComponent],
  imports: [
    CommonModule,
    FlexModule,

    MatExpansionModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    TranslateModule,
  ],
  exports: [
      AlertDialogComponent,
      AlertSnackbarComponent
  ]
})
export class MyspotAlertsModule { }

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Offer } from '../../../models/workplace/offer.model';

@Component({
  selector: 'ms-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['../myspot-card.component.scss', './offer-card.component.scss']
})
export class OfferCardComponent implements OnInit {

  @Input('offer') offer: Offer;
  @Input('options') options: any;
  @Output('clicked') clicked =  new EventEmitter<string>();

  currency: string;

  constructor() { }

  ngOnInit() {

  }

  modify() {
    this.clicked.emit('modify');
  }

  delete() {
    this.clicked.emit('delete');
  }

  rooms() {
    this.clicked.emit('select');
  }

}

import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import StartOf = moment.unitOfTime.StartOf;
import {User} from '@myspot/models/people/user';
import {unitOfTime} from 'moment';
// import * as _ from 'lodash';

export function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

export function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}

export function ifSet(object, path): boolean {
    return path.split('.').reduce((obj, part) => obj && obj[part], object);
  }


export function setImages(obj: any): any {
  let images = [];

  if (!obj) {
    return undefined;
  }

  if (obj.image) {
    images = obj.image.split(';');
  }

  if (!images || images.length === 0) {
    if (obj.additionalType) {
      if (obj.additionalType === 'SEAT') {
        switch (obj.alternateName) {
          case 'PRS':
            images = [`/placeholder/PRESENCE.jpg`];
            break;
          default:
            images = [`/placeholder/${obj.additionalType}.jpg`];
            break;
        }
      } else {
        images = [`/placeholder/${obj.additionalType}.jpg`];
      }
    }
  }

  obj.images = images;

  return obj;

}

export function toCamelCase(myString: string): string {

  console.log('toCamelCase');
  if (!myString || typeof myString !== 'string'){
    return '';
  }

  return myString.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function isExpired(date: any, granularity: StartOf = 'day'): boolean {
    return moment(date).isBefore(moment(), granularity);
}

export function ValidateExpiredDate(control: AbstractControl): {[key: string]: any} | null  {
    if (control.value && isExpired(control.value)) {
        return { dateExpired: true };
    }
    return null;
}

export function isSameUser(userA: User, userB: User): boolean {
    if  (!userA && !userB) {
        return true;
    }

    if (!userA || !userB) {
        return false;
    }

    return userA.email === userB.email && userA.activeRole === userB.activeRole;

}

export function getRootPath(pathname: string, root = '/'): string {
    const paths = pathname.split('/');
    return paths && paths[1] ? root + paths[1] : root;
}

export function flexFont(classNames: string): void {
  const divs: any = document.getElementsByClassName(classNames);
  for (const div of divs) {
    const relFontsize = div.offsetWidth * 0.05;
    div.style.fontSize = relFontsize + 'px';
  }
}

export function validateEmail(email): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getDomainFromEmail(email: string): string {
  if (validateEmail(email)) {
    return email.split('@')[1];
  }
  return '';
}

export function removeParam(url: string, param: string): string {
  const realURL = new URL(url);
  realURL.searchParams.delete(param);
  return realURL.href;
}

export function setParam(url: string, param: string, value: string): string {
  const realURL = new URL(url);
  realURL.searchParams.set(param, value);
  return realURL.href;
}

export function getQueryParameter(key: string): string {
  const parameters = new URLSearchParams(window.location.search);
  return parameters.get(key);
}

export function getDTSOffset(tz: string): number {
  const janOffset = tz ? moment({M: 0, d: 1}).tz(tz).utcOffset() : moment({M: 0, d: 1}).utcOffset();
  const julOffset = tz ? moment({M: 6, d: 1}).tz(tz).utcOffset() : moment({M: 6, d: 1}).utcOffset();
  return janOffset - julOffset;
}

export function removeDTS(date: moment.Moment, timezone: string): moment.Moment {

  if (!date) {
    return;
  }

  if (!date.isDST()) {
    date.add(getDTSOffset(timezone), 'minutes');
  }

  return date;
}

export function getDateTZZ(date: moment.Moment): string {
  return date ? moment(date).format('ZZ') : '';
}

export function initBooleanInput(variable: any): boolean {
  return variable === '' ? true : variable;
}

export function isKnownDomain(mail: string, domains: string[]): boolean {
  return !validateEmail(mail) ? false : domains.includes(getDomainFromEmail(mail));
}

export function emailDomainValidator(domains: string[], known = true): ValidatorFn  {
  return (control: AbstractControl): ValidationErrors | null => {

    if (!validateEmail(control.value)) {
      return null;
    }

    const knownDomain = isKnownDomain(control.value, domains);

    if (knownDomain && !known) {
      return {knownDomain: true};
    }

    if (!knownDomain && known) {
      return {unknownDomain: true};
    }

    return null;
  };
}

export function calculateVatPrice(totalPrice: number, vatCode: 'NOVAT' | 'V10' | 'V22'): {netPrice: number, vatPrice: number} {
  const vatValues = {
    NOVAT: 0,
    V10: 10,
    V22: 22
  };
  const vat = vatValues[vatCode];
  const netPrice = +(totalPrice / ( 1 + (vat / 100))).toFixed(2);
  const vatPrice = +(totalPrice - netPrice).toFixed(2);

  return {netPrice, vatPrice};
}

export function compareTimes(a = '00:00', b = '23:59', format = moment.HTML5_FMT.TIME): number {
  const aM = moment(a, format);
  const bM = moment(b, format);
  return aM.isBefore(bM) ? -1 : aM.isAfter(bM) ? 1 : 0;
}

export function minDateValidator(minControl: AbstractControl): ValidatorFn  {
  return (control: AbstractControl): ValidationErrors | null => {
    return  (compareTimes(minControl.value, control.value) === 1) ? {invalidTime: true} : null;
  };
}

export function getMomentFromHHMM(time: string, day: moment.Moment = moment()): moment.Moment {

  if (!time) {
    return undefined;
  }

  const times = time.split(':');
  const today = day.clone();
  today.set('hour', +times[0]).set('minutes', +times[1]);

  return today;
}


export function parseJwt(token): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function roundMoment(
  date: moment.Moment,
  to: number,
  unit: unitOfTime.DurationConstructor | any,
  mode: 'ceil' | 'floor' = 'ceil'): moment.Moment {
  if (!date) {
    return undefined;
  }

  const unitToRound = date.get(unit);
  let amount;
  if (mode == 'ceil') {
    amount = (to - unitToRound) % to;
    date.add(amount, unit);
  } else {
    amount = unitToRound % to;
    date.subtract(amount, unit);
  }

  return date;
}

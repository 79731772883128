import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Guest} from '@myspot/models/people/guest';
import {Person} from '@myspot/models/people/person';
import {AccessGuestStatus} from '../../../models/access/access';
import {isExpired} from '../../../util/utils';
import * as momentTz from 'moment-timezone';
import {MyspotCardComponent} from '../myspot-card.component';
import {User} from '@myspot/models/people/user';

@Component({
  selector: 'ms-guest-card',
  templateUrl: './guest-card.component.html',
  styleUrls: ['../myspot-card.component.scss', './guest-card.component.scss']
})
export class GuestCardComponent extends MyspotCardComponent implements OnInit, OnChanges {

  @Input() guest: Guest;
  @Input() status: AccessGuestStatus;
  @Input() tz = momentTz.tz(momentTz.tz.guess()).format('ZZ');
  @Input() carded;
  email = '';
  name = '';
  lastName = '';
  organization = '';
  person: Person;
  avatar =  'assets/images/avatars/profile.jpg';
  user: User;

  acs: typeof AccessGuestStatus;


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.guest || changes.status) {
      this.initGuest();
    }
  }

  ngOnInit(): void {
  }

  initGuest(): void {
    if (!this.guest) {
      this.guest = {};
      return;
    }

    if (!this.guest.verified) {
      this.status = AccessGuestStatus.GUEST_NOT_REGISTERED;
    } else
    if (!this.status) {
      this.status = this.guest.status;
    }

    this.user = this.guest.user;
    if (this.user) {
        this.email = this.user.email;
        this.name = this.user.firstName;
        this.lastName = this.user.lastName;
        this.person = this.user.person || {};
        if (this.person) {
            this.organization = this.person?.companyName;
            this.cardImg = this.person?.photo ? this.cloudPath + this.person?.photo : this.avatar;
        }
    }
  }

  isDocumentExpired(date: any): boolean {
    return isExpired(date);
  }

}

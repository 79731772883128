import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';


import { MSEvent, MSBroadcastEvent, BroadcastEvent } from '../../models/events/event';
import { PageService } from '../../apps/page.service';
import { map, catchError } from 'rxjs/operators';
import { setImages } from '../../util/utils';
import * as moment from 'moment';
import { UserEvent } from '@myspot/models/events/user-event';

interface ElasticQuery {
    query?: {
        bool?: {
            must?: {
                match_all?: {},
                wildcard?: {
                    name?: string
                }
            },
            match_phrase_prefix?: {
                name?: {
                    query?: string,
                    max_expansions?: number
                }
            }
            filter?: ({
                term?: {
                    additionalType: string
                }
            } |
            {
                term?: {
                    about: string
                }
            } |
            {
                geo_distance?: {
                    distance: string,
                    position: number[]
                }
            } |
            {
                range?: {
                'offers.price'?: {
                    gte?: number,
                    lte?: number
                    }
                }
            } |
            {
                range?: {
                'eventSchedules.startingDateTime'?: {
                    gte?: string,
                    lte?: string
                    }
                }
            } |
            {
                range?: {
                'eventSchedules.endingEndTime'?: {
                    gte?: string,
                    lte?: string
                    }
                }
            })[]
        }
    }
};


@Injectable()
export class EventAPIService extends PageService {

    elasticQuery: ElasticQuery;

    protected resourceUrl = '/myspoteventservice';
    protected link = '/api/events';
    protected usersLink = '/api/users';
    protected virtualLink = '/api/broadcast-events';
    protected elasticUrl = '/myspot-events-dev/_search/';
    protected likesLink = '/api/likes';

    constructor(protected http: HttpClient) {
        super(http);
    }

    get(filter?, sortBy = 'id', sortDirection = 'asc', page = 0, size = 10, link = this.link, params?: HttpParams): Observable<MSEvent[]> {
        filter = filter ? filter : undefined;
        return super.get(filter, sortBy, sortDirection, page, size, link, params).pipe(map(events => events.map(e => this._configEvent(e))));
    }

    getUsers(size?: number): Observable<UserEvent> {
        let params = new HttpParams();
        if (size) {
            params = params.append('size', size + '');
        }
        return this.http.get<UserEvent>(`${this.resourceUrl}${this.usersLink}`, { params });
    }

    getById(id: number, hideError = false, getSchema = false): Observable<MSEvent | any> {

        console.log('getById');

        let params = new HttpParams();
        const schemaPath = (getSchema) ? '/schema' : '';

        if (hideError) {
            params = params.set('angHideError', 'true');
        }

        return this.http
        .get<MSEvent>(`${this.resourceUrl + this.link}/${id}${schemaPath}`, {params}).pipe(map(e => {
            if (e.about) {
                e.tags = e.about.split(';');
            }
            e = setImages(e);
            return e;
        }));
    }

    getPartnerships(size?: number): Observable<any> {
        let params = new HttpParams();
        if (size) {
            params = params.append('size', size + '');
        }
        return this.http.get(`${this.resourceUrl}/api/partnerships`, { params });
    }

    getVirtualById(id: number, hideError = false, getSchema = false): Observable<MSBroadcastEvent | any> {

        let params = new HttpParams();
        const schemaPath = (getSchema) ? '/schema' : '';
        if (hideError) {
            params = params.set('angHideError', 'true');
        }

        return this.http
        .get<MSBroadcastEvent>(`${this.resourceUrl + this.virtualLink}/${id}${schemaPath}`, {params}).pipe(map(e => this._configEvent(e)));
    }

    getLikesCount(id): Observable<number>{
        return this.http.get<number>(this.resourceUrl + this.likesLink + '/events/' + id + '/count');
    }

    isLiked(id: number): Observable<boolean> {
        return this.http.get<boolean>(this.resourceUrl + this.likesLink + '/events/' + id ).pipe(map(l => true), catchError(e => of(false)));
    }

    addLike(eventLikedId: number): Observable<boolean> {
        return this.http.post(this.resourceUrl + this.likesLink, {eventLikedId}).pipe(map(l => true), catchError(e => of(false)));
    }

    removeLike(eventLikedId: number): Observable<boolean> {
        return this.http.delete(this.resourceUrl + this.likesLink + '/events/' + eventLikedId).pipe(map(l => true), catchError(e => of(false)));
    }

    getElastic(
        longitude: number,
        latitude: number,
        distance = '30km',
        name?: string,
        startDate?: string,
        endDate?: string,
        priceMin = 0,
        priceMax = 10000,
        size = 30,
        page = 0): Observable<MSEvent[]> {

        console.log('getElastic');

        this.elasticQuery = {
            query: {
                bool: {
                    filter: [{
                        geo_distance: {
                            distance,
                            position: [longitude, latitude]
                        }
                    }]
                }
            }
        };

        if (name) {
           /* this.elasticQuery.query.bool.must = {
                match: {
                    name: name + '.*'
                }
            };*/

            this.elasticQuery.query.bool.must = {
                wildcard: {
                    name: '*' + name + '*'
                }
            };

        } else {
            this.elasticQuery.query.bool.must = {
                match_all: {}
            };
        }

        if (startDate) {
            this.elasticQuery.query.bool.filter.push({
                range: {
                    'eventSchedules.startingDateTime': {
                        gte: startDate
                    }
                }
            });
        }

        if (endDate) {
            this.elasticQuery.query.bool.filter.push({
                range: {
                    'eventSchedules.endingEndTime': {
                        lte: endDate
                    }
                }
            });
        }


       /* this.elasticQuery.query.bool.filter.push({
            range: {
                'offers.price': {
                    gte: priceMin || 0,
                    lte: priceMax || 10000,
                }
            }
        }); */

        const params = new HttpParams().set('query', JSON.stringify(this.elasticQuery));




        return this.http.get<MSEvent[]>(this.resourceUrl + this.link + '/_search', {params}).pipe(map(events => {

            events = events.map(e => this._configEvent(e));

            this.count = events.length;
            return events;
        }));
    }

    _configEvent(e: MSEvent): MSEvent {
        e = setImages(e);
        if (e.about) {
            e.tags = e.about.split(';');
        }
        return e;
    }

    generateReport(id: number): Observable<any> {
        const downloadRequest = new HttpRequest('GET', this.resourceUrl + this.link + '/' + id + '/attendees/report', {
            reportProgress: true,
            responseType: 'text',
            observe: 'response'
        });
        return this.http.request(downloadRequest);
    }

    delete(id: number): Observable<any> {
        return this.http.delete<any>(`${this.resourceUrl + this.link}/${id}`);
    }

    deleteBatch(ids: number[]): Promise<any> {
        return Promise.all(
            ids.map(id => this.delete(id).toPromise())
        );
    }

    save(event: MSEvent | BroadcastEvent, isVirtual = false): Observable<MSEvent> {

        const link = (isVirtual) ? this.virtualLink : this.link;

        if (event.id) {
            return this.http.put<MSEvent | BroadcastEvent>(`${this.resourceUrl + link}`, event).pipe(map(e => this._configEvent(e)));
        }
        return this.http.post<MSEvent | BroadcastEvent>(`${this.resourceUrl + link}`, event).pipe(map(e => this._configEvent(e)));

    }

}

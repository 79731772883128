import { TranslateService } from '@ngx-translate/core';
import { CardInfo } from '../myspot-card.component';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MyspotCardComponent } from '../myspot-card.component';
import { Subscription } from 'rxjs';
import { Tip } from '../../../models/tip/tip';
import { TipsAPIService } from '../../../services/tips.service';

@Component({
  selector: 'ms-tip-card',
  templateUrl: './tip-card.component.html',
  styleUrls: ['../myspot-card.component.scss', './tip-card.component.scss']
})
export class TipCardComponent extends MyspotCardComponent implements OnInit, OnDestroy {

  @Input() tip: Tip;

  likesCount = 0;

  likesCount$: Subscription;

  constructor(protected tipsService: TipsAPIService, public translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit(): void {
    this.info = this.tip as CardInfo;
    super.ngOnInit();


    // this.likesCount$ = this.eventService.getLikesCount(this.tip.id).subscribe(c => this.likesCount = c);
  }

  ngOnDestroy(): void {
      // this.likesCount$.unsubscribe();
  }

}

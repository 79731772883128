import { HttpParams, HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { setImages } from '../util/utils';
import { Page } from '../models/page';

@Injectable()
export class PageService {

  filterProperty = 'name';

  data: any;

  firstPage = '';
  prevPage = '';
  nextPage = '';
  lastPage = '';

  count = 0;

  headers: HttpHeaders;

  onChanged: BehaviorSubject<any> = new BehaviorSubject({});

  protected resourceUrl = ''; // CHILD MUST HAVE IT COMPILED
  protected link = ''; // CHILD MUST HAVE IT COMPILED

  disableBEPagination = false;


  constructor(protected http: HttpClient) { }

  setResourceUrl(url: string): void {
    this.resourceUrl = url;
  }

  setLink(link: string): void {
    this.link = link;
  }

  protected configGetParams(
    filter?: string | { field: string; search: string },
    sortBy = 'id',
    sortDirection = 'asc',
    page = 0,
    size = 10,
    params?: HttpParams): HttpParams {

    params = params || new HttpParams();

    if (filter) {
      if (filter['field']) {
        if (filter['field'] === 'search') {
          params = params.append( 'search', filter['search'] as string);
        } else {
          params = params.append(filter['field'] + '.contains', filter['search'] as string);
        }

      } else {
        params = params.append(this.filterProperty + '.contains', filter as string);
      }
    }

    if (!this.disableBEPagination) {
      if (sortBy) {
        const sort = sortBy + ',' + (sortDirection || 'asc');
        params = params
          .append('sort', sort);
      }


      params = params
        .append('page', page + '')
        .append('size', size + '');
    }

    return params;
  }

  get(
    filter?: string | { field: string; search: string },
    sortBy = 'id',
    sortDirection = 'asc',
    page = 0,
    size = 10,
    link = this.link,
    params = new HttpParams()
  ): Observable<any[]> {

    console.log('url', this.resourceUrl + link);

    params = this.configGetParams(
      filter,
      sortBy,
      sortDirection,
      page,
      size,
      params);

    return this.getByParams(params, link);
  }


  getPaged(page?: Page, params?: HttpParams): Observable<any[]> {
    page = page || new Page();
    return this.get('', page.sortBy, page.sortDirection, page.pageNumber, page.size, page.link, params);
  }

  getByIds(page?: Page, ids: number[] = []): Observable<any[]> {

    let params = new HttpParams();
    params = params.set('id.in', ids.toString());

    return this.getPaged(page, params);
  }

  getByParams(params: HttpParams, link = this.link): Observable<any[]> {
    return this.http
      .get<any[]>(this.resourceUrl + link, {
        params,
        observe: 'response'
      })
      .pipe(
        tap(response => {
          this.parseResponse(response);
        }),
        map(response => {
          console.log(response);
          return response.body;
        })
      );
  }

  parseResponse(response: HttpResponse<any[]>): void {
    this.retrieve_pagination_links(response);
    this.headers = response.headers;
    this.data = response.body ? response.body : [];

    if (this.headers.get('x-total-count') === null) {
      this.count = this.data.length;
    } else {
      this.count = +this.headers.get('x-total-count');
      // console.log(this.headers.get('link'));
    }

    this.data.map(d => setImages(d));

    console.log('data', this.data);

    this.onChanged.next(this.data);
  }

  getCountByParams(params: HttpParams): Observable<number> {
    const headers = new HttpHeaders().set('responseType', 'text');
    return this.http
      .get<number>(this.resourceUrl + this.link + '/count', {
        params,
        headers,
      });
  }

  parse_link_header(header): {} {
    if (!header?.length) {
      return;
    }

    const parts = header.split(',');
    const links = {};
    parts.forEach(p => {
      const section = p.split(';');
      const url = section[0].replace(/<(.*)>/, '$1').trim();
      const name = section[1].replace(/rel="(.*)"/, '$1').trim();
      links[name] = url;
    });
    console.log(links);
    return links;
  }

  retrieve_pagination_links(response): void {
    const linkHeader = this.parse_link_header(response.headers.get('Link')) || {};
    this.firstPage = linkHeader['first'];
    this.lastPage = linkHeader['last'];
    this.prevPage = linkHeader['prev'];
    this.nextPage = linkHeader['next'];
  }

  delete(id: number | string): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl + this.link}/${id}`);
  }

  deleteBatch(ids: number[]): Promise<any> {
    return Promise.all(
      ids.map(id => this.delete(id).toPromise())
    );
  }

}


<div class="card-container {{layout}}" [ngClass]="{'flat': flat}">
  <div class="check">
      <mat-icon>check</mat-icon>
  </div>
  <div class="fuse-card ms-card">


      <div class="position-relative" class="card-img-container">
          <div class="card-img" [ngStyle]="{'background-image': 'url(' + cardImg + ')'}"></div>
      </div>

      <div class="card-body" fxLayout="column" fxLayoutAlign="space-between start">
          <div *ngIf="tip" class="top w-100-p">
              <div class="card-title">
                  {{ (tip.name.length>36)? (tip.name | slice:0:36)+'..':(tip.name) }}
              </div>
          </div>
          <div class="middle w-100-p">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">                   
                    <mat-icon>today</mat-icon>
                    <span class="date">{{tip.dateCreated | date: 'E d MMM yyyy' }}</span>
                </div>
          <div class="bottom w-100-p">
              <div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="start top" fxLayoutGap="6px grid">
                <div *ngFor="let tag of tip.tags">
                    <div class="container-name chip secondary">{{tag}}</div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
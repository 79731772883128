import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MyspotCardModule} from '@myspot/components/myspot-card/myspot-card.module';
import {MyspotDirectiveModule} from '@myspot/directives/myspot-directive.module';
import {TranslateCompiler, TranslateModule} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';

import config from './app.config';

import {
  OKTA_CONFIG,
  OktaAuthGuard,
  OktaAuthModule,
} from '@okta/okta-angular';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './util/token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { LodgingBusinessAPIService } from '@myspot/services/workplace/lodging-business.service';
import {HomeComponent} from './home/home.component';
import {CommonModule} from '@angular/common';
import {MeetingRoomAPIService} from '@myspot/services/workplace/meeting-room.service';
import {MatButtonModule} from '@angular/material/button';
import {MyspotAlertService} from '@myspot/services/alert.service';
import {MyspotAlertsModule} from '@myspot/components/alerts/alerts.module';
import {PrivacyService} from '@myspot/services/people/privacy.service';
import { NgUniversalModule, TransferHttpCacheModule } from '@trilon/ng-universal';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    MyspotCardModule,
    MyspotDirectiveModule,
    MyspotAlertsModule,

    OktaAuthModule,

    LayoutModule,
    FlexLayoutModule,

    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      }
    }),
    MatButtonModule,
    TransferHttpCacheModule,
  ],
  providers: [{ provide: OKTA_CONFIG, useValue: config.oidc },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    CookieService,
    LodgingBusinessAPIService,
    MeetingRoomAPIService,
    MyspotAlertService,
    PrivacyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { TranslateService } from '@ngx-translate/core';
import { CardInfo } from '../myspot-card.component';
import { Offer } from '../../../models/workplace/offer.model';
import { Place } from '../../../models/workplace/place.model';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MyspotCardComponent } from '../myspot-card.component';
import { MSEvent } from '../../../models/events/event';
import { EventAPIService } from '../../../services/event/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ms-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['../myspot-card.component.scss', './event-card.component.scss']
})
export class EventCardComponent extends MyspotCardComponent implements OnInit, OnDestroy {

  @Input() event: MSEvent;
  @Input() place: Place;
  @Input() offer: Offer;

  subscribers = 0;
  likesCount = 0;

  likesCount$: Subscription;

  constructor(protected eventService: EventAPIService, public translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit(): void {
    this.info = this.event as CardInfo;
    super.ngOnInit();

    this.subscribers = this.event.maximumAttendeeCapacity - this.event.remainingAttendeeCapacity;

    this.likesCount$ = this.eventService.getLikesCount(this.event.id).subscribe(c => this.likesCount = c);
  }

  ngOnDestroy(): void {
      this.likesCount$.unsubscribe();
  }

}

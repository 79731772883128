export class Page {
    // The number of elements in the page
    size = 0;
    // The total number of elements
    totalElements = 0;
    // The total number of pages
    totalPages = 0;
    // The current page number
    pageNumber = 0;

    offset = 0;

    link: string;
    sortBy: string;
    sortDirection: string;
}

export interface Page2 {
    currentPage: number
    pageSize: number
    totalPages: number
}

import { PageService } from '../../apps/page.service';
import { Observable } from 'rxjs';
import {IPlace, PlaceType} from '../../models/workplace/place.model';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { createRequestOption } from '../..';

@Injectable()
export class PlaceAPIService extends PageService {

    protected resourceUrl = '/myspotworkplaceservice';
    protected link = '/api/places';

    protected sortBy = 'id';
    protected sortDirection = 'asc';
    protected page = 0;
    protected size = 10;

    protected _addDefaultParams(params: HttpParams = new HttpParams()): HttpParams {
      params = params.append('additionalType.in', 'BUILDING')
        .append('additionalType.in', 'FLOOR')
        .append('additionalType.in', 'ZONE')
        .append('additionalType.in', 'LODGINGBUSINESS')
        .append('additionalType.in', 'MEETINGROOM')
        .append('additionalType.in', 'DESK')
        .append('additionalType.in', 'PARKING_SLOT');

      return params;
    }

    get(
      filter?,
      sortBy = this.sortBy,
      sortDirection = this.sortDirection,
      page = this.page,
      size = this.size ,
      link = this.link,
      params?: HttpParams): Observable<IPlace[]> {

        if (link === '/api/places') {
          params = this._addDefaultParams(params);
        }

        return super.get(filter, sortBy, sortDirection, page, size, link, params);

    }

    getByType(additionalType: string): Observable<IPlace[]> {
      let params = new HttpParams();
      params = params .append('additionalType.in', additionalType);
      return super.getByParams(params);
    }

    getById(id: number): Observable<IPlace> {
      return this.http.get<IPlace>(`${this.resourceUrl + this.link}/${id}`);
    }

    getContainedInPlaceIdsById(id: number): Observable<number[]> {
      return this.http.get<number[]>(`${this.resourceUrl + this.link}/ids?containedInPlaceId=${id}`);
    }

    getByParams(params: HttpParams): Observable<IPlace[]>{
      return super.getByParams(params);
    }

    getByOffered(
      offerId: number,
      filter?,
      sortBy = this.sortBy,
      sortDirection = this.sortDirection,
      page = this.page,
      size = this.size ,
      link = this.link,
      params?: HttpParams): Observable<IPlace[]> {

      params = this._addDefaultParams(params);

      params = params.set('containedInOffersId.equals', offerId + '');


      return this.get(filter, sortBy, sortDirection, page, size, link, params);
    }

    findByName(
      name: string = '',
      sortBy = this.sortBy,
      sortDirection = this.sortDirection,
      page,
      size = this.size,
      link = this.link,
      params?: HttpParams): Observable<IPlace[]> {
      params = params || new HttpParams();
      params = params.append('name.contains', name);
      params = params.append('page', page ? page.toString() : 0);
      params = params.append('size', size.toString());

      if (sortDirection){
        params = params.append('sort', sortDirection);
      }

      return super.getByParams(params);

    }

    beforeSave(place: IPlace): IPlace {
      place.physicalRepresentationOfs = place.physicalRepresentationOfs || [];
      place.containsPlaces = place.containsPlaces || [];
      place.containsEquipments = place.containsEquipments || [];
      place.openingHoursSpecifications = place.openingHoursSpecifications || [];
      place.specialOpeningHoursSpecifications = place.specialOpeningHoursSpecifications || [];
      place.assignedTos = place.assignedTos || [];
      place.amenityFeatures = place.amenityFeatures || [];
      place.purposes = place.purposes || [];
      place.containedInZones = place.containedInZones || [];
      place.containedInOffers = place.containedInOffers || [];
      place.containedInClusters = place.containedInClusters || [];

      return place;
    }

    save(place: IPlace): Observable<IPlace> {

      place = this.beforeSave(place);

      if (place.id) {
        return this.http.put<IPlace>(`${this.resourceUrl + this.link}`, place);
      }
      return this.http.post<IPlace>(`${this.resourceUrl + this.link}`, place);

    }

    delete(id: number): Observable<any> {
      return this.http.delete<any>(`${this.resourceUrl + this.link}/${id}`);
    }

    deleteBatch(ids: number[]): Promise<any> {
      return Promise.all(
          ids.map(id => this.delete(id).toPromise())
      );
    }

    query(req?: any): Observable<IPlace[]> {
      const options = createRequestOption(req);
      return this.http.get<IPlace[]>(`${this.resourceUrl + this.link}`, { params: options, observe: 'response' })
          .pipe(map(response => response.body));
    }

    getByTenantCode(tenantCode: string): Observable<IPlace[]> {
      let params = new HttpParams();
      params = params.append('tenantCode.equals', tenantCode);
      return this.get(undefined, undefined, undefined, undefined, undefined, undefined, params);
    }


    getUserAssignedPlaces(id): Observable<IPlace[]> {
      return this.http.get<IPlace[]>(`${this.resourceUrl + this.link}/assigned-to/${id}`);
    }

  getObjects(id: string, additionalTypes: PlaceType[] = [], pageIndex: number, pageSize: number): Observable<IPlace[]> {

    let params = new HttpParams()
      .set('page', pageIndex + '')
      .set('size', pageSize + '');

    for (const type of additionalTypes) {
      params = params.append('additionalType.in', type);
    }

    if (id) {
      params = params
        .append('containedInPlaceId.equals', id);

    }
    return this.getByParams(params);
  }
}


<!-- <div
  fxFlex
  fxLayout.gt-xs="row wrap"
  fxLayout="column"
  fxLayoutAlign.gt-xs="center stretch"
  fxLayoutAlign="stretch center"
  fxLayoutGap.gt-xs="24px grid"
  fxLayoutGap="12px"
  class="card-list"
>

<ms-place-card
  fxFlex="0 1 auto"
  [place] = "place"
  [offer] = "undefined"
  itemSelector
  [isSelected]="true"
  (isSelectedChange)="selected()"
></ms-place-card>

</div>
-->
<router-outlet></router-outlet>

<div class="card-container {{layout}}" [ngClass]="{'flat': flat}">
    <div class="check">
        <mat-icon>check</mat-icon>
    </div>
    <div class="fuse-card ms-card">

        <div *ngIf="options?.showButtons" class="p-16 pt-0 buttons" fxLayout="row" fxLayoutAlign="end top"
            fxLayoutGap="5px">
            <button mat-icon-button color="accent" (click)="modify()">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="delete()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>

        <div class="position-relative" class="card-img-container">
            <div class="card-img" [ngStyle]="{'background-image': 'url(' + cardImg + ')'}"></div>

            <div *ngIf="event.organizers && event.organizers[0]?.legalName" class="position-absolute align-left align-top">
                    <div *ngIf="event.organizers[0]?.legalName" class="chip primary mt-16 ml-16">
                        <strong>{{event.organizers[0].legalName}}</strong>
                    </div>
                </div>

            <div class="position-absolute card-title align-left align-bottom">
                <div class="offer-price">
                    <div *ngIf="!offer || offer.totalPrice == 0" class="title">Free</div>
                    <div *ngIf="offer && offer.totalPrice > 0" class="title">{{offer.totalPrice}}{{offer.priceCurrency | translate}}</div>
                </div>
            </div>

            <div *ngIf="offer" class="card-date position-absolute align-right align-bottom">
                <div class="title">
                    <ng-container>
                        <span class="day">
                            {{schedule?.startingDateTime | date: 'd'}}
                        </span>
                        <span class="month">
                            {{schedule?.startingDateTime| date: 'MMM'}}
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="card-body" fxLayout="column" fxLayoutAlign="space-between start">
            <div *ngIf="event" class="top w-100-p">
                <div class="card-title">
                    {{ (event.name.length>36)? (event.name | slice:0:36)+'..':(event.name) }}
                </div>
                <div *ngIf="event.location?.address" class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon matSuffix class="secondary-text">location_on</mat-icon>
                    <span>{{event.location.address}} <div class="container-name chip accent">{{event.eventType}}</div></span>
                </div>
            </div>


            <p *ngIf="offer?.validFrom || offer?.validThrough">{{'EVENTS.OFFER_AVAILABILITY' | translate}}</p>
            <div *ngIf="offer?.validFrom" class="top w-100-p pb-8">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon matSuffix class="secondary-text">today</mat-icon>
                    <span class="date">{{'EVENTS.OFFER_AVAILABILITY_FROM' | translate}}{{offer.validFrom | date: 'mediumDate' : tz : locale}} </span>                    
                </div>
            </div>
            <div *ngIf="offer?.validThrough" class="top w-100-p pb-8">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon matSuffix class="secondary-text">today</mat-icon>
                    <span class="date">{{'EVENTS.OFFER_AVAILABILITY_TO' | translate}}{{offer.validThrough | date: 'mediumDate' : tz : locale}} </span>                    
                </div>
            </div>


            <div class="bottom w-100-p">
                
            </div>




        </div>

    </div>
</div>
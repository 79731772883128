import { TranslateService } from '@ngx-translate/core';
import { CardInfo } from '../myspot-card.component';
import { Offer } from '../../../models/workplace/offer.model';
import { Place } from '../../../models/workplace/place.model';
import { Component, OnInit, Input } from '@angular/core';
import { MyspotCardComponent } from '../myspot-card.component';
import { MSEvent, EventSchedule } from '../../../models/events/event';

@Component({
  selector: 'ms-event-ticket-card',
  templateUrl: './event-ticket-card.component.html',
  styleUrls: ['../myspot-card.component.scss', './event-ticket-card.component.scss']
})
export class EventTicketCardComponent extends MyspotCardComponent implements OnInit {

  @Input() event: MSEvent;
  @Input() place: Place;
  @Input() offer: Offer;
  @Input() schedule: EventSchedule;

  locale: string;

  ngOnInit() {
    this.info = this.event as CardInfo;
    super.ngOnInit();

  }

}

<h1 mat-dialog-title class="text-center mb-0 title">{{ title | translate }}</h1>
<div mat-dialog-content class="pb-20 pt-0 text-center">
    <img *ngIf="image"
        [src]="image"
    />
    <p class="message" [innerHtml]="message | translate"></p>
    <p *ngIf="tips" class="pb-12 tips" [innerHTML]="tips | translate"></p>
    <mat-expansion-panel class="mt-20" *ngIf="showDetails && details">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Error description
            </mat-panel-title>
            <mat-panel-description>
                nerd stuff only
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p
            style="word-wrap: break-word; max-width: 320px; text-align: left;"
            [innerHTML]="details | translate"
        ></p>
    </mat-expansion-panel>
</div>
<div mat-dialog-actions fxLayout="row-reverse" fxLayoutAlign="space-between center" class="w-100-p">
    <button *ngIf="showReturnTo" mat-flat-button color="primary" (click)="returnTo()" cdkFocusInitial>{{'INTERFACE.GO_BACK' | translate}}</button>
    <button
        mat-flat-button
        color="accent"
        (click)="onNoClick()"
        cdkFocusInitial
    >
        Ok
    </button>
</div>

<div *ngIf="error" class="error">
  {{ error }}
</div>

<div fxLayout="column" *ngIf="token; else noToken">
  {{token}}

</div>

<ng-template #noToken>
  <div *ngIf="!isAuthenticated" style="width: 100vw; height: 100%" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="18px">
    <img src="https://res.cloudinary.com/dlo69v6hu/image/upload/c_scale,w_60/v1610377515/logo-myspot-fill_sdtnij.png" alt="myspot"/>

    <button id="login-button" mat-flat-button color="primary" role="button" (click)="login()">Login</button>
  </div>

  <div *ngIf="isAuthenticated && userName" style="width: 100vw; height: 100%" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="18px">
    <img src="https://res.cloudinary.com/dlo69v6hu/image/upload/c_scale,w_60/v1610377515/logo-myspot-fill_sdtnij.png" alt="myspot"/>
    <p>Await redirection...</p>
  </div>
</ng-template>



import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    HostListener,
    Renderer2,
} from '@angular/core';
import { initBooleanInput } from '../util/utils';

@Directive({ selector: '[itemSelector]' })

export class ItemSelectorDirective implements OnChanges {

    @Input() itemSelector: boolean;
    @Input() item: any;
    @Input() isSelected;
    @Input() notSelectable;
    @Input() disableToggle;
    @Input() hideSelection;
    @Output() isSelectedChange: EventEmitter<boolean> = new EventEmitter();

    flag = 0;
    screenX = 0;

    @HostListener('mousemove', ['$event']) onMove($event): void {
        //console.log('clicked', $event);

        if (this.screenX > ($event.screenX + 10) || (this.screenX < ($event.screenX - 10))) {
            this.flag = 1;
        }

        this.screenX = $event.screenX;
    }
    @HostListener('mousedown', ['$event']) onDown($event): void {
        // console.log('clicked', $event);
        this.flag = 0;
    }
    @HostListener('mouseup', ['$event']) onUp($event): void {
        //console.log('clicked', $event);
        if (this.flag === 0) {
            this.el.nativeElement.scrollIntoView({ block: 'nearest' });
            this.toggleSelect();
        }

        this.flag = 2;
    }

    constructor(private renderer: Renderer2, private el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.initBooleans();
        this.classHandler();
    }

    initBooleans(): void {
        this.itemSelector = initBooleanInput(this.itemSelector);
        this.isSelected = initBooleanInput(this.isSelected);
        this.disableToggle = initBooleanInput(this.disableToggle);
        this.hideSelection = initBooleanInput(this.hideSelection);
        this.notSelectable = initBooleanInput(this.notSelectable);
        this.classHandler();
    }

    toggleSelect(): void {
        if (!this.itemSelector || this.notSelectable) {
            return;
        }

        if (!this.hideSelection) {
            if (!this.disableToggle) {
                this.isSelected = !this.isSelected;
                this.isSelectedChange.emit(this.isSelected);
            } else if (!this.isSelected) {
                this.isSelected = true;
                this.isSelectedChange.emit(this.isSelected);
            }

            this.classHandler();
        }
    }

    classHandler(): void {
        if (this.hideSelection || !this.itemSelector) {
            this.renderer.setStyle(this.el.nativeElement, 'cursor', 'auto');
            this.renderer.removeClass(this.el.nativeElement, 'selected');

            return;
        }

        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');

        if (this.isSelected) {
            this.renderer.addClass(this.el.nativeElement, 'selected');
            this.el.nativeElement.scrollIntoView({ block: 'nearest' });
        } else {
            this.renderer.removeClass(this.el.nativeElement, 'selected');
        }
    }

}


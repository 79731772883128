import { Place } from './place.model';

export enum DaysOfWeek {
    SUNDAY = 'SUNDAY',
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
}

export const DaysOfWeekValues = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
};

export interface IOpeningHoursSpecification {
    additionalType: string;
    alternateName?: string;
    closes?: string | Date;
    dayOfWeek?: DaysOfWeek;
    description?: string;
    disambiguatingDescription?: string;
    id?: 0;
    image?: 0;
    name?: string;
    opens?: string | Date;
    placeOpening?: Place;
    placeOpeningId?: number;
    placeSpecialOpening?: Place;
    placeSpecialOpeningId?: number;
    validFrom?: Date;
    validThroug?: Date;
}

export class OpeningHoursSpecification implements IOpeningHoursSpecification {
    additionalType = 'OPENINGHOURS';
    alternateName?: string;
    closes?: string | Date;
    dayOfWeek?: DaysOfWeek;
    description?: string;
    disambiguatingDescription?: string;
    id?: 0;
    image?: 0;
    name?: string;
    opens?: string | Date;
    placeOpening?: Place;
    placeOpeningId?: number;
    placeSpecialOpening?: Place;
    placeSpecialOpeningId?: number;
    validFrom?: Date;
    validThroug?: Date;
}

export interface IWeekOpeningHours {
    [prop: string]: OpeningHoursSpecification;
}

export class WeekOpeningHours implements IWeekOpeningHours {
    [prop: string]: OpeningHoursSpecification;
}

export interface IWeeklyClosing {
    [prop: string]: boolean;
}

export class WeeklyClosing implements IWeeklyClosing {
    [prop: string]: boolean;
}

<div *ngIf="place" class="card-container {{layout}}" [ngClass]="{'flat': flat, 'wide': wide}" (window:resize)="checkScrollable()">
        <div class="check">
                <mat-icon>check</mat-icon>
            </div>
            <div *ngIf="owner" class="owner" fxLayout="row" fxLayoutAlign="center center">
                <ms-avatar [person]="owner" [size]="80"></ms-avatar>
            </div>
            <div class="fuse-card ms-card">

                <div *ngIf="options?.showButtons" class="p-16 pt-0 buttons" fxLayout="row" fxLayoutAlign="end top"
                    fxLayoutGap="5px">
                    <button mat-icon-button color="accent" (click)="modify()">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="delete()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>

                <div class="position-relative card-img-container">
                    <div *ngIf="fullyAvailable" class="fully-available">
                        <mat-icon>check_circle</mat-icon>
                    </div>
                    <div class="card-img" [ngStyle]="{'background-image': 'url(' + cardImg + ')'}"></div>
                    <div class="position-absolute card-title align-left align-bottom no-wrap">

                        <div *ngIf="offer && offer.totalPrice" fxLayout="row" fxLayoutAlign="space-between center" class="offer-price">
                            <div fxFlex class="title">{{offer.totalPrice}}{{offer.priceCurrency | translate}} / {{offer.priceUnit | translate}}</div>
                            <div fxFlex="0 0 auto" class="container-name chip primary">
                                <ng-container *ngIf="!place.disambiguatingDescription; else templateDisDesc">
                                    {{place.additionalType | translate}}
                                </ng-container>
                            </div>
                        </div>

                        <div *ngIf="!offer || !offer.totalPrice">
                            <div class="container-name chip primary">
                                <ng-container *ngIf="!place.disambiguatingDescription; else templateDisDesc">
                                    {{place.additionalType | translate}}
                                </ng-container>
                            </div>

                        </div>

                        <ng-template #templateDisDesc>
                                {{place.disambiguatingDescription}}
                        </ng-template>

                    </div>
                </div>

                <div class="card-body" fxLayout="column" #cardBody fxLayoutAlign="space-between start">
                    <div class="top w-100-p">
                        <div class="card-title no-wrap" [ngClass]="{'scroll': titleToScroll}" #cardTitle>
                            {{place.name}}
                        </div>
                        <div *ngIf="place.address" class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="secondary-text">location_on</mat-icon>
                            <span style="overflow: hidden">
                                <span class="no-wrap" #cardAddress [ngClass]="{'scroll': addressToScroll}">{{place.address}}</span>
                            </span>

                        </div>
                    </div>
                    <div class="middle w-100-p">

                            <ng-container *ngIf="place.amenityFeatures?.length && layout == 'horizontal'">
                                    <div class="feats-icon-container pt-16 w-100-p" fxLayout="row wrap" fxLayoutGap="5px grid">
                                        <ng-container *ngFor="let feat of place.amenityFeatures; let i=index">
                                            <div *ngIf="feat.name && i < 6" class="feat mb-0">
                                                <mat-icon matTooltip="{{feat.name}}">{{feat.image}}</mat-icon>
                                            </div>
                                            <div *ngIf="feat.name && i == 6" class="feat mb-0">
                                                <mat-icon matTooltip="">more_horiz</mat-icon>
                                            </div>
                                        </ng-container>
                                    </div>
                            </ng-container>

                        <ng-container *ngIf="showDetails">
                            <div fusePerfectScrollbar>

                                <ng-container>
                                    <div class="section-title">{{'COMMON.' + place.additionalType + '_DETAILS_INFO' | translate}} </div>
                                    <div class="credits-row">
                                        <div class="name">
                                            <mat-icon>label</mat-icon>{{'COMMON.NAME' | translate}}
                                        </div>
                                        <div class="value">{{place.name}}</div>
                                    </div>
                                    <div class="credits-row" *ngIf="place.additionalType === 'MEETING_ROOM'">
                                        <div class="name">
                                            <mat-icon>event_seat</mat-icon>{{'COMMON.SEATS' | translate}}
                                        </div>
                                        <div class="value">{{place.maximumAttendeeCapacity}}</div>
                                    </div>
                                    <div class="credits-row">
                                        <div class="name">
                                            <mat-icon>sell</mat-icon>{{'COMMON.CODE' | translate}}
                                        </div>
                                        <div class="value">{{place.alternateName}}</div>
                                    </div>
                                    <div class="credits-row">
                                        <div class="name">
                                            <mat-icon>public</mat-icon>{{'Public access' | translate}}
                                        </div>
                                        <div class="value">{{place.publicAccess}}</div>
                                    </div>
                                    <div class="credits-row">
                                        <div class="name">
                                            <mat-icon>bolt</mat-icon>{{'Quickbookable' | translate}}
                                        </div>
                                        <div class="value">{{place.isQuickBookable}}</div>
                                    </div>

                                    <div class="credits-row">
                                        <div class="name">
                                            <mat-icon>event_seat</mat-icon>{{'COMMON.PURPOSE' | translate}}
                                        </div>
                                        <div class="value" *ngFor="let p of place.purposes">{{p.name}}</div>
                                    </div>


                                    <div class="credits-row long" *ngIf="place.description">
                                        <div class="name">
                                            <mat-icon>info</mat-icon>{{'COMMON.DESCRIPTION' | translate}}
                                        </div>
                                        <div class="value" [innerHTML]="place.description"></div>
                                    </div>

                                    <ng-container *ngIf="place.amenityFeatures?.length">
                                        <div class="section-title">{{'COMMON.FEATURES' | translate}}</div>
                                        <div class="feats-container" fxLayout="row wrap" fx fxLayoutGap="2rem grid">
                                            <ng-container *ngFor="let feat of place.amenityFeatures">
                                                <div *ngIf="feat.name" class="feat" fxFlex="50%">
                                                    <mat-icon>{{feat.image}}</mat-icon>{{feat.name}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="showDetails">
                                        <div class="credits-row" *ngIf="floor">
                                            <div class="name">
                                                <mat-icon>stairs</mat-icon>{{'COMMON.FLOOR' | translate}}
                                            </div>
                                            <div class="value">{{floor.name}} ({{floor.alternateName}})</div>
                                            <div class="value" *ngIf="floor?.description" [innerHTML]="floor.description"></div>
                                        </div>

                                        <ng-container *ngIf="lodging">
                                            <div class="credits-row">
                                                <div class="name">
                                                    <mat-icon>business</mat-icon>{{'SPOT' | translate}}
                                                </div>
                                                <div class="value">{{lodging?.name}}</div>
                                            </div>
                                            <div class="credits-row">
                                                <div class="name">
                                                    <mat-icon>location_on</mat-icon>{{'COMMON.ADDRESS' | translate}}
                                                </div>
                                                <div class="value">{{lodging?.address}}</div>
                                            </div>
                                            <div class="credits-row long" *ngIf="lodging?.description">
                                                <div class="name">
                                                    <mat-icon>info</mat-icon>{{'COMMON.DESCRIPTION' | translate}}
                                                </div>
                                                <div class="value" [innerHTML]="lodging?.description"></div>
                                            </div>
                                            <ng-container *ngIf="lodging?.amenityFeatures?.length">
                                                <div class="section-title">{{'COMMON.FEATURES' | translate}}</div>
                                                <div class="feats-container" fxLayout="row wrap" fxLayoutGap="2rem grid">
                                                    <ng-container *ngFor="let feat of lodging?.amenityFeatures">
                                                        <div *ngIf="feat.name" class="feat" fxFlex="50%">
                                                            <mat-icon>{{feat.image}}</mat-icon>{{feat.name}}
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="showOffer">
                                    <ng-container *ngIf="!offer && place.containedInOffers?.length">
                                        <ng-container *ngFor="let of of place.containedInOffers">
                                            <ng-container *ngTemplateOutlet="offerTemplate; context:{offer: of}"></ng-container>
                                        </ng-container>
                                        <mat-divider class="m-32"></mat-divider>
                                    </ng-container>

                                    <ng-container *ngIf="offer">
                                        <ng-container *ngTemplateOutlet="offerTemplate; context:{offer: offer}"></ng-container>
                                    </ng-container>

                                    <ng-template #offerTemplate let-offer="offer">
                                        <div class="credits-row" >
                                            <div class="name">
                                                <mat-icon>local_offer</mat-icon>{{'COMMON.OFFER' | translate}}
                                            </div>
                                            <div class="value">{{offer.name}} ({{offer.totalPrice}}{{offer.priceCurrency | translate}} / {{offer.priceUnit | translate}})</div>
                                        </div>

                                        <div class="credits-row">
                                            <div class="name">
                                                <mat-icon>attach_money</mat-icon>{{'COMMON.NET_PRICE' | translate}}
                                            </div>
                                            <div class="value">{{offer.netPrice}}{{offer.priceCurrency | translate}}</div>
                                        </div>

                                        <div class="credits-row">
                                            <div class="name">
                                                <mat-icon>account_balance</mat-icon>{{'COMMON.VAT_PRICE' | translate}}
                                            </div>
                                            <div class="value">{{offer.vatPrice}}{{offer.priceCurrency | translate}}</div>
                                        </div>

                                        <div class="credits-row">
                                            <div class="name">
                                                <mat-icon>attach_money</mat-icon>{{'COMMON.TOTAL_PRICE' | translate}}
                                            </div>
                                            <div class="value">{{offer.totalPrice}}{{offer.priceCurrency | translate}}</div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </div>
                        </ng-container>

                    </div>
                    <div *ngIf="place.assignedTo">
                        <div class="container-name chip secondary">{{'BOOKING.ASSIGNED_SPACE' | translate}}</div>
                    </div>



                    <div class="bottom w-100-p">
                            <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between baseline" fxLayoutGap="6px">

                                    <div *ngIf="place.containedInPlace">{{place.containedInPlace?.name}}</div>


                                    <div fxFlex="0 0 auto" *ngIf="place.amenityFeatures?.length && layout != 'horizontal'" class="feats-icon-container " fxLayout="row" fxLayoutGap="5px">
                                        <ng-container *ngFor="let feat of place.amenityFeatures; let i=index">
                                        <div *ngIf="feat.name && i < 6" class="feat mb-0">
                                            <mat-icon matTooltip="{{feat.name}}">{{feat.image}}</mat-icon>
                                        </div>
                                        <div *ngIf="feat.name && i == 6" class="feat mb-0">
                                                <mat-icon matTooltip="">more_horiz</mat-icon>
                                                </div>
                                        </ng-container>
                                    </div>

                                    <div *ngIf="place.maximumAttendeeCapacity > 1" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center">
                                        <mat-icon class="secondary-text">event_seat</mat-icon>
                                        <span>{{place.maximumAttendeeCapacity}}</span>
                                     </div>
                                </div>
                    </div>

                </div>

            </div>
</div>

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import { AlertDialogComponent } from '../components/alerts/alert-dialog/alert-dialog.component';
import { AlertSnackbarComponent } from '../components/alerts/alert-snackbar/alert-snackbar.component';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class MyspotAlertService {

    isDialogOpen = false;

    constructor(
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public translate: TranslateService,
        ) {}

    openAlertMessage(
      message: string,
      code?: number,
      details?: string,
      title?: string,
      tips?: string,
      image?: string,
      showDetails = true,
      showReturnTo = true): void {

        if (!this.isDialogOpen) {
          this.isDialogOpen = true;
          message = (message) ? message.replace('\n', '<br>') : undefined;
          message = this.translate.instant(message);

          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: 'auto',
            data: { code, message, details, title, tips, image, showDetails, showReturnTo }
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.isDialogOpen = false;
          });
        }


      }

      openSnackBar(message: string): void {
        message = (message) ? message.replace('\n', '<br>') : undefined;
        message = this.translate.instant(message);
        const snackBarRef = this.snackBar.openFromComponent(AlertSnackbarComponent, {
          duration: 10000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          data: {
            message
          },
          announcementMessage: 'prova'
        });
        snackBarRef.instance.message = message;
      }


      private openShow(message, button, classText, duration): MatSnackBarRef<any> {
        message = (message) ? message.replace('\n', '<br>') : undefined;
        message = this.translate.instant(message);
        return this.snackBar.open(message, button, {
          panelClass: [classText],
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration,
        });
      }

      showInfo(message, button, duration?): MatSnackBarRef<any> {
        return this.openShow(message, button, 'w-snackbar-info', duration ?? 25000 );
      }

      showError(message, button, duration?): MatSnackBarRef<any> {
        return this.openShow(message, button, 'w-snackbar-error', duration ?? 80000);
      }

      showWorming(message, button, duration?): MatSnackBarRef<any> {
        return this.openShow(message, button, 'w-snackbar-worming', duration ?? 50000);
      }
}

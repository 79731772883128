export const locale = {
    lang: 'de',
    data: {
        'INTERFACE': {
            SAVE: 'Speichern',
            ADD: 'Hinzufügen',
            NEW: 'Neu',
            DETAIL: 'Detail',
            CONFIRM: 'Bestätigen',
            CONFIRMATION: 'Ich bestätige',
            CANCEL: 'Abbrechen',
            CLEAR: 'Klären',
            GO_BACK: 'Zurück',
            DELETE: 'Löschen',
            DELETE_ALL: 'Alle Löschen',
            REJECT: 'Ablehnen',
            REJECT_ALL: 'Alle Ablehnen',
            REJECT_SELECTED: 'Ablehnen Ausgewählt',
            EDIT: 'Bearbeiten',
            UPDATE: 'Aktualisieren'
        },
        'CAN_LEAVE': {
            TITLE: 'Achtung!',
            BODY: 'Verlassen ohne speichern?',
            NO: 'Nein danke',
            OK: 'Weiter',
        },
        'OFFERS': {
            OFFER: 'Angebot'
        },
        'TUTS': {
            ORGANIZATION: {
                BASIC: 'Tragen Sie hier die Informationen über Ihr Unternehmen ein',
                CONTACT: 'Tragen Sie hier die Person ein, die wir kontaktieren können',
                LODGIN: 'Hier können Sie Ihre Standorte eintragen',
                SAVE: 'Vergessen Sie nicht, Ihre Daten zu speichern!',
                HELP: 'Wenn Sie sich die Hilfe erneut ansehen möchten, klicken Sie hier',
            },
            LODGING: {
                BASIC: 'Ihre Standortinformationen',
                SEARCH: 'Nutzen Sie Google, um Ihren Standort zu finden. Wenn er nicht gefunden werden kann, können Sie die Adresse immer von Hand eingeben',
                PHOTO: 'Zeigen Sie mit tollen Fotos, wie schön Ihr Arbeitsplatz ist',
                FEAT: 'Welche Eigenschaften hat Ihr Standort?',
                HOURS: 'Öffnungszeiten',
                SPECIAL: 'Sie verbringen Weihnachten bei Ihrer Familie? Hier können Sie abweichende Öffnungszeiten hinterlegen',
                SAVE: 'Vergessen Sie nicht, Ihre Daten zu speichern!',
                MEETS: 'Wenn Sie Ihren Standort erstellt haben, können Sie hier Ihre Räume anlegen',
                OFFERS: 'Und hier ist die Preisliste',
            },
            MEET: {
                BASIC: 'Die Informationen über Ihren Standort',
                QUICK: 'Aktivieren Sie diese Option, wenn Sie möchten, dass Ihre Kunden blitzschnell buchen können',
                PHOTO: 'Erstellen Sie ein Fotoalbum Ihres Standortes. Ihre Kunden werden es lieben',
                OFFERS: 'Hier ordnen Sie den Räumen Preislisten zu',
                FEAT: 'Was sind die Eigenschaften Ihres Raumes?',
                HOURS: 'Zeitpläne für die Buchung dieses Raumes',
                SPECIAL: 'Gibt es besondere Tage oder Zeiträume, an denen abweichende Zeiten angegeben werden sollen? Sie können diese hier hinterlegen',
                SAVE: 'Vergessen Sie nicht, Ihre Daten zu speichern!',
            },
            OFFER: {
                BASIC: 'Die Informationen auf Ihrer Preisliste',
                UNIT: 'Die Zeiteinheit, für die Sie den Raum für diesen Preis reservieren möchten',
                LIMITED: 'Wenn es ein limitiertes Angebot ist, hinterlegen Sie den Zeitraum',
                ACTIVITIES: 'Für welche Aktivitäten ist das Angebot gültig?',
                ITEMS: 'Die mit diesem Angebot verbundenen Räume',
                SAVE: 'Vergessen Sie nicht, Ihre Daten zu speichern!',
            }
        },
        'meeting-room': 'Meetingraum',
        'desk': 'Tisch',
        'parking-slot': 'Parklücke',
        'presence': 'Presence',
        'PARKING_SLOT': 'Parklücke',
        'PARKING_FACILITY': 'Parkmöglichkeit',
        'MEETINGROOM': 'Meetingraum',
        'DESK': 'Tisch',
        'SEAT': 'Anwesenheit',
        'SPOT': 'Stelle',
        'EVENT': 'Event',
        'FULL_DAY': 'Tag',
        'HALF_DAY': '½ Tag',
        'PER_HOUR': 'Stunde',
        'EUR': '€',
        'MONTH': 'Monat',
        'LODGINGBUSINESS': 'Spot',
        'ROLE_HOST': 'Host',
        'ROLE_ADMIN': 'Admin',
        'ROLE_USER': 'Freiberufler',
        'ROLE_USER_CORPORATE': 'Unternehmen',
        'ROLE_BUILDING_MANAGER': 'Gebäudemanager',
        'ROLE_DEMO': 'Demo',
        'ROLE_RECEPTIONIST': 'Reception',
        'ROLE_FACILITY_CUSTOMER': ' Fac. Kunde',
        'ROLE_FACILITY_VENDOR': ' Fac. Anbieter',
        'ROLE_CASH_MANAGER': 'Cash manager',
        'ROLE_PROJECT_MANAGER': 'Project manager',
        'ROLE_FACILITY_MANAGER': 'Facility manager',
        'ROLE_HR_MANAGER': 'HR Manager',
        'ROLE_SUPERVISOR': 'Supervisor',
        'ALERT_STRIPE': 'Achtung! Um Zahlungen zu erhalten, müssen Sie die Stripe Registrierung abschließen',
        'RESOURCE': 'Ressource',
        'RESOURCE_TYPE': 'Ressource typ',

        RESOURCES: {
            'meeting-room': 'Meeting Room{count, plural, =0{} one{} other{s}}',
            desk: 'Desk{count, plural, =0{} one{} other{s}}',
            'parking-slot': 'Parking slot{count, plural, =0{} one{} other{s}}',
            presence: 'Presence{count, plural, =0{} one{} other{s}}',
            PARKING_SLOT: 'Parking slot{count, plural, =0{} one{} other{s}}',
            PARKING_FACILITY: 'Parking facilit{count, plural, =0{ies} one{y} other{ies}}',
            MEETINGROOM: 'Meeting room{count, plural, =0{} one{} other{s}}',
            DESK: 'Desk{count, plural, =0{} one{} other{s}}',
            SEAT: 'Presence{count, plural, =0{} one{} other{s}}',
            SPOT: 'Spot{count, plural, =0{} one{} other{s}}',
            EVENT: 'Event{count, plural, =0{} one{} other{s}}',
        },

        'COMMON': {
            ACCOUNTHOLDER: 'Sccountholder',
            ADD: 'Hinzufügen',
            ADDRESS: 'Adresse',
            ANYTHING_ELSE: 'Sonstiges',
            ATTENDEE: 'Teilnehmer{count, plural, =0{} one{} other{s}}',
            CLOSES: 'Schließt',
            CODE: 'Code',
            COMPANY_PROTOCOL: 'Firmenprotokoll',
            DATE: 'Datum',
            DELETED_MESSAGE: ' item{count, plural, =0{} one{} other{s}} deleted',
            DESCRIPTION: 'Beschreibung',
            DETAIL: 'Details{count, plural, =0{} one{} other{s}}',
            EMPLOYEE: 'Mitarbeiter',
            END: 'Ende',
            ERROR: 'We\'re sorry, but an error has occurred.',
            FEATURES: 'Eigenschaften',
            FILTER: 'Filter{count, plural, =0{} one{} other{s}}',
            FLOOR: 'Boden',
            HOW: 'Wie',
            LIKED: 'Es gefällt Ihnen!',
            LOGOUT: 'Logout',
            MEETINGROOM_DETAILS_INFO: 'Meeting Room Details',
            NAME: 'Name',
            NET_PRICE: 'Nettopreis',
            NOTES: 'Notizen',
            NOT_LIKED: 'Gefällt mir',
            NO_RESULTS: 'No results',
            OFFER: 'Angebot',
            OFFERS: 'Angebote',
            OPENINGS: 'Öffnungszeiten',
            OPENS: 'Öffnet',
            PARKING_SLOT_DETAILS_INFO: 'Parking Slot Details',
            PAYMENT_REDIRECT_MESSAGE: 'Zahlung nach der Weiterleitung abschließen',
            PHOTO: 'Foto',
            PURPOSE: 'Purpose',
            PRICE: 'Preis',
            PRIVACY_POLICY: 'Datenschutz-Bestimmungen',
            SEARCH: 'Suche',
            SEATS: 'Sitze',
            SELF_CERTIFICATION: 'Selbstzertifizierung',
            SPECIAL_OPENINGS: 'Abweichende Öffnungszeiten',
            START: 'Starten',
            TICKET: 'Ticket',
            TIME: 'Zeit',
            TIMEZONE: 'Zeitzone',
            TITLE: 'Titel',
            TOTAL_PRICE: 'Gesamtpreis',
            T_AND_C: 'Geschäftsbedingungen',
            VALID_FROM: 'Gültig von',
            VALID_UNTIL: 'Gültig für',
            VAT_PRICE: 'Bruttopreis',
            WEEK_CLOSE: 'Wöchentliche Schließung',
            WHAT: 'Was',
            WHEN: 'Wann',
            WHERE: 'Wo',
            Zone: 'Zone',
        },

        ALERT: {
            TITLE: 'Attention!',
            DELETE_ALL: 'All items depending on the selected ones<br> will be deleted'
        },

        'PHOTO': {
            TITLE: 'Titel',
            MANDATORY: 'Titel ist obligatorisch',
            ADD: 'Hinzufügen von Fotos',
            INFO: 'Fotos werden nach dem Speichervorgang hochgeladen',
            TO_UPLOAD: 'Hochladen',
            QUEUE: 'QuWarteschlange',
            PROGRESS: 'Progree hochladen',
            ABORT_ALL: 'Abbrechen alle',
            REMOVE_ALL: 'Entfernen Sie alle',
            STATUS: 'Status',
            DELETE: 'Löschen',
            IN_PROGRESS: 'in Arbeit',
            COMPLETED: 'Upload mit Statuscode abgeschlossen ',
            UPLOAD_TITLE: 'Laden Sie ein Foto von diesem Ort hoch'
        },

        'MANAGER' : {
            PHOTOS : {
                M1: 'MYSPOT funktioniert am besten mit horizontalen Bildern im Verhältnis 3:2',
                M2: 'Das erste Bild wird als Profilbild genutzt, die anderen werden in der Galerie gezeigt',
                M3: 'Um ein Bild zu löschen, wählen Sie es aus und klicken auf den Löschen Button. Klicken Sie auf Speichern, um es zu bestätigen'
            },
            ASSIGN_EMPLOYEE: {
                TITLE: 'Zuteilung',
                HEADING: 'Mitarbeiter, der diesem Ort zugeordnet ist',
                HINT: 'Wenn keine, suchen Sie bitte per E-Mail und wählen Sie Mitarbeiter aus.',
                SEARCH_BY_EMAIL: 'Suche per E-Mail',
                RESET: 'Auf keine zurücksetzen',
            },
            VIEW: 'View',
            SELECT: 'Select',
            LAST_SELECTED_PLACE: 'Last selected place',
            CLICK_MODE: 'Click mode',
            SELECT_FLOOR: 'Select floor',
            SELECT_PLACE_TYPE: 'Select place type',
            FILTER_BY: 'Filter by',
            BASIC_INFO: 'Basic info',
            NAME: 'Name',
            OFFERED_BY_LODGING: 'Offered by Lodging Business',
            TIME_SLOT: 'Time slot',
            PRICE: 'Price',
            CURRENCY: 'Currency',
            VAT: 'Vat',
            NO_VAT: 'Not VAT',
            LIMITED_TIME_OFFER: 'Limited Time Offer',
            VALID_FROM: 'Valid from',
            VALID_THROUGH: 'Valid through',
            DESCRIPTION: 'Description',
            ITEM_LIST: 'Item list',
            ITEM_LIST_TIP: 'Click ADD to activate this area',
            CHECK_ITEM_OFFER: 'Check the items available for this offer',
            SAVE_BEFORE_ADD: 'You must to save your offer before adding items to it',
            ITEM_OFFERED: 'Item{count, plural, =0{} one{} other{s}} offered',
            ITEM_OFFERED_TITLE: 'Item{count, plural, =0{} one{} other{s}} offered (click to remove)',
            AVAILABLE_CLICK: 'available (click to toggle)',
            ITEM_MAP: 'Item map',
            ITEM_MAP_TIP: 'Click ADD to activate this area',
        },

        'ORG' : {
            NEW:            'Neue Organisation',
            DETAIL:         'Informationen über die Organisation',
            CONTACT_MYSPOT: 'Kontaktieren Sie das MYSPOT Team, um Ihre Organisation hinzuzufügen',
            BASIC_INFO:     'Basisinformationen',
            COMPANY_NAME:   'Unternehmensbezeichnung',
            TAX:            'Steuernummer',
            VAT:            'Umsatzsteuer-ID',
            TEL:            'Telefon',
            DESCRIPTION:    'Beschreibung',
            CONTACT:        'Ansprechpartner',
            NAME:           'Vollständiger Name',
            SPOTS:          'Standorte',
            ADD:            'Neuen Standort hinzufügen',
            SPOT_NAME:      'Standortname',
            TYPE:           'Typ',
            BUILDING:       'Gebäudemanager ',
            SELECTED:       'ausgewählt',
            TOTAL:          'Total',
            PREMIUM_FEATURES: 'Premium Eigenschaften',
            SENDER_EMAIL: 'Benutzerdefinierte Adressen für das Senden von E-Mails',
            SENDER_ALIAS: 'Alias für den E-Mail Versand',
            PLACE_DETAIL: 'Ortsdetails',
        },

        'VEND': {
            NEW:            'Neue Organisation',
            DETAIL:         'Informationen über die Organisation',
            CONTACT_MYSPOT: 'Kontaktieren Sie das MYSPOT Team, um Ihre Organisation hinzuzufügen',
            BASIC_INFO:     'Basisinformationen',
            COMPANY_NAME:   'Unternehmensbezeichnung',
            TAX:            'Steuernummer',
            VAT:            'Umsatzsteuer-ID',
            TEL:            'Telefon',
            DESCRIPTION:    'Beschreibung',
            CONTACT:        'Ansprechpartner',
            NAME:           'Vollständiger Name',
            ADD:            'Neuen Standort hinzufügen',
            TYPE:           'Typ',
            TOTAL:          'Total',
            CURRENCY:       'Währung',
            NEMPLOYEES: 'Anzahl Mitarbeiter',
            GEOPRESENCE: 'Geografische Präsenz',
            PARENTCOMPANY: 'Haben Sie einen Mutterkonzern?',
            SUBCOMPANY: 'Haben Sie eine Tochergesellschaft?',
            TERRITORY: 'Geografisches Gebiet und abgedeckte Staaten',
            CONTACTS: 'Kontakt',
            ADDRESS: 'Adresse',
            CITY: 'Stadt',
            ZIP: 'Postleitzahl',
            COUNTRY: 'Land',
            SITE: 'Webseite',
            CONTACTPEOPLE: 'Ansprechpartner',
            HISTORY: 'Historie',
            YEARS: 'Jahre des Bestehens',
            CLIENTS: 'Hauptkunden',
            SUMMARY: 'Kurze Unternehmensbeschreibung',
            CERTIFICATIONS: 'Zertifizierungen',
            SERVICES: 'Services: Hinterlegen Sie eine Liste',
            OTHERINFO: 'Weitere Informationen',

        },


        'BOOKING': {
            DESKBUSY: 'The resource has already been busy.',
            COLLEGUE: 'Colleague',
            STATUS: {
                REQUESTED: 'Angefragt',
                CONFIRMED: 'Warten auf Check-In',
                IN_PROGRESS: 'Bestätigt',
                CLOSED: 'Geschlossen',
                CANCELLED: 'Abgebrochen',
                AWAITING_PAYMENT: 'Zahlung erwartet',
                AWAITING_PAYMENT_RESPONSE: 'Zahlungsbestätigung erwartet',
                EXPIRED: 'Abgelaufen',
                PAID: 'Bezahlt',
                REJECTED: 'Abgelehnt',
            },
            CLEAR_FILTERS: 'Filter zurücksetzen',
            RESERVATION: 'Reservierung Details{count, plural, =0{} one{} other{s}}',
            COLLEAGUE: 'Do you want to book for a colleague?',
            COLLEAGUE_INFO: 'Colleague',
            DESK: 'Tisch',
            MEETINGROOM: 'Raum',
            PARKING_SLOT: 'Parklücke',
            LODGINGBUSINESS: 'Gebäude',
            EVENT: 'Event',
            BROADCASTEVENT: 'Ereignis übertragen',
            SEARCH_TEXT: {
                BASE: 'Sie suchen',
                POURPOSE: 'nach',
                ATTENDEE: 'nach <strong>{attendee}</strong> Teilnehmer',
                DAY: 'am',
                START: 'ab',
                END: 'bis',
                PLACE: 'in',
                FLOOR: 'den Stock ',
                ALL_FLOORS: 'auf allen Etagen',
                ZONE: 'den Zone ',
                ALL_ZONE: 'auf allen Zone',
            },
            ENTER_PASS: 'Für einfaches buchen, geben Sie Ihren Passcode ein',
            LOOK_AGENDA: 'Überprüfen Sie Ihre Agenda',
            HIDE_AGENDA: 'Agenda verbergen',
            FEATURES: 'Services',
            INVALID_DATE: 'Ungültige Datenauswahl',
            SELECT_PERIOD: 'Wählen Sie diesen Zeitraum',
            AWAITING_SUGGESTIONS: 'Warten auf Vorschläge',
            SUGGESTED_TIMESLOT: 'Vorgeschlagene Zeitslots',
            NO_SUGGESTION: 'Keine Vorschläge vorhanden',
            PROPOSALS: 'Vorschläge',
            FILTER_PROPOSALS: 'Vorschläge anhand dieser Filter',
            ASSIGNED_SPACE: 'Zugewiesener Speicherplatz',
            RESOURCES: 'Ressourcen',
            FILTER_RESOURCES: 'Ressourcen anhand dieser Filter',
            TIME_GRANULARITY: 'Zeitformat ändern',
            NO_TIME_AVAIABLE: 'Wöchentliche Schließung',
            USE_FILTER: 'Setzen Sie Filter, um die Suche zu starten',
            CHANGE_FILTER: 'Ändern Sie die Filter, um mehr Angebote zu erhalten',
            FILTER_BY_NAME: 'Nach Namen filtern',
            SEARCH_BY_EMAIL: 'Schreiben Sie die E-Mail',
            INVITE_ATTENDEES: 'Invite attendees...',
            INSERT_VALID_EMAIL: 'Please enter a valid email address',
            SELECT_START: 'Select start time',
            SELECT_END: 'Select end time',
            ALL_FLOORS: 'Alle Etagen',
            ALL_ZONES: 'Alle Zones',
            QR_CODE: 'Please check-in via mobile',
            CONFIRM_DIALOG: {
                TITLE: 'Reservierung bestätigt!',
                BODY: 'Möchten Sie etwas anderes buchen?',
                CALENDAR: 'Nein danke',
                RESERVE: 'Ja!',
                REFRESH_TOKEN: {
                    TITLE: 'Sitzung abgelaufen',
                    BODY: 'Du warst zu lange inaktiv,<br> bitte aktualisiere deine Sitzung,<br> um fortzufahren',
                    BODY_DONE: 'Ihre Sitzung ist jetzt aktualisiert.<br>Bitte bestätigen Sie weiterhin Ihre Reservierung',
                    REFRESH: 'Aktualisierung',
                    RETRY: 'Wiederholen',
                    DONE: 'Weitermachen',
                    WAIT: 'Erfrischend...',
                }
            },
            RESERVATIONS: {
                REJECT_DIALOG_TEXT: 'Bitte geben Sie einen Grund für die Ablehnung ein.',
                REJECT_REASON: 'Ablehnungsgrund',
                REJECT_SUCCESS: 'Reservierung Erfolgreich Abgelehnt',
                REJECT_ERROR: 'Fehler bei der Ablehnung der Reservierung'
            },
            SEARCH_FROM_DATE: 'Datum von',
            SEARCH_TO_DATE : 'Datum bis',
            SEAT: 'Sitz',
            NO_LODGING: 'Kein Hauptsitz gefunden',
            FIND_COLLEAGUE: 'Suche einen Kollegen',
            TIMEZONE_BOOKING: 'Sie buchen jetzt mit der Zeitzone von',
            MAP_LEGEND: {
                OK : 'Erhältlich',
                NOT_OK : 'Erhältlich los',
                PARTIAL : 'Verfügbar (nur einige Tage)',
                ASSIGNED : 'Zugewiesen',
                OWNED: 'Besitz',
                COLLEAGUE : 'Kollege',
                COLLEAGUE_SELECTED : 'Kollege (ausgewählt)',
                SELECTED : 'Ausgewählt',
                DEFAULT : 'Nicht verfügbar',
            },
            ERROR: {
                COVID_LIMIT: {
                    TITLE: 'Limit erreicht',
                    MESSAGE: 'Aufgrund von Covid19 haben wir eingeschränkten Zugang zu diesem Bereich',
                    TIPS: 'Versuchen Sie, die Uhrzeit, den Tag oder den Boden zu ändern',
                    DETAILS: '',
                },
                TIME_LIMIT: 'Booking past ',
                TIME_LIMIT1: 'days is not permitted',
                TIME_LIMIT_TITLE: 'Limit reached',
                NOT_PROPOSAL: 'Sorry, but an error has occurred. Try searching again.'
            },
            NOT_FIND_COLLEAGUE: 'Kein Kollege anwesend',
            FULL_DAY: 'Ganzer Tag',
            NO_Place: 'Es gibt keine Ergebnisse für Ihre Suche',
            NO_RESERVATION: 'Der Kollege hat derzeit keine Vorbehalte',
            NO_RESERVATION_FLOOR: `Der Kollege hat eine Reservierung für eine andere Etage`,
             N0_ORDER: 'A reservation already exists during the selected date and time frame. Please update your selection to book.'
        },


        'WEEK': {
            SUNDAY : 'Sonntag',
            MONDAY : 'Montag',
            TUESDAY : 'Dienstag',
            WEDNESDAY : 'Mittwoch',
            THURSDAY : 'Donnerstag',
            FRIDAY : 'Freitag',
            SATURDAY : 'Samstag',
        },

        'EVENTS': {
            STATUS: {
                REQUESTED: 'Angefragt',
                CONFIRMED: 'Bestätigt',
                IN_PROGRESS: 'Bestätigt',
                CLOSED: 'Geschlossen',
                CANCELLED: 'Storniert',
                AWAITING_PAYMENT: 'Zahlung erwartet',
                AWAITING_PAYMENT_RESPONSE: 'Zahlungsbestätigung erwartet',
                EXPIRED: 'Abgelaufen',
                PAID: 'Bezahlt',
            },
            DATE_HOURS : 'Datum & Zeiten',
            LOCATION : 'Location',
            NAME : 'Name',
            RESERVE_TICKET: 'Ticket reservieren',
            JOIN_TEAM: 'Dem Event folgen',
            SUBSCRIBE: 'Eintragen',
            UNSUBSCRIBE: 'Austragen',
            COUNTDOWN: 'Event Start: {countdown}',
            CLOSED: 'Geschlossen',
            SEARCH_LOCATION: 'Die Location suchen',
            SEARCH_EVENT_NAME: 'Über den Eventnamen suchen',
            SEARCH_WHERE: 'Wo?',
            EVENT_NAME: 'Eventname',
            SEARCH_FROM_DATE: 'Datum von',
            SEARCH_TO_DATE : 'Datum bis',
            HIDE_FILTERS : 'Filter verbergen',
            SHOW_MORE_FILTERS : 'Filter anzeigen',
            FOUNDED : 'Gefundene Events:',
            EVENTS : 'Events',
            EVENTS_BY_FILTERS : 'Events mit diesen Filtern',
            NO_RESULTS : 'Ändern Sie die Filter, um mehr Events angezeigt zu bekommen',
            PAST_EVENT: 'Vergangene Events',
            ONLY_PAST: 'Es sind keine Events in diesem Bereich geplant, aber Sie können sich vergangene Events ansehen.',
            YOU_ARE_HERE : 'Sie sind hier',
            CHOOSE_TICKET: 'Wählen Sie Ihr Ticket',
            HOW_MANY_TICKETS: 'Wie viele Tickets?',
            TICKETS: 'Tickets',
            NO_OFFERS: 'Tut uns leid,<br>für dieses Event sind derzeit keine Tickets verfügbar.<br>Bitte versuchen Sie es zu einem späteren Zeitpunkt wieder.',
            NO_TICKETS: 'Tut uns leid,<br>die Sitzplätze für dieses Ticket sind ausverkauft',
            NO_OFFERS_VIRTUAL: 'Tut uns leid,<br>die Registrierung für dieses Event ist noch nicht geöffnet<br>Bitte versuchen Sie es zu einem späteren Zeitpunkt wieder.',
            TICKET_RESERVED: 'Ihre Tickets wurden reserviert',
            TICKET_RESERVED_VIRTUAL: 'Registrierung bestätigt!',
            TICKET_RESERVED_TEMP: 'Ihre Tickets werden vorübergehend reserviert',
            TICKET_RESERVED_VIRTUAL_TEMP: 'Registrierung wird noch bestätigt',
            CHECK_CALENDAR: `Wenn Sie sich mit Ihrem Office 365 Account angmeldet haben,<br>wurde der Termin mit Ihrem Kalender synchronisiert.`,
            ICS_TEXT: `Alternativ können Sie den ICS-File downloaden und Ihrem Kalender hinzufügen`,
            DOWNLOAD_ICS: 'Download ICS-File',
            ERROR_RESERVATION: 'Es tut uns leid! Ein Fehler ist aufgetreten. Bitte versuchen Sie es später wieder',
            GO_CALENDAR: 'Zeig meinen Kalender',
            RESERVE: 'Reservieren',
            LOGIN_REQUIRED: 'Sie müssen eingeloggt sein, um ein Ticket zu kaufen',
            OFFER_AVAILABILITY: 'Verfügbarkeit der Angebote',
            OFFER_AVAILABILITY_FROM: 'von: ',
            OFFER_AVAILABILITY_TO: 'bis: ',
            PARTECIPANTS: 'Teilnehmer',
        },

        'RECEPTION': {
            NOT_REGISTERED: 'Der Gast noch nicht registriert',
            GUESTS: 'Gäste',
            ON_BEHALF_OF: 'im Namen von',
            N_GUESTS: 'Anzahl der Gäste',
            VALID_FROM: 'Starten',
            VALID_THROUGH: 'Ende',
            BUILDING: 'Gebäude',
            REQUESTOR: 'Anforderer',
            ADD_ACCESS_REQUEST: 'Erstellen einer Zugriffsanforderung',
            SEARCH: 'Suche Zugriffsanforderung',
            INSERT_REFERENT: 'Geben Sie den Namen oder die E-Mail ein',
            ADD_GUESTS_TIP: '<strong>Gäste</strong> über das entsprechende Panel hinzufügen',
            GRANT_ACCESS: 'Gewähren des <strong>Zugangs</strong>',
            INSERT_ACCESS_INFO: 'Einfügen von Informationen zur <strong>Zugriffsanforderung</strong>',
            ACCESS_READY_TITLE: '<strong>Zugriffsanforderung</strong> ist bereit!',
            REPEAT_PROCESS: 'Wiederholen Sie den Vorgang aus Schritt <strong>2</strong>, um einen weiteren <strong>Gast</strong> hinzuzufügen, oder drücken Sie die Taste, um die <strong>Zugriffsanfrage</strong> an Ihre <strong>Gäste</strong> zu senden.',
            HELLO: 'Hallo, <strong>{name}</strong>!',
            WELCOME_MESSAGE: 'Zu beginnen, alle Informationen zur <strong>Zugriffsanforderung</strong> ausfüllen',
            STEP2: {
                TITLE: 'Mindestens einen <strong>Gast hinzufügen</strong>',
                SEARCH_LABEL: 'Geben Sie die E-Mail des <strong>Gastes</strong> ein',
                SEARCH_HINT: 'Versuchen Sie zu suchen, ob sich der <strong>Gast</strong> in unserem Archiv befindet. Ist dies nicht der Fall, können Sie eine neue'
            },
            STEP3: {
                TITLE: 'Wählen Sie den richtigen <strong>Gast</strong>',
                TITLE2: 'Kein <strong>Gast</strong> gefunden. Registrieren eines neuen found!',
                TITLE_ERROR: 'Colleagues not allowed as guests',
                INSERT_VALID_EMAIL: 'Please enter a valid email address',
            },
            STEP4: {
                TITLE: 'Füllen Sie alle Infos des <strong>Gastes</strong>',
                PRIVACY_BUTTON: 'Datenschutzvereinbarungen drucken'
            },
            STEP5: {
                BUTTON: '<strong>Gast</strong> hinzufügen',
            },
            OK_MESSAGE: 'Ihre <strong>Gäste</strong> erhalten eine E-Mail mit den Zugangsanweisungen',
            OK_TITLE: 'Zugang gewährt',
            STATUS: 'Status',
        },

        INVITATION: {
            ADD_ACCESS_REQUEST: 'Create Invitation',
            SEARCH: 'Search Invitation',
            GRANT_ACCESS: 'Send <strong>Invitation</strong>',
            INSERT_ACCESS_INFO: 'Insert <strong>Invitation</strong> information',
            ACCESS_READY_TITLE: 'The <strong>Invitation</strong> is ready!',
            REPEAT_PROCESS: 'Repeat the process from step <strong>2</strong> to add another <strong>Guest</strong> or press the button to send the <strong>Invitation</strong> to your <strong>Guests</strong>',
            WELCOME_MESSAGE: 'To begin, complete all <strong>Invitation</strong> information',
            OK_TITLE: 'Invite sent',
        },

        ACCESS: {
            REGISTRATION: {
                TITLE : 'Registrierung',
                PERSONAL_DATA : 'Persönliche Informationen',
                REQUIRED: 'Dieses Feld ist obligatorisch',
                NAME : 'Namen',
                SURNAME : 'Nachname',
                BIRTHDAY : 'Geburtsdatum',
                COMPANY : 'Unternehmen',
                DOCUMENT : {
                    TITLE : 'Identitätsdokument',
                    TYPE : 'Typ',
                    ID : 'Persönliche ID',
                    DRIVING_LICENSE : 'Führerschein',
                    PASSPORT : 'Pass',
                    NUMBER : 'Dokumentnummer',
                    ISSUED_BY : 'Ausgestellt von',
                    EXPIRING : 'Ablaufdatum',
                    EXPIRED: 'Abgelaufen',
                    'Identity Card': 'Persönliche ID',
                    'Driving License': 'Führerschein',
                    'Passport': 'Pass'
                },
                PRIVACY : {
                    TITLE : 'Datenschutz',
                    SUBTITLE : 'INFORMATIONEN ÜBER DIE VERARBEITUNG PERSONENBEZOGENER DATEN',
                    TEXT : 'Der Unterzeichnete stimmt der Verarbeitung seiner personenbezogenen Daten zu, die sich seiner Rechte wie oben vorgesehen bewusst ist.'
                },
                POLICY_CONFIRM : 'I accept the privacy conditions',
                CONFIRM : 'Bestätigung der Registrierung',
                COMPLETE : {
                    TITLE_1 : 'Ihre Registrierung ist abgeschlossen.',
                    TITLE_2 : 'Sie erhalten kurz vor dem Termin eine E-Mail mit Anweisungen zum Anfordern des Zugriffs.'
                },
                EMAIL: 'E-mail',
                INSERT_VALID_EMAIL: 'Please enter a valid email address',
            },
            ACCEPTANCE: {
                TITLE: 'Reception',
                ME: 'The undersigned',
                REQUIRED_MESSAGE: 'This field is mandatory',
                NAME : 'Name',
                SURNAME : 'Surname',
                COMPANY : 'Company',
                TODAY : 'Date',
                SIGNAGE : 'Signature',
                DELETE_SIGNAGE : 'Delete signature',
                CONFIRM : 'Confirm Registration',
                COVID : {
                  TITLE: 'COVID-19 Declaration',
                  CHECK: 'I confirm the Covid-19 declaration',
                  MESSAGE : {
                    ROW1: 'Due to the ongoing and rapidly changing situation with the novel-coronavirus (COVID-19).',
                    ROW2: 'To prevent the spread of COVID-19 and reduce the potential risk of exposure to others, I will adopt all the needed precautionary measures.',
                    ROW3: 'And:',
                    UL_1: {
                      LI_1: 'I am NOT presenting with any of the symptoms of COVID-19;',
                      LI_2: 'I have not been identified as a contact of someone who has tested positive for COVID -19 or been asked to self-isolate;',
                      LI_3: 'I have not travelled to high-risk areas, or outside of my home country by car, air, sea, bus or train in the past 14 days.'
                    },
                    ROW4 : 'and, at the end I declare:',
                    UL_2: {
                      LI_1: 'that my movement on the national territory is in compliance with the decrees of the President of the Council of Ministers, regarding the Coronavirus emergency;',
                      LI_2: 'that I observe the travel limitations indicated in the D.P.C.M. in force or that I do not come from the areas identified in "orange" and "red" except for proven work needs;',
                      LI_3: 'that I comply with the provisions relating to returns to Italy from foreign countries established in the D.P.C.M. in force.'
                    }
                  }
                },
                SECURITY : {
                  TITLE: 'Security Information',
                  DESCRIPTION : 'I declare that I have read the information on security.',
                  DOWNLOAD : 'Download the document',
                  CHECK: 'I confirm the security declaration'
                },
                PRIVACY: {
                    CHECK: 'I accept the privacy conditions',
                    TEXT: 'Die betroffene Person bestätigt, dass sie die Datenschutzerklärung angesehen hat und erklärt sich jedes Mal ausdrücklich einverstanden mit der automatisierten Verarbeitung Ihrer personenbezogenen Daten, da sie sich ihrer Rechte gemäß der genannten Erklärung bewusst ist.',
                },
                NOT_REGISTERED : {
                  TITLE : 'Not registered yet?',
                  DESCRIPTION : 'You have to completete the registration in order to complete this step.'
                },
                COMPLETE : {
                    TITLE_1 : 'Ihre Daten wurden erfolgreich gesendet.',
                    TITLE_2 : 'Sie erhalten eine E-Mail, um sich anzumelden.',
                    DESCRIPTION : 'You can leave this page open while waiting for the badge, or come back later to check that it has been enabled.'
                },
                NOT_VALID_TIME_TITLE: 'Operation Nicht zulässig',
                NOT_VALID_TIME_DESCRIPTION: 'Es tut uns leid, die Sitzung wurde verschoben. Sie erhalten kurz vor dem Termin eine neue Annahme-E-Mail.',
            },
            BADGE: {
                GUEST_EXPIRED: 'Ihr Abzeichen ist abgelaufen.',
                GUEST_REJECTED: 'Es tut uns leid, aber Ihr Abzeichen wurde storniert.',
                GUEST_DEFAULT_ERROR: 'Es tut uns leid, aber es ist ein Fehler aufgetreten.',
                GUEST_ACCESS_GRANTED: 'Bitte geben Sie ein.',
                GUEST_SCAN_REJECTED: 'Es tut uns leid, aber der Zugriff ist im Moment nicht möglich',
                GUEST_TIME_NOT_VALID: 'Siamo spiacenti, ma il badge non è valido per la giornata odierna.',
                WELCOME: 'WILLKOMMEN',
                ENJOY_YOUR_STAY: 'Ihren Aufenthalt geniessen.',
                GOODBYE: 'Goodbye',
                GOODBYE_GREET: 'Have a nice day',
                COMPLETE: {
                    AUTHORIZATION_TEXT: 'Der Gast hat die Annahme abgeschlossen und wartet auf Ihre Genehmigung.',
                    AUTHORIZATION_BUTTON: 'Autorisieren des Zugriffs',
                    SUCCESS: 'Die Genehmigung bestätigt. Ihr Gast kann jetzt auf die Unterkunft zugreifen.',
                    ERROR: 'Ein Fehler ist aufgetreten. Versuchen Sie es später erneut.',
                },
            },
            GUEST: {
                TITLE: 'Gastzugang',
                CHECK_IN: 'Check-in',
                CHECK_OUT: 'Check-out',
                QR_CODE_ENTRANCE: 'Entra o esci tramite QR Code',
                REGISTER: 'Registrieren',
                WELCOME_TO: 'Willkommen bei',
                HAS_QR_CODE: 'Haben Sie eine QR-Warteschlange, um sich anzumelden? Checken Sie hier ein:',
                NOT_REGISTERED: 'Wenn Sie nicht eingeladen wurden, können Sie sich hier registrieren:',
                CREATE_NEW_REGISTRATION: 'Wenn keine Registrierung gefunden wurde, erstellen Sie bitte eine neue:',
                QRCODE_TO_SCANNER: 'Please present your code',
                SCAN_QRCODE: 'Please use you device to scan the code above',
                CHOOSE_HOW_TO_CONTINUE: 'Please, choose how to continue your registration',
                CONTINUE_VIA_APP_TEXT: 'Continue your registration via mobile app.',
                CONTINUE_VIA_APP_BUTTON: 'Mobile App',
                CONTINUE_VIA_TABLET_TEXT: 'Continue your registration here.',
                CONTINUE_VIA_TABLET_BUTTON: 'Continue',
                HOST_EMAIL_TEXT: 'Please insert the email address of your host:',
                OWN_EMAIL_TEXT: 'Please insert your email address:',
                TABLET: 'Continue here',
                MOBILE: 'Use my smartphone',
                HOST_MAIL: 'Host E-Mail',
                YOUR_MAIL: 'Your E-Mail',
                YOUR_DATA: 'Your Data',
                GUEST_STATUS: 'Status',
                STATUS: {
                    GUEST_AWAITED: 'Erwartet',
                    GUEST_ACCESS_REQUESTED: 'Zugriff angefordert',
                    GUEST_ACCESS_GRANTED: 'Zugriff gewährt',
                    GUEST_ENTERED: 'Eingegeben',
                    GUEST_EXITED: 'Beendet',
                    GUEST_EXPIRED: 'Abgelaufen',
                    GUEST_NOT_REGISTERED: 'Nicht registriert',
                    GUEST_AWAITED_DESC: `Der Gast hat nicht eingecheckt`,
                    GUEST_ACCESS_REQUESTED_DESC: `Der Host wartet auf Autorisierung`,
                    GUEST_ACCESS_GRANTED_DESC: `Der Gast hat das virtuelle Abzeichen`,
                    GUEST_ENTERED_DESC: `Der Gast ist eingetreten`,
                    GUEST_EXITED_DESC: `Der Gast ist verlassen`,
                    GUEST_EXPIRED_DESC: `Der Termin wurde abgesagt`,
                    GUEST_NOT_REGISTERED_DESC: `Der Gast hat sich noch nicht registriert`,
                },
            },
            SELF: {
                SELECT_LODGING: 'Wählen Sie das Gebäude aus',
            }
        },


        PROFILE: {
            MENU: 'Profile',
            SAVED: 'Profile saved!',
            SAVED_ERROR: 'Ops, something goes wrong. Try again',
            PROFILE_PIC: 'Profile pic',
            UPLOAD_PIC: 'Upload your photo',
            NO_NAME: 'Missing name!',
            TITLE: 'Profile',
            JOB_TITLE: 'Job title',
            SHORT_BIO: 'Short bio',
            DESCRIPTION: 'Bio',
            SKILLS_LABEL: 'Skills',
            INTERESTS_LABEL: 'Interests',
            NEW_SKILL: 'Add skill',
            NEW_INTEREST: 'Add interest',
            LINKEDIN: 'LinkedIn Profil Link',

            PHOTOS : {
                M1: 'MYSPOT funktioniert am besten mit vertikalen Bildern im Verhältnis 3:4',
                M2: 'Das erste Bild wird als Profilbild genutzt',
                M3: 'Um ein Bild zu löschen, wählen Sie es aus und klicken auf den Löschen Button. Klicken Sie auf Speichern, um es zu bestätigen'
            },

            VISIBILITY: {
                LABEL: 'Profile visibility',
                COLLEAGUES : 'Kollegen',
                NOT_VISIBLE : 'Privat',
                COMMUNITIES : 'Gemeinschaft',
                MYSPOT : 'MYSPOT',
                COLLEAGUES_CLAIM: 'Kennen Sie wirklich jeden einzelnen Ihrer Kollegen?',
                NOT_VISIBLE_CLAIM: 'Nutzen Sie MYSPOT privat und nicht sichtbar für andere.',
                COMMUNITIES_CLAIM: 'Mit gemeinsamen Ideen und Projekten können Sie weit über die Unternehmensgrenzen hinaus wirken.',
                MYSPOT_CLAIM: 'In dieser vernetzten Welt finden Sie überall Wachstum und Chancen zum Aufbau von Geschäftsbeziehungen.',
                COLLEAGUES_DESC : 'Teilen Sie Talente und Leidenschaften mit ihnen: Sie könnten bisher ungeahnte Möglichkeiten entdecken.',
                NOT_VISIBLE_DESC : 'Sie können sich der Community jederzeit öffnen und sichtbar werden.',
                COMMUNITIES_DESC : 'Finden Sie heraus, wer Ihre Interessen teilt und bleiben Sie mit ihnen in Kontakt.',
                MYSPOT_DESC : 'Werden Sie Teil des gesamten MYSPOT Netzwerks.',
            },
        },

        TIPS: {
            TITLE: 'Titel',
            TAG: 'Tag',
            TAGS: 'Tags',
            SEARCH: 'Suche',
            SEARCH_TIPS: 'Suche tips',
            SEARCH_TAG: 'Suche nach tag',
            DESCRIPTION: 'Beschreibung',
            DATE_CREATED: 'Date Created',
            LINK: 'Link',
            OPEN: 'Open',
            PREVIOUS: 'vorherigen',
            NEXT: 'nächster',
            NO_RESULTS: 'Derzeit liegen keine Tipps vor.'
         },

        PARKING_SLOTS : {
            ADD_NEW: 'Neue Parkplätze hinzufügen',
            LOCATION: 'Lage',
            CAPACITY: 'Kapazität',
            PARKING_SLOT_SUBTITLE: 'Parkplatz Details',
            NEW_PARKING_SLOT_TITLE: 'Neue Parkplätze',
            PARKING_SLOT_PHOTOS: 'Parkplatz Fotos',
            FEATURES_TITLE: 'Wählen Sie die Funktionen dieses Parkplatzes',
            CHANGE_INFO: 'Ändern Sie diese Werte nur, wenn der Spot und dieser Parkplatz unterschiedliche Öffnungszeiten haben.'
        },

        EMPLOYEES: {
            FIRST_NAME: 'Vorname',
            LAST_NAME: 'Nachname',
            EMAIL: 'E-mail',
            EMPLOYEE_NUMBER: 'Mitarbeiternummer',
            DEFAULT_BUILDING: 'Standardgebäude',
            DEFAULT_FLOOR: 'Standardboden',
            CURRENT: 'Aktuellen',
            AVAILABLE: 'Verfügbar',
            CLUSTERS: 'Clusters',
            CLUSTER_UPDATED: 'Cluster aktualisiert',
            CLUSTER_UPDATED_ERROR: 'Cluster nicht aktualisiert',
            SELECT_FROM_BELOW: 'Bitte wählen Sie aus der folgenden Liste aus.',
            ADD_BUTTON: 'Add',
            DELETE_BUTTON: 'Remove',
            ASSIGNED: 'Assigned clusters',
            TO_ASSIGN: 'Cluster to be assigned',
            CHECK: 'Seleziona',
            NAME: 'Name',
            DESCRIPTION: 'Description',
            FILTER: 'Filter',
            DESK_TITLE: 'Assigned desks',
            DESK_CODE: 'Code Desk',
            DESK_NAME: 'Name Desk',
            DESK_FLOOR: 'Floor',
            BUILDING_CODE: 'Code Building',
            BUILDING_NAME: 'Name Building',
            USER_ACTIVE: 'Activate',
            USER_DEACTIVATE: 'Deactivate',
            DESK_ASSIGNED: 'Assigned Resources',
            ERROR: 'Inborn error',
            MESSAGE_INFO: 'the user is in another state for more info ',
            EMAIL_BUTTON: 'contact us',
            DESK_ERROR_CODE: 'Error removing desks',
            MESSAGE_INFO_ERROR: 'The user is in the state ',
            MESSAGE_INFO_ERROR1: ' manageable via helpdesk. To edit, open a ticket',
            MESSAGE_INFO_ERROR2: 'clicking here'
        },
        CLUSTERS: {
            ADD_NEW: 'Hinzufügen eines neuen Clusters',
            CLUSTER_TITLE: 'Cluster Profile',
            NAME: 'Namen',
            DESCRIPTION: 'Beschreibung',
            BASIC_INFO: 'Basic Info',
            EMPLOYEES: 'Mitarbeiter',
            NEW_CLUSTER_DIALOG: 'Bitte geben Sie neue Cluster-Details ein.',
            DELETE_CLUSTER_DIALOG: 'Bitte bestätigen Sie das Löschen des folgenden Clusters:',
        },
        ZONES: {
            ADD_NEW_FLOOR: 'Neue Etage Hinzufügen',
            OBJECTS: 'Objekte',
            NEW_FLOOR_TITLE: 'Neue Etage',
            FLOOR_SUBTITLE: 'Etage Details',
            CONTAINED_OBJECTS: 'Enthaltene Objekte',
            LOGICAL_REPRESENTATION_OF: 'Logische Darstellung Von',
            ZONE_TYPE: 'Zonentyp',
            CURRENT_OBJECTS: 'Anzahl der Objekte, die derzeit auf dieser Etage enthalten sind:',
            REMOVE_OBJECT: '(Klicken Sie auf ein Objekt, um es von dieser Etage zu entfernen. Drücken Sie dann Speichern.)',
            ADD_OBJECT: '(Klicken Sie auf ein Objekt, um es zu dieser Etage hinzuzufügen. Drücken Sie dann Speichern.)',
            AVAILABLE_OBJECTS_TITLE: 'Verfügbare Objekte',
            AVAILABLE_OBJECTS: 'Anzahl der verfügbaren Objekte:'
        },
        RESERVATIONS: {
            RESERVATION_SUBTITLE: 'Reservierung Details',
            RESERVATION_DATA: 'Reservierung Informationen',
            RESERVATION_ID: 'Reservierung ID',
            OWNER_NAME: 'Namen'
        },

        PRIVACY_TITLE: 'By continuing you agree our',
        PRIVACY: 'Terms of Service & Privacy Policy'
  }
};

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';

import {
  OktaCallbackComponent,
} from '@okta/okta-angular';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'config',
    pathMatch: 'full',
    loadChildren: () => import('./config/config.module').then(m => m.ConfigModule)
  },
  {
    path: 'room-panel',
    pathMatch: 'full',
    loadChildren: () => import('./room-panel/room-panel.module').then(m => m.RoomPanelModule)
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

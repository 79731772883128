import { Router } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
  tips?: string;
  image?: string;
  returnUrl?: string;
  code?: number;
  details?: string;
  showDetails?: boolean;
  showReturnTo?: boolean;
}

@Component({
  selector: 'ms-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {

    version = 0;

    versions = [
        {
            title: 'We are fighting ninjas!',
            image: 'assets/images/set/ninja.png',
            message: 'Sorry, we get an error.<br />Try again later or contact our support team',
        },
        {
            title: 'There is a bug in the Matrix!',
            image: 'assets/images/set/matrix.png',
            message: 'Sorry, we get an error.<br />Try again later or contact our support team',
        },
        {
            title: 'Session expired',
            image: 'https://res.cloudinary.com/dlo69v6hu/image/upload/v1587378685/myspot-icons/account-denied.png',
            message: 'Your session is expired. Please log in again'
        },
        {
            title: 'Resource forbidden',
            image: 'https://res.cloudinary.com/dlo69v6hu/image/upload/v1587378685/myspot-icons/account-denied.png',
            message: 'Try to log in with another account'
        },
        {
            title: 'Covid',
            image: 'https://res.cloudinary.com/dlo69v6hu/image/upload/v1587378685/myspot-icons/doctor.png',
            message: 'Too many people'
        },
        {
          title: 'Something went wrong',
          image: '',
          message: `We couldn't process your request.<br>Please try it again.`,
        },
    ];

    title: string;
    image: string;
    message: string;
    details: string;
    tips: string;

    returnUrl = '/';

    showDetails = false;
    showReturnTo = true;


  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      switch (data.code) {
        case 401:
          this.version = 2;
          break;
        case 403:
          this.version = 3;
          break;
        case 901:
          this.version = 4;
          break;
        default:
          this.version = 5;
          // this.version = Math.floor(Math.random() * 10 % 2);
          this.showDetails = data.showDetails;
          break;
      }

      this.title = data.title ?? this.versions[this.version].title;
      this.image = data.image ?? this.versions[this.version].image;

      this.returnUrl = data.returnUrl ?? this.returnUrl;

      this.message = data.message ?? this.versions[this.version].message;
      this.details = data.details;
      this.tips = data.tips;

      this.showReturnTo = data.showReturnTo ?? true;

    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  returnTo(): void {
    this.router.navigate([this.returnUrl]);
    this.dialogRef.close();
  }


}

import {Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[itemRemover]'
})
export class ItemRemoverDirective {

  @Output() removeClick: EventEmitter<void> = new EventEmitter();

  button: any;
  clickListener: any;


  @HostListener('mouseenter', ['$event']) onEnter($event): void{
    // console.log('clicked', $event);
    console.log('itemRemover mouseenter');
    this.el.nativeElement.style = 'yellow';
    this.renderer.addClass(this.el.nativeElement, 'item-remover');
    this.renderer.appendChild(this.el.nativeElement, this.button);
    this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');

  }

  @HostListener('mouseleave', ['$event']) onLeave($event): void{
    // console.log('clicked', $event);
    console.log('itemRemover mouseleave');
    this.renderer.removeClass(this.el.nativeElement, 'item-remover');
    this.renderer.removeChild(this.el.nativeElement, this.button);
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.init();
  }

  init(): void {
    this.button = this.renderer.createElement('button');
    this.button.setAttribute('class', 'item-remover-button');
    this.clickListener = this.renderer.listen(this.button, 'click', (evt) => {
      this.onClick();
    });
    this.button.innerHTML = `
          <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color">
            clear
          </mat-icon>
       `;
  }

  onClick(): void {
    console.log('itemRemover remove');
    this.removeClick.emit();
  }

}

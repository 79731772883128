export const locale = {
    lang: 'it',
    data: {
        'INTERFACE': {
            SAVE: 'Salva',
            ADD : 'Crea',
            NEW : 'Crea',
            DETAIL: 'Dettaglio',
            CONFIRM: 'Ok',
            CONFIRMATION: 'Confermo',
            CANCEL: 'Annulla',
            CLEAR: 'Cancellare',
            GO_BACK: 'Torna indietro',
            DELETE: 'Cancella',
            DELETE_ALL: 'Cancella Tutti',
            REJECT: 'Rigettare',
            REJECT_ALL: 'Cancella tutti',
            REJECT_SELECTED: 'Cancella selezione',
            EDIT: 'Edit',
            UPDATE: 'Aggiorna'
        },
        ROOM_PANEL: {
          RESERVED: 'Prenotato',
          FREE: 'Disponibile',
          UNTIL: 'Fino alle',
          MEETING_IN_PROGRESS: 'Meeting in corso',
          NEXT_MEETING: 'Prossimo Meeting',
          CLOSED: 'Chiuso',
          TODAY_OPENING_HOURS: 'Orari d\'apertura di oggi',
          DAILY_CLOSURE: 'Chiusura giornaliera',
          LOOKING: 'Cercando nuove prenotazioni...',
          NEW_MEETING: 'Nuovo Meeting',
          TITLE_LABEL: 'Titolo',
          DAY_LABEL: 'Giorno',
          START_LABEL: 'Inizio',
          END_LABEL: 'Fine',
          TRY_ANOTHER_DATE: 'Chiuso, prova con un altro giorno',
          CONFIRM_WITH: 'Conferma con l\'app',
          OPEN: 'Apri l\'app',
          USE_READER_FUNCTIONALITY: 'Usa la funzionalità ',
          READER: 'Reader',
          SCAN_CODE: 'Scansiona il codice per confermare',
          BACK: 'Indietro',
          DOWNLOAD_APP: 'Scarica l\'app',
          PANEL_RESET_TIMER: 'Timer reset del pannello',
          NEXT: 'Avanti'
        },
        'CAN_LEAVE': {
            TITLE: 'Attenzione!',
            BODY: 'Vuoi lasciare la pagina senza salvare?',
            NO: 'No, Resto',
            OK: 'Sì, Lascio la pagina',
        },
        'OFFERS': {
            OFFER: 'Offerta'
        },
        'TUTS': {
            ORGANIZATION: {
                BASIC: 'Inserisci le informazioni della tua organizzazione',
                CONTACT: 'Scrivi i dati della persona che contatteremo',
                LODGIN: 'Qui potrai inserire le tue sedi',
                SAVE: 'Non scordarti di salvare!',
                HELP: 'Se vuoi rivedere la guida, basca cliccare qui!',
            },
            LODGING: {
                BASIC: 'Le informazioni della tua sede',
                SEARCH: 'Utilizza Google per trovare la tua sede. Se non la trovi potrai inserire manualmente il tuo indirizzo',
                PHOTO: 'Fai sapere quanto è bella la tua sede grazie a delle magnifiche foto',
                FEAT: 'Quali caratteristiche ha la tua sede?',
                HOURS: 'Gli orari di apertura e di chiusura',
                SPECIAL: 'A Natale volete stare in famiglia? Qui potete scrivere gli orari di apertura e chiusura speciali',
                SAVE: 'Non scordarti di salvare!',
                MEETS: 'Quando avrai creato la tua sede, qui potrai creare i tuoi spazi',
                OFFERS: 'E qui invece il tuo listino dei prezzi',
            },
            MEET: {
                BASIC: 'Le informazioni del tuo spazio',
                QUICK: 'Attiva l\opzione se vuoi che i tuoi clienti possano prenotare in un attimo',
                PHOTO: 'Crea l\album fotografico del tuo spazio',
                OFFERS: 'Qui assegnerai allo spazio i listini dei prezzi',
                FEAT: 'Quali sono le caratteristiche del tuo spazio?',
                HOURS: 'Gli orari per poter prenotare questa sala',
                SPECIAL: 'Ci sono giorni speciali o periodi in cui necessiti di indicare un orario diverso? Puoi inserirli qui',
                SAVE: 'Non scordarti di salvare!',
            },
            OFFER: {
                BASIC: 'Le informazioni del tuo listino',
                UNIT: 'L\'unità di tempo con la quale vuoi far prenotare i tuoi spazi',
                LIMITED: 'Se è un\offerta limitata, attiva il periodo di tempo',
                ACTIVITIES: 'Per quali attività è valida questa offerta?',
                ITEMS: 'Gli spazi collegati a questa offerta',
                SAVE: 'Non scordarti di salvare!',
            }
        },
        'meeting-room': 'Sala riunioni',
        'desk': 'Postazione',
        'parking-slot': 'Parcheggio',
        'presence': 'Presenza',
        'PARKING_SLOT': 'Parcheggio',
        'PARKING_FACILITY': 'Parcheggio',
        'MEETINGROOM': 'Sala',
        'DESK': 'Postazione',
        'SEAT': 'Presenza',
        'EVENT': 'Evento',
        'FULL_DAY': 'Giorno',
        'HALF_DAY': '½ Giornata',
        'PER_HOUR': 'Ora',
        'MONTH': 'Mese',
        'EUR': '€',
        'LODGINGBUSINESS': 'Spot',
        'ROLE_HOST': 'Host',
        'ROLE_ADMIN': 'Admin',
        'ROLE_USER_CORPORATE': 'Corporate',
        'ROLE_USER': 'Freelancer',
        'ROLE_BUILDING_MANAGER': 'Building manager',
        'ROLE_DEMO': 'Demo',
        'ROLE_RECEPTIONIST': 'Reception',
        'REQUESTED' : 'Da confermare',
        'CONFIRMED' : 'Confermata',
        'IN_PROGRESS' : 'In uso',
        'CLOSED' : 'Conclusa',
        'CANCELLED' : 'Annullata',
        'ROLE_FACILITY_CUSTOMER': ' Fac. Customer',
        'ROLE_FACILITY_VENDOR': ' Fac. Vendor',
        'ROLE_CASH_MANAGER': 'Cash manager',
        'ROLE_PROJECT_MANAGER': 'Project manager',
        'ROLE_FACILITY_MANAGER': 'Facility manager',
        'ROLE_HR_MANAGER': 'HR Manager',
        'ROLE_SUPERVISOR': 'Supervisor',
        'ALERT_STRIPE': 'Attenzione! Per ricevere i pagamenti devi completare la registrazione su Stripe',
        'RESOURCE': 'Risorsa',
        'RESOURCE_TYPE': 'Tipo risorsa',

        RESOURCES: {
            'meeting-room': 'Sal{count, plural, =0{e} one{a} other{e}} riunion{count, plural, =0{i} one{e} other{i}}',
            desk: 'Postazion{count, plural, =0{i} one{e} other{i}}',
            'parking-slot': 'Parcheggi{count, plural, =0{} one{o} other{}}',
            presence: 'Presenz{count, plural, =0{e} one{a} other{e}}',
            PARKING_SLOT: 'Parcheggi{count, plural, =0{} one{o} other{}}',
            PARKING_FACILITY: 'Are{count, plural, =0{e} one{a} other{e}} Parcheggi{count, plural, =0{} one{o} other{}}',
            MEETINGROOM: 'Sal{count, plural, =0{e} one{a} other{e}} riunion{count, plural, =0{i} one{e} other{i}}',
            DESK: 'Postazion{count, plural, =0{i} one{e} other{i}}',
            SEAT: 'Presenz{count, plural, =0{e} one{a} other{e}}',
            SPOT: 'Spot',
            EVENT: 'Event{count, plural, =0{i} one{o} other{i}}',
        },

        'COMMON': {
            ADD: 'Aggiungi',
            ADDRESS: 'Indirizzo',
            ANYTHING_ELSE: 'Altro',
            ATTENDEE: 'Partecipant{count, plural, =0{e} one{e} other{i}}',
            ATTENDEES: 'Partecipanti',
            BUILDING_PROTOCOL : 'Protocollo edificio',
            CLOSES: 'Chiude',
            CODE: 'Codice',
            COMPANY_PROTOCOL: 'Protocollo aziendale',
            COMPANY_PROTOCOL_COVID: 'Documento di sicurezza',
            COMPANY_PROTOCOL_TEXT: 'Protocollo aziendale',
            DATE: 'Data',
            DELETED_MESSAGE: ' element{count, plural, =0{i} one{o} other{i}} cancellat{count, plural, =0{i} one{o} other{i}}',
            DESCRIPTION: 'Descrizione',
            DESK_DETAILS_INFO: 'Dettagli Spazio',
            DETAIL: 'Dettagli{count, plural, =0{o} one{o} other{}}',
            EMPLOYEE: 'Dipendente',
            END: 'Fine',
            ERROR: 'Ci dispiace, ma si è verificato un errore.',
            FEATURES: 'Caratteristiche',
            FILTER: 'Filtr{count, plural, =0{0} one{0} other{i}}',
            FLOOR: 'Piano',
            HOW: 'Come',
            LIKED: 'Ti piace!',
            LOGOUT: 'Esci',
            MEETINGROOM_DETAILS_INFO: 'Dettagli sala riunione',
            NAME: 'Nome',
            NET_PRICE: 'Prezzo',
            NOTES: 'Note',
            NOT_LIKED: 'Ti piace?',
            NO_RESULTS: 'Nessun risultato',
            OFFER: 'Offerta',
            OFFERS: 'Offerte',
            OPENINGS: 'Orari di apertura',
            OPENS: 'Apre',
            PARKING_SLOT_DETAILS_INFO: 'Dettagli Spazio',
            PAYMENT_REDIRECT_MESSAGE: 'Potrai completare il pagamento fra poco',
            PERSON: 'Per conto di',
            PHOTO: 'Foto',
            PURPOSE: 'Scopo',
            PRICE: 'Prezzo',
            PRIVACY_POLICY: 'Privacy policy',
            REFERENT: 'Referente',
            REQUESTOR: 'Richiedente',
            SEARCH: 'Cerca',
            SEATS: 'Capacità',
            SELF_CERTIFICATION: 'Autocertificazione',
            SPECIAL_OPENINGS: 'Orari di apertura straordinari',
            START: 'Inizio',
            SUBJECT: 'Oggetto',
            TICKET: 'Biglietto',
            TIME: 'Ore',
            TIMEZONE: 'Fuso Orario',
            TITLE: 'Titolo',
            TOTAL_PRICE: 'Prezzo ivato',
            T_AND_C: 'Termini e Condizioni',
            VALID_FROM: 'Valido da',
            VALID_UNTIL: 'Valido fino a',
            VAT_PRICE: 'IVA',
            WEEK_CLOSE: 'Chiusura settimanale',
            WHAT: 'Cosa',
            WHEN: 'Quando',
            WHERE: 'Dove',
            ZONE: 'Area',
        },

        ALERT: {
            TITLE: 'Attenzione!',
            DELETE_ALL: 'Tutti gli elementi selezionati<br>verranno elimitati'
        },

        'PHOTO': {
            TITLE: 'Titolo',
            MANDATORY: 'Il titolo è obbligatorio',
            ADD: 'Aggiungi foto',
            INFO: 'Le foto verrano caricate dopo aver approvato le modifiche',
            TO_UPLOAD: 'Da caricare',
            QUEUE: 'Foto in coda',
            PROGRESS: 'Avanzamento upload',
            ABORT_ALL: 'Annulla tutto',
            REMOVE_ALL: 'Rimuovi tutto',
            STATUS: 'Stato',
            DELETE: 'Cancella',
            IN_PROGRESS: 'In corso',
            COMPLETED: 'Upload completato',
            UPLOAD_TITLE: 'Carica una foto di questo posto'
        },

        'MANAGER' : {
            PHOTOS : {
                M1: 'Utilizza immagini orizzontali con proporzione 3:2 per visualizzarle correttamente',
                M2: 'La prima immagine caricata verrà utilizzata come copertina del profilo. Le successive verranno inserite nella galleria',
                M3: 'Per cancellare un’immagine, selezionala e clicca sul tasto rosso Cancella. Clicca su Salva per confermare'
            },
            ASSIGN_EMPLOYEE: {
                TITLE: 'Assegnazione',
                HEADING: 'Dipendente assegnato a questo posto',
                HINT: 'Se nessuno, cerca via e-mail e seleziona dipendente.',
                SEARCH_BY_EMAIL: 'Ricerca via Mail',
                RESET: 'Reimpostare su Nessuno'
            },
            VIEW: 'Visualizza',
            SELECT: 'Seleziona',
            LAST_SELECTED_PLACE: 'Ultima selezione',
            CLICK_MODE: 'Modalità di selezione',
            SELECT_FLOOR: 'Dove?',
            SELECT_PLACE_TYPE: 'Cosa?',
            FILTER_BY: 'Filtra per',
            BASIC_INFO: 'Informazioni',
            NAME: 'Nome',
            OFFERED_BY_LODGING: 'Offerta dallo spot',
            TIME_SLOT: 'Unità di tempo',
            PRICE: 'Prezzo',
            CURRENCY: 'Valuta',
            VAT: 'IVA',
            NO_VAT: 'Nessuna IVA',
            LIMITED_TIME_OFFER: 'Offera per un periodo limitato',
            VALID_FROM: 'Valida da',
            VALID_THROUGH: 'Valida fino a',
            DESCRIPTION: 'Descrizione',
            ITEM_LIST: 'Lista elementi',
            ITEM_LIST_TIP: 'Devi prima salvare per abilitare questa sezione',
            CHECK_ITEM_OFFER: 'Spunta gli elementi per aggiungerli a questa offerta',
            SAVE_BEFORE_ADD: 'Devi prima salvare per poter aggiungere elementi',
            ITEM_OFFERED: 'Element{count, plural, =0{i} one{o} other{i}} offert{count, plural, =0{i} one{o} other{i}}',
            ITEM_OFFERED_TITLE: 'Element{count, plural, =0{i} one{o} other{i}} offert{count, plural, =0{i} one{o} other{i}} (click per rimuoverli dall\'offerta)',
            AVAILABLE_CLICK: 'disponibil{count, plural, =0{i} one{e} other{i}} (click aggiungi/rimuovi dall\'offerta)',
            ITEM_MAP: 'Planimetria',
            ITEM_MAP_TIP: 'Devi prima salvare per abilitare questa sezione',
        },

        'ORG': {
            NEW:            'Nuova organizzazione',
            DETAIL:         'Inserisci le informazioni della tua azienda',
            CONTACT_MYSPOT: 'Contatta il team di MYSPOT per configurare la tua società.',
            BASIC_INFO:     'Informazioni base',
            COMPANY_NAME:   'Nome Società',
            TAX:            'Codice Fiscale',
            VAT:            'Partita IVA',
            TEL:            'Telefono',
            DESCRIPTION:    'Descrizione',
            CONTACT:        'Contatti Responsabile',
            NAME:           'Nome e Cognome',
            SPOTS:          'Spot',
            ADD:            'Aggiungi nuovo Spot',
            SPOT_NAME:      'Nome Spot',
            TYPE:           'Tipo',
            BUILDING:       'Edificio',
            SELECTED:       'selezionati',
            TOTAL:          'totali',
            PREMIUM_FEATURES: 'Funzionalità Premium',
            SENDER_EMAIL: 'Indirizzo custom per invio email',
            SENDER_ALIAS: 'Alias per invio email',
            PLACE_DETAIL: 'Dettaglio del Posto'
        },

        'VEND': {
            NEW:            'New Organization',
            DETAIL:         'Organization Detail',
            CONTACT_MYSPOT: 'Contact MYSPOT team to add your organization',
            BASIC_INFO:     'Basic Info',
            COMPANY_NAME:   'Company Legal Name',
            TAX:            'Tax Code',
            VAT:            'Vat Id',
            TEL:            'Telephone',
            DESCRIPTION:    'Description',
            CONTACT:        'Contact Person',
            NAME:           'Full Name ',
            ADD:            'Add New Spot',
            TYPE:           'Type',
            TOTAL:          'total',
            CURRENCY:       'Currency',
            NEMPLOYEES: 'Number of employees',
            GEOPRESENCE: 'Geographical Presence',
            PARENTCOMPANY: 'Do you have a Parent Company?',
            SUBCOMPANY: 'Do you have a Subsidiary Company?',
            TERRITORY: 'Geographical area and states covered',
            CONTACTS: 'Contacts',
            ADDRESS: 'Address',
            CITY: 'City',
            ZIP: 'Zip code',
            COUNTRY: 'Country',
            SITE: 'Web site',
            CONTACTPEOPLE: 'Contact people',
            HISTORY: 'History',
            YEARS: 'Years in Existence',
            CLIENTS: 'Main clients',
            SUMMARY: 'Brief Summary of Company',
            CERTIFICATIONS: 'Certificate',
            SERVICES: 'Service: prodide a list',
            OTHERINFO: 'Other info',

        },


        'BOOKING': {
            DESKBUSY: 'La risorsa è stata già occupata.',
            COLLEGUE: 'Collega',
            STATUS: {
                REQUESTED: 'Prenotata',
                CONFIRMED: 'Attesa Check-In',
                IN_PROGRESS: 'Confermata',
                CLOSED: 'Conclusa',
                CANCELLED: 'Cancellata',
                AWAITING_PAYMENT: 'In attesa di pagamento',
                AWAITING_PAYMENT_RESPONSE: 'Attesa conferma di pagamento',
                EXPIRED: 'Scaduta',
                PAID: 'Pagata',
                REJECTED: 'Rifiutata',
            },
            CLEAR_FILTERS: 'Resetta i filtri',
            RESERVATION: 'Dettagli Prenotazione',
            COLLEAGUE: 'Devi prenotare per un collega?',
            COLLEAGUE_INFO: 'Collega',
            DESK: 'Postazione',
            MEETINGROOM: 'Sala',
            PARKING_SLOT: 'Parcheggio',
            LODGINGBUSINESS: 'Edificio',
            EVENT: 'Evento',
            BROADCASTEVENT: 'Evento trasmesso',
            SEARCH_TEXT: {
                BASE: 'Stai cercando',
                POURPOSE: 'per una',
                ATTENDEE: 'Per <strong>{attendee}</strong> partecipanti',
                DAY: 'il',
                START: 'da',
                END: 'a',
                PLACE: 'in',
                FLOOR: 'al piano',
                ALL_FLOORS: 'su tutti i piani',
                ZONE: 'nell\'area',
                ALL_ZONES: 'su tutti le area',
            },
            ENTER_PASS: 'Inserisci il tuo pass per prenotare rapidamente',
            LOOK_AGENDA: 'Controlla l\'agenda',
            HIDE_AGENDA: 'Nascondi l\'agenda',
            FEATURES: 'Servizi',
            INVALID_DATE: 'Il periodo selezionato non è valido',
            SELECT_PERIOD: 'Seleziona queste date',
            AWAITING_SUGGESTIONS: 'In attesa dei suggerimenti',
            SUGGESTED_TIMESLOT: 'Orari suggeriti',
            NO_SUGGESTION: 'Nessun suggerimento',
            PROPOSALS: 'Soluzioni',
            FILTER_PROPOSALS: 'Soluzioni con questi filtri',
            ASSIGNED_SPACE: 'Spazio Assegnato',
            RESOURCES: 'Soluzioni',
            FILTER_RESOURCES: 'Prosposte con questi filtri',
            TIME_GRANULARITY: 'Cambia formato minuti',
            NO_TIME_AVAIABLE: 'Chiusura settimanale',
            NO_TIME_BLOCK: 'Limite temporale',
            USE_FILTER: 'Usa i filtri per iniziare la ricerca',
            CHANGE_FILTER: 'Prova a cambiare i filtri per avere più soluzioni',
            FILTER_BY_NAME: 'Filtro nome',
            SEARCH_BY_EMAIL: 'Scrivi la mail',
            INVITE_ATTENDEES: 'Invita partecipanti...',
            INSERT_VALID_EMAIL: 'Per favore inserisci una mail valida',
            SELECT_START: 'Seleziona orario inizio',
            SELECT_END: 'Seleziona orario fine',
            ALL_FLOORS: 'Tutti i Piani',
            ALL_ZONES: 'In ogni area',
            QR_CODE: 'Fai il check-in con l\'app',
            CONFIRM_DIALOG: {
                TITLE: 'Prenotazione confermata!',
                BODY: 'Vuoi riservare qualcos\'altro?',
                CALENDAR: 'No, grazie',
                RESERVE: 'Certo!',
                REFRESH_TOKEN: {
                    TITLE: 'Sessione scaduta',
                    BODY: 'Sei stato inattivo troppo a lungo, aggiorna la sessione per continuare',
                    BODY_DONE: 'La tua sessione è stata aggiornata.<b>Continua per finalizzare la prenotazione',
                    REFRESH: 'Aggiorna',
                    RETRY: 'Riprova',
                    DONE: 'Continua',
                    WAIT: 'Verifica...',
                }
            },
            RESERVATIONS: {
                REJECT_DIALOG_TEXT: 'Inserire un motivo del rifiuto.',
                REJECT_REASON: 'Rifiutare Motivo',
                REJECT_SUCCESS: 'Prenotazione Rifiutata Correttamente',
                REJECT_ERROR: 'Errore nel Rifiuto della Prenotazione'
            },
            SEARCH_FROM_DATE: 'Dal giorno',
            SEARCH_TO_DATE : 'Al giorno',
            SEAT: 'Posto',
            NO_LODGING: 'Nessuna sede trovata',
            FIND_COLLEAGUE: 'Cerca un collega',
            TIMEZONE_BOOKING: 'Stai ora prenotando con il fuso orario di',
            MAP_LEGEND: {
                OK : 'Disponibile',
                NOT_OK: 'Non Disponibile',
                PARTIAL : 'Disponibile (solo alcuni giorni)',
                ASSIGNED : 'Desk Personale',
                OWNED: 'Prenotato',
                COLLEAGUE : 'Collega',
                COLLEAGUE_SELECTED : 'Collega (selezionato)',
                SELECTED : 'Selezionato',
                DEFAULT : 'Non disponibile',
            },
            ERROR: {
                COVID_LIMIT: {
                    TITLE: 'Limite raggiunto',
                    MESSAGE: 'A causa delle restrizioni Covid 19 abbiamo limitato il numero di accessi per questa area',
                    TIPS: 'Prova a cambiare orario, giorno o piano',
                    DETAILS: '',
                },
                TIME_LIMIT: 'Prenotazioni oltre ',
                TIME_LIMIT1: 'giorni non sono permesse',
                TIME_LIMIT_TITLE: 'Limite raggiunto',
                NOT_PROPOSAL: 'Ci dispiace, ma si è verificato un errore. Prova a rieffettuare la ricerca.'
            },
            NOT_FIND_COLLEAGUE: 'Nessun collega presente',
            FULL_DAY: 'Giornata intera',
            NO_Place: 'Non sono presenti risultati per la ricerca effettuata',
            NO_RESERVATION: 'Il collega non ha prenotazioni in questo orario',
            NO_RESERVATION_FLOOR: `Il collega ha una prenotazione per un altro piano`,
            N0_ORDER: 'Esiste già una prenotazione durante la data e orario selezionati. Cambia la ricerca per prenotare.'
        },

        'WEEK': {
            SUNDAY : 'DOMENICA',
            MONDAY : 'LUNEDI',
            TUESDAY : 'MARTEDI',
            WEDNESDAY : 'MERCOLEDI',
            THURSDAY : 'GIOVEDI',
            FRIDAY : 'VENERDI',
            SATURDAY : 'SABATO',
        },

        'EVENTS': {
            STATUS: {
                REQUESTED: 'Prenotata',
                CONFIRMED: 'Confermata',
                IN_PROGRESS: 'Confermata',
                CLOSED: 'Conclusa',
                CANCELLED: 'Cancellata',
                AWAITING_PAYMENT: 'In attesa di pagamento',
                AWAITING_PAYMENT_RESPONSE: 'Attesa conferma di pagamento',
                EXPIRED: 'Scaduta',
                PAID: 'Pagata',
            },
            DATE_HOURS : 'Data & Orari',
            LOCATION : 'Posizione',
            NAME : 'Nome',
            RESERVE_TICKET: 'Prenota un Ticket',
            JOIN_TEAM: 'Unisciti all\'Evento',
            SUBSCRIBE: 'Iscriviti all\'Evento',
            UNSUBSCRIBE: 'Annulla l\'iscrizione',
            COUNTDOWN: 'Inizierà: {countdown}',
            CLOSED: 'Evento concluso',
            SEARCH_LOCATION: 'Filtra per posizione',
            SEARCH_EVENT_NAME: 'Filtra per nome evento',
            SEARCH_WHERE: 'Dove?',
            EVENT_NAME: 'Nome Evento',
            SEARCH_FROM_DATE: 'Dal giorno',
            SEARCH_TO_DATE : 'Al giorno',
            HIDE_FILTERS :'Nascondi Filtri',
            SHOW_MORE_FILTERS : 'Mostra Filtri',
            FOUNDED : 'Eventi trovati:',
            EVENTS : 'Eventi',
            EVENTS_BY_FILTERS : 'con i filtri selezionati',
            PAST_EVENT: 'Eventi conclusi',
            NO_RESULTS : 'Prova a cambiare filtri per avere più risultati',
            ONLY_PAST: 'Non ci sono eventi in programma, ma puoi dare un\'occhiata a quelli passati.',
            YOU_ARE_HERE : 'Tu sei qui',
            CHOOSE_TICKET: 'Seleziona un biglietto',
            HOW_MANY_TICKETS: 'Quanti biglietti?',
            TICKETS: 'Biglietti',
            NO_OFFERS: 'Ci dispiace,<br> ma i biglietti non sono ancora stati resi disponibili per questo evento.<br>Per favore, riprova più tardi.',
            NO_TICKETS: 'Ci dispiace,<br> ma non ci sono posti disponibili per il biglietto selezionato',
            NO_OFFERS_VIRTUAL: 'Ci dispiace,<br> le iscrizioni non sono ancora aperte per questo evento.<br>Riprova più tardi.',
            TICKET_RESERVED_VIRTUAL: 'Iscrizione confermata!',
            TICKET_RESERVED: 'I tuoi biglietti sono stati prenotati',
            TICKET_RESERVED_TEMP: 'Abbiamo fermato i tuoi biglietti',
            TICKET_RESERVED_VIRTUAL_TEMP: 'Iscrizione da confermare',
            CHECK_CALENDAR: `Se hai eseguito il login con Office 365,<br>l\'evento è sul tuo calendario Outlook`,
            ICS_TEXT: `In alternativa, scarica il file ICS per aggiungerlo al tuo calendario`,
            DOWNLOAD_ICS: 'Aggiungi al calendario (ICS)',
            ERROR_RESERVATION: 'Ci dispiace!<br> Qualcosa non ha funzionato. Riprova più tardi.',
            GO_CALENDAR: 'Mostrami il mio calendario',
            RESERVE: 'Conferma',
            LOGIN_REQUIRED: 'Devi essere loggato per poter prenotare il biglietto',
            OFFER_AVAILABILITY: 'Validità offerta',
            OFFER_AVAILABILITY_FROM: 'Da: ',
            OFFER_AVAILABILITY_TO: 'Fino al: ',
            PARTECIPANTS: 'Participanti',
            ACCOUNTHOLDER: 'intestatario',
        },

        RECEPTION: {
            NOT_REGISTERED: 'L\'ospite non è ancora registrato',
            GUESTS: 'Ospiti',
            ON_BEHALF_OF: 'Referente',
            N_GUESTS: 'N. Ospiti',
            VALID_FROM: 'Inizio',
            VALID_THROUGH: 'Fine',
            BUILDING: 'Sede',
            REQUESTOR: 'Organizzatore',
            ADD_ACCESS_REQUEST: 'Crea Richiesta d\'accesso',
            SEARCH: 'Cerca Richieste d\'accesso',
            INSERT_REFERENT: 'Inserisci la mail del referente',
            ADD_GUESTS_TIP: 'Aggiungi <strong>Ospiti</strong> tramite l\'apposito pannello',
            GRANT_ACCESS: 'Autorizza <strong>ingresso</strong>',
            INSERT_ACCESS_INFO: 'Inserisci le informazioni della <strong>Richiesta d\'accesso</strong>',
            ACCESS_READY_TITLE: 'La <strong>Richiesta d\'accesso</strong> è pronta!',
            REPEAT_PROCESS: 'Ripeti il processo dal punto <strong>2</strong> per aggiungere un altro <strong>Ospite</strong> o premi il pulsante per inoltrare la <strong>Richiesta d\'accesso</strong> ai tuoi <strong>Ospiti</strong>',
            HELLO: 'Ciao, <strong>{name}</strong>!',
            WELCOME_MESSAGE: 'Per iniziare, compila le informazioni della <strong>Richiesta d\'accesso</strong>',
            STEP2: {
                TITLE: 'Aggiungi almeno un <strong>Ospite</strong>',
                SEARCH_LABEL: `Scrivi la mail dell'<strong>Ospite</strong>`,
                SEARCH_HINT: 'Cerca l\'<strong>Ospite</strong> nel nostro archivio. Se non esiste ancora, puoi registrarlo come nuovo.'
            },
            STEP3: {
                TITLE: 'Seleziona il tuo <strong>Ospite</strong> dalla lista',
                TITLE2: 'Nessun <strong>Ospite</strong> corrispondente. Registralo!',
                TITLE_ERROR: 'Non è possibile invitare i colleghi',
                INSERT_VALID_EMAIL: 'Per favore inserisci una mail valida',
            },
            STEP4: {
                TITLE: 'Verifica tutte le informazioni dell\'<strong>Ospite</strong>',
                PRIVACY_BUTTON: 'Stampa le condizioni della privacy'
            },
            STEP5: {
                BUTTON: 'Aggiungi <strong>Ospite</strong>',
            },
            OK_MESSAGE: 'I tuoi <strong>Ospiti</strong> riceveranno una mail con le istruzioni per accedere',
            OK_TITLE: 'Accesso consentito',
            STATUS: 'Status',
        },

        INVITATION: {
            ADD_ACCESS_REQUEST: 'Crea Invito',
            SEARCH: 'Cerca inviti',
            GRANT_ACCESS: 'Spedisci <strong>Invito</strong>',
            INSERT_ACCESS_INFO: `Inserisci le informazioni dell'<strong>Invito</strong>`,
            ACCESS_READY_TITLE: `L'<strong>Invito</strong> è pronto!`,
            REPEAT_PROCESS: `Ripeti il processo dal punto <strong>2</strong> per aggiungere un altro <strong>Ospite</strong> o premi il pulsante per inoltrare l'<strong>Invito</strong> ai tuoi <strong>Ospiti</strong>`,
            WELCOME_MESSAGE: `Per iniziare, compila le informazioni dell'<strong>Invito</strong>`,
            OK_TITLE: 'Invito spedito',
        },

        ACCESS: {
            REGISTRATION: {
                TITLE : 'Registrazione',
                PERSONAL_DATA : 'Dati personali',
                REQUIRED: 'È un campo obbligatorio!',
                NAME : 'Nome',
                SURNAME : 'Cognome',
                BIRTHDAY : 'Data di nascita',
                COMPANY : 'Società',
                DOCUMENT : {
                    TITLE : 'Documento di identità',
                    TYPE : 'Tipo di documento',
                    ID : 'Carta di identità',
                    DRIVING_LICENSE : 'Patente',
                    PASSPORT : 'Passaporto',
                    NUMBER : 'N. Documento',
                    ISSUED_BY : 'Rilasciato da',
                    EXPIRING : 'Scadenza',
                    EXPIRED: 'Scaduto',
                    'Identity Card': 'Carta di identità',
                    'Driving License': 'Patente',
                    'Passport': 'Passaporto'
                },
                PRIVACY : {
                    TITLE : 'Privacy e trattamento dei dati',
                    SUBTITLE : 'INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI DEI VISITATORI',
                    TEXT : 'Il sottoscritto, letta l’informativa che precede, acconsente al trattamento dei propri dati personali consapevole dei propri diritti come sopra previsti.'
                },
                POLICY_CONFIRM : 'Accetto le condizioni sulla privacy',
                CONFIRM : 'Conferma registrazione',
                COMPLETE : {
                    TITLE_1 : 'La tua registrazione è completata.',
                    TITLE_2 : 'Riceverai una mail poco prima dell\'appuntamento con le istruzioni per richiedere il accesso.'
                },
                EMAIL: 'Mail',
                INSERT_VALID_EMAIL: 'Per favore inserisci una mail valida',
            },
            ACCEPTANCE: {
                TITLE: 'Accettazione',
                ME: 'Io sottoscritto',
                REQUIRED_MESSAGE: 'È un campo obbligatorio!',
                NAME : 'Nome',
                SURNAME : 'Cognome',
                COMPANY : 'Società',
                TODAY : 'Data odierna',
                SIGNAGE : 'Firma',
                DELETE_SIGNAGE : 'Cancella la firma',
                CONFIRM : 'Conferma l\'accettazione',
                COVID : {
                    TITLE: 'Questionario COVID-19',
                    CHECK: 'Confermo la dichiarazione sul COVID-19',
                    MESSAGE : {
                        ROW1: 'In relazione ai casi di contagio da Coronavirus Covid-19 riscontrarti recentemente in Italia.',
                        ROW2: 'Dichiaro di impegnarmi ad adottare tutte le misure di prevenzione, anche in conformità alle raccomandazioni ed alle istruzioni dell\'OMS e delle Autorità Sanitarie Nazionali, atte a prevenire la diffusione del nuovo Coronavirus COVID-19.',
                        ROW3: 'Altresì dichiaro:',
                        UL_1: {
                            LI_1: 'di non presentare sintomatologie tipiche di chi è affetto da Coronavirus COVID-19',
                            LI_2: 'di non essere stato in contatto, per quanto di mia conoscenza, con una persona affetta da Coronavirus COVID-19',
                            LI_3: 'di non aver ricevuto comunicazione da parte delle autorità competenti in merito ad un mio contatto stretto con una persona contagiata da Coronavirus COVID-19.'
                        },
                        ROW4 : 'Infine dichiaro:',
                        UL_2: {
                            LI_1: 'che il mio spostamento sul territorio nazionale è nel rispetto dei decreti del Presidente del Consiglio dei Ministri, in materia di emergenza Coronavirus;',
                            LI_2: 'che osservo le limitazioni degli spostamenti indicate nel D.P.C.M. in vigore ovvero che non provengo dalle aree identificate in “arancione” e “rossa" se non per comprovate esigenze lavorative;',
                            LI_3: 'che osservo le disposizioni relative ai rientri in Italia da Paesi Esteri stabilite nel D.P.C.M. in vigore.'
                        }
                    }
                },
                SECURITY : {
                    TITLE: 'Informazioni sulla sicurezza',
                    DESCRIPTION : 'Dichiaro di aver preso visione dell’informativa in materia di sicurezza.',
                    DOWNLOAD : 'Scarica qui la documentazione',
                    CHECK: 'Confermo la dichiarazione sulla sicurezza'
                },
                PRIVACY: {
                    CHECK: 'Accetto le condizioni sulla privacy',
                    TEXT: 'L’Interessato conferma di aver visualizzato l’informativa privacy e manifesta ogni volta il proprio espresso consenso al trattamento automatizzato dei propri dati personali, consapevole dei propri diritti come previsti nella suddetta informativa.',
                },
                NOT_REGISTERED : {
                    TITLE : 'Non sei registrato.',
                    DESCRIPTION : 'Per procedere con l’accettazione è necessario prima effettuare la registrazione tramite la mail che hai ricevuto contestualmente all’invito.'
                },
                COMPLETE : {
                    TITLE_1 : 'I tuoi dati sono stati inviati correttamente.',
                    TITLE_2 : 'Riceverai una e-mail per accedere.',
                    DESCRIPTION : 'Puoi lasciare aperta questa pagina in attesa del badge, o tornare più tardi per controllare che sia stato abilitato.'
                },
                NOT_VALID_TIME_TITLE: 'Operazione Non Consentita',
                NOT_VALID_TIME_DESCRIPTION: 'Siamo spiacenti, l’incontro è stato spostato. Ti arriverà una nuova mail di accettazione poco prima dell’appuntamento.',
            },
            BADGE: {
                GUEST_EXPIRED: 'Il tuo badge è scaduto.',
                GUEST_REJECTED: 'Ci dispiace, ma il tuo badge è stato annulato.',
                GUEST_DEFAULT_ERROR: 'Ci dispiace, ma si è verificato un errore.',
                GUEST_ACCESS_GRANTED: 'Prego, può entrare.',
                GUEST_SCAN_REJECTED: 'Siamo spiacenti, ma l\'accesso al momento non è possibile.',
                GUEST_TIME_NOT_VALID: 'Siamo spiacenti, ma il badge non è valido per la giornata odierna.',
                WELCOME: 'BENVENUTO',
                ENJOY_YOUR_STAY: 'Buona permanenza.',
                GOODBYE: 'Arrivederci',
                GOODBYE_GREET: 'Le auguriamo una buona giornata',
                COMPLETE: {
                    AUTHORIZATION_TEXT: 'L\'ospite ha completato l\'accettazione ed è in attesa della tua autorizzazione.',
                    AUTHORIZATION_BUTTON: 'Autorizza l\'accesso',
                    SUCCESS: 'Autorizzazione confermata. Il tuo ospite ora può accedere alla struttura',
                    ERROR: 'Si è verificato un errore. Riprova più tardi.'
                },
            },
            GUEST: {
                TITLE: 'Ospite Accesso',
                CHECK_IN: 'Check-in',
                CHECK_OUT: 'Check-out',
                QR_CODE_ENTRANCE: 'Entra o esci tramite QR Code',
                REGISTER: 'Registrati',
                WELCOME_TO: 'Benvenuto a',
                HAS_QR_CODE: 'Hai un QR code per accedere? Effettua il check-in qui:',
                NOT_REGISTERED: 'Se non sei stato invitato puoi registrarti qui:',
                CREATE_NEW_REGISTRATION: 'Se non viene trovata alcuna registrazione, crearne una nuova:',
                QRCODE_TO_SCANNER: 'Mostra qui il codice che ti abbiamo spedito per mail',
                SCAN_QRCODE: 'Please use you device to scan the code above',
                CHOOSE_HOW_TO_CONTINUE: 'Scegli ora come continuare la tua registrazione',
                CONTINUE_VIA_APP_TEXT: 'Continua la registrazione sul tuo cellulare.',
                CONTINUE_VIA_APP_BUTTON: 'Mobile App',
                CONTINUE_VIA_TABLET_TEXT: 'Continua a qui.',
                CONTINUE_VIA_TABLET_BUTTON: 'Continua',
                HOST_EMAIL_TEXT: 'Per favore, inserisci la mail del tuo referente:',
                OWN_EMAIL_TEXT: 'Per favore, inserisci la tua mail:',
                TABLET: 'Continua qui',
                MOBILE: 'Concludi sullo Smartphone',
                HOST_MAIL: 'Mail referente',
                YOUR_MAIL: 'La tua Mail',
                GUEST_STATUS: 'Registrazione',
                STATUS: {
                    GUEST_AWAITED: 'Registrato',
                    GUEST_ACCESS_REQUESTED: 'Da autorizzare',
                    GUEST_ACCESS_GRANTED: 'Abilitato',
                    GUEST_ENTERED: 'Entrato',
                    GUEST_EXITED: 'Uscito',
                    GUEST_EXPIRED: 'Disabilitato',
                    GUEST_NOT_REGISTERED: 'Non registrato',
                    GUEST_AWAITED_DESC: `L'ospite si è registrato`,
                    GUEST_ACCESS_REQUESTED_DESC: `L'ospite è in attesa di autorizzazione`,
                    GUEST_ACCESS_GRANTED_DESC: `L'ospite può accedere`,
                    GUEST_ENTERED_DESC: `L'ospite è entrato`,
                    GUEST_EXITED_DESC: `L'ospite è uscito`,
                    GUEST_EXPIRED_DESC: `L'appuntamento non è più valido`,
                    GUEST_NOT_REGISTERED_DESC: `L'ospite non si è registrato`,
                },
            },
            SELF: {
                SELECT_LODGING: 'Seleziona l\'edificio',
            }
        },

        PROFILE: {
            MENU: 'Profilo',
            SAVED: 'Profilo aggiornato!',
            SAVED_ERROR: 'Ops... qualcosa è andato storto. Riprova',
            PROFILE_PIC: 'Foto profilo',
            UPLOAD_PIC: 'Carica la tua foto',
            NO_NAME: 'Nome incompleto!',
            TITLE: 'Profilo personale',
            JOB_TITLE: 'Ruolo Aziendale',
            SHORT_BIO: 'Descrizione in breve',
            DESCRIPTION: 'Descrizione',
            SKILLS_LABEL: 'Competenze',
            INTERESTS_LABEL: 'Interessi',
            NEW_SKILL: 'Aggiungi competenza',
            NEW_INTEREST: 'Aggiungi interesse',
            LINKEDIN: 'Link a profilo LinkedIn',

            PHOTOS : {
                M1: 'Utilizza immagini verticali con proporzione 3:4 per visualizzarle correttamente',
                M2: 'La prima immagine caricata verrà utilizzata come copertina del profilo',
                M3: 'Per cancellare un’immagine, selezionala e clicca sul tasto rosso Cancella. Clicca su Salva per confermare'
            },

            VISIBILITY: {
                LABEL: 'Visibilità del profilo',
                COLLEAGUES : 'Colleghi',
                NOT_VISIBLE : 'Non visibile',
                COMMUNITIES : 'Community',
                MYSPOT : 'MYSPOT',
                COLLEAGUES_CLAIM: 'Sei sicuro di conoscere davvero tutti i tuoi colleghi?',
                NOT_VISIBLE_CLAIM: 'Inizia ad utilizzare MYSPOT senza essere visibile dal network.',
                COMMUNITIES_CLAIM: 'Avere idee e progetti in comune permette di sintonizzarti molto oltre i confini aziendali.',
                MYSPOT_CLAIM: 'Conosci e resta in contatto con chi condivide i tuoi stessi interessi.',
                COLLEAGUES_DESC : 'Condividi con loro talenti e passioni: potresti scoprire un mondo con non ti aspetti.',
                NOT_VISIBLE_DESC : 'In qualsiasi momento potrai iniziare a farti conoscere.',
                COMMUNITIES_DESC : 'Avere degli interessi in comune permette di partire già sintonizzati. È un’occasione da prendere al volo.',
                MYSPOT_DESC : 'Permetti a tutto il network MYSPOT di conoscerti.',
            }

        },

        TIPS: {
            TITLE: 'Title',
            TAG: 'Tag',
            TAGS: 'Tags',
            SEARCH: 'Search',
            SEARCH_TIPS: 'Search tips',
            SEARCH_TAG: 'Search by tag',
            DESCRIPTION: 'Description',
            DATE_CREATED: 'Date Created',
            LINK: 'Link',
            OPEN: 'Open',
            PREVIOUS: 'precedente',
            NEXT: 'prossimo',
            NO_RESULTS: 'There are currently no tips available.'
        },

        PARKING_SLOTS : {
            ADD_NEW: 'Aggiungi Nuovo Parcheggio',
            LOCATION: 'Presso',
            CAPACITY: 'Capacità',
            PARKING_SLOT_SUBTITLE: 'Dettagli parcheggio',
            NEW_PARKING_SLOT_TITLE: 'Nuovo Parcheggio',
            PARKING_SLOT_PHOTOS: 'Foto Parcheggio',
            FEATURES_TITLE: 'Seleziona le caratteristiche di questo parcheggio',
            CHANGE_INFO: 'Modificare tali valori solo se il posto e questo parcheggio hanno orari di apertura diversi.'
        },

        EMPLOYEES: {
            FIRST_NAME: 'Nome',
            LAST_NAME: 'Cognome',
            EMAIL: 'Email',
            EMPLOYEE_NUMBER: 'Matricola',
            DEFAULT_BUILDING: 'Edificio Default',
            DEFAULT_FLOOR: 'Piano Default',
            CURRENT: 'Assegnati',
            AVAILABLE: 'Disponibili',
            CLUSTERS: 'Gruppi',
            CLUSTER_UPDATED: 'Gruppo Aggiornato',
            CLUSTER_UPDATED_ERROR: 'Gruppo Non Aggiornato',
            SELECT_FROM_BELOW: 'Seleziona dall\'elenco qui sotto.',
            ADD_BUTTON: 'Aggiungi',
            DELETE_BUTTON: 'Rimuovi',
            ASSIGNED: 'Cluster assegnati',
            TO_ASSIGN: 'Cluster da assegnare',
            CHECK: 'Seleziona',
            NAME: 'Nome',
            DESCRIPTION: 'Descrizione',
            FILTER: 'Filtro',
            DESK_TITLE: 'assigned desks',
            DESK_CODE: 'Codice Desk',
            DESK_NAME: 'Nome Desk',
            DESK_FLOOR: 'Piano',
            BUILDING_CODE: 'Codice Building',
            BUILDING_NAME: 'Nome Building',
            USER_ACTIVE: 'Attiva',
            USER_DEACTIVATE: 'Disattiva',
            DESK_ASSIGNED: 'Risorse assegnate',
            ERROR: 'Errore innato',
            MESSAGE_INFO: 'l\'utenza si trova in un altro stato per maggiori info',
            EMAIL_BUTTON: 'contattaci',
            DESK_ERROR_CODE: 'Errore nella rimozione delle desk',
            MESSAGE_INFO_ERROR: 'L\'utente si trova nello stato ',
            MESSAGE_INFO_ERROR1: ' gestibile mediante helpdesk. Per modificare apri un ticket',
            MESSAGE_INFO_ERROR2: 'cliccando qui'
        },
        CLUSTERS: {
            ADD_NEW: 'Aggiunge Nuovo Cluster',
            CLUSTER_TITLE: 'Cluster Profilo',
            NAME: 'Nome',
            DESCRIPTION: 'Descrizione',
            BASIC_INFO: 'Prima Info',
            EMPLOYEES: 'Dipendenti',
            NEW_CLUSTER_DIALOG: 'Inserire i nuovi dettagli del cluster.',
            DELETE_CLUSTER_DIALOG: 'Confermare l\'eliminazione del cluster:',
        },
        ZONES: {
            ADD_NEW_FLOOR: 'Aggiunge Nuovo Piano',
            OBJECTS: 'Oggetti',
            NEW_FLOOR_TITLE: 'Nuovo Piano',
            FLOOR_SUBTITLE: 'Dettagli Piano',
            CONTAINED_OBJECTS: 'Oggetti Contenuti',
            LOGICAL_REPRESENTATION_OF: 'Rappresentazione Logica Di',
            ZONE_TYPE: 'Tipo di Zona',
            CURRENT_OBJECTS: 'No. di oggetti attualmente contenuti su questo piano:',
            REMOVE_OBJECT: '(Clicca su un oggetto per rimuoverlo da questo piano. Quindi premere Salva.)',
            ADD_OBJECT: '(Fare clic su un oggetto per aggiungerlo a questo piano. Quindi premere Salva.)',
            AVAILABLE_OBJECTS_TITLE: 'Oggetti Disponibili',
            AVAILABLE_OBJECTS: 'No. di oggetti disponibili:'
        },
        RESERVATIONS: {
            RESERVATION_SUBTITLE: 'Prenotazione Detaglio',
            RESERVATION_DATA: 'Prenotazione Dati',
            RESERVATION_ID: 'Prenotazione ID',
            OWNER_NAME: 'Nome'
        },

        PRIVACY_TITLE: 'Proseguendo accetti il nostro',
        PRIVACY: 'Termini del servizio e Privacy Policy'
    }
};

<div class="guest-card card-container {{layout}}" [ngClass]="{'flat': flat}">
  <div class="check">
    <mat-icon>check</mat-icon>
  </div>
  <div *ngIf="owner" class="owner" fxLayout="row" fxLayoutAlign="center center">
    <ms-avatar [person]="owner" [size]="80"></ms-avatar>
  </div>
  <div class="fuse-card ms-card">

    <div class="position-relative card-img-container">

      <div class="card-img" [ngStyle]="{'background-image': 'url(' + cardImg + ')'}"></div>
      <div class="position-absolute card-title align-left align-bottom no-wrap">

        <div *ngIf="status" fxLayout="row" fxLayoutAlign="end center" class="offer-price">
          <div class="title" matTooltip="{{'ACCESS.GUEST.STATUS.' + status + '_DESC' | translate}}">
            {{'ACCESS.GUEST.STATUS.' + status | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="card-body" fxLayout="column" #cardBody fxLayoutAlign="space-between start">
      <div class="top w-100-p">
        <div class="card-title no-wrap" #cardTitle>
          {{guest.user?.firstName}} {{guest.user?.lastName}}
        </div>
        <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="secondary-text">mail</mat-icon>
          <span style="overflow: hidden">
            <span class="no-wrap" #cardAddress>{{guest.user?.email}}</span>
          </span>

        </div>
      </div>
      <div class="middle w-100-p">
        <div *ngIf="person?.companyName" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon matTooltip="{{'ACCESS.REGISTRATION.COMPANY' | translate}}">business</mat-icon>
          <strong>{{person?.companyName}}</strong></div>
      </div>

      <div class="bottom w-100-p mt-12">
        <div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="space-between baseline" fxLayoutGap="6px">
          <div *ngIf="guest.documentType" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon matTooltip="{{'ACCESS.REGISTRATION.DOCUMENT.TITLE' | translate}}">assignment_ind</mat-icon>
            <strong>{{'ACCESS.REGISTRATION.DOCUMENT.' + guest.documentType | translate}}</strong>
          </div>
          <div *ngIf="guest.documentExpireDate" fxLayout="row" fxLayoutAlign="start center" [ngClass]="{'expired' : isDocumentExpired(guest.documentExpireDate)}">
            <mat-icon matTooltip="{{'ACCESS.REGISTRATION.DOCUMENT.EXPIRING' | translate}}">event</mat-icon>
            <strong>{{guest.documentExpireDate | date: 'shortDate' : tz : translateService.currentLang}}</strong>
          </div>
          <div *ngIf="guest.documentNumber" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon matTooltip="{{'ACCESS.REGISTRATION.DOCUMENT.NUMBER' | translate}}">numbers</mat-icon>
            <strong>{{guest.documentNumber}}</strong>
          </div>
        </div>
      </div>




    </div>

  </div>
</div>

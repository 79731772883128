<div class="card-container {{layout}}" [ngClass]="{'flat': flat}">
    <div class="check">
        <mat-icon>check</mat-icon>
    </div>
    <div class="fuse-card ms-card">

        <div *ngIf="options?.showButtons" class="p-16 pt-0 buttons" fxLayout="row" fxLayoutAlign="end top"
            fxLayoutGap="5px">
            <button mat-icon-button color="accent" (click)="modify()">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="delete()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>

        <div class="position-relative" class="card-img-container">
            <div class="card-img" [ngStyle]="{'background-image': 'url(' + cardImg + ')'}"></div>

            <div *ngIf="event.organizers && event.organizers[0]?.legalName" class="position-absolute align-left align-top">
                    <div *ngIf="event.organizers[0]?.legalName" class="chip primary mt-16 ml-16">
                        <strong>{{event.organizers[0].legalName}}</strong>
                    </div>
                </div>

            <div class="position-absolute align-left align-top p-8" fxLayout="row" fxLayoutGap="4px">
                <div class="container-name chip primary" *ngIf="subscribers && !event.hideAttendeesCount">
                    <mat-icon>people</mat-icon>
                    <span class="chip-text"><strong>{{subscribers}}</strong></span>
                </div>

                <div class="container-name chip selected" *ngIf="likesCount">
                    <mat-icon>star</mat-icon>
                    <span class="chip-text"><strong>{{likesCount}}</strong></span>
                </div>
                
            </div>

            <div class="position-absolute card-title align-left align-bottom">
                <div class="offer-price">
                    <div *ngIf="!offer || offer.totalPrice == 0" class="title">Free</div>
                    <div *ngIf="offer && offer.totalPrice > 0" class="title">from {{offer.totalPrice}}{{offer.priceCurrency | translate}}</div>
                </div>
            </div>

            <div *ngIf="event.eventSchedules[0]" class="card-date position-absolute align-right align-bottom">
                <div class="title">
                    <span class="day">
                        {{event.eventSchedules[0].startingDateTime | date: 'd' : tz : translateService.currentLang}}
                    </span>
                    <span class="month">
                        {{event.eventSchedules[0].startingDateTime | date: 'MMM' : tz : translateService.currentLang}}
                    </span>
                </div>
            </div>
        </div>

        <div class="card-body" fxLayout="column" fxLayoutAlign="space-between start">
            <div *ngIf="event" class="top w-100-p">
                <div class="card-title">
                    {{ (event.name.length>36)? (event.name | slice:0:36)+'..':(event.name) }}
                </div>
                <div *ngIf="event.location?.address" class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon matSuffix class="secondary-text">location_on</mat-icon>
                    <span>{{event.location?.address}}</span>
                </div>
            </div>


            <div *ngIf="event.eventSchedules[0]" class="top w-100-p pb-8">
                <div *ngIf="event.eventSchedules" class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon matSuffix class="secondary-text">today</mat-icon>
                    <span class="date">{{event.eventSchedules[0].startingDateTime | date: 'E d MMM yyyy' : tz : translateService.currentLang}} - {{event.eventSchedules[0].startingDateTime | date: 'shortTime' : tz : translateService.currentLang}}</span>
                </div>
            </div>

            <div *ngIf="place" class="top w-100-p pb-8">
                <div *ngIf="place.address" class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon matSuffix class="secondary-text pt-6">location_on</mat-icon>
                    <div><strong>{{place.name}}</strong><br><span class="address">{{place.address}}</span></div>
                </div>
            </div>


            <div class="middle w-100-p">

                <ng-container *ngIf="place?.amenityFeatures?.length && layout == 'horizontal'">
                    <div class="feats-icon-container pt-16 w-100-p" fxLayout="row wrap" fx fxLayoutGap="5px grid">
                        <ng-container *ngFor="let feat of place.amenityFeatures; let i=index">
                            <div *ngIf="feat.name && i < 6" class="feat mb-0">
                                <mat-icon matTooltip="{{feat.name}}">{{feat.image}}</mat-icon>
                            </div>
                            <div *ngIf="feat.name && i == 6" class="feat mb-0">
                                <mat-icon matTooltip="">more_horiz</mat-icon>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

            </div>


            <div class="bottom w-100-p">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between baseline" fxLayoutGap="6px">
                    <div fxFlex="0 0 auto" *ngIf="place?.amenityFeatures?.length && layout != 'horizontal'"
                        class="feats-icon-container " fxLayout="row" fx fxLayoutGap="5px">
                        <ng-container *ngFor="let feat of place.amenityFeatures; let i=index">
                            <div *ngIf="feat.name && i < 6" class="feat mb-0">
                                <mat-icon matTooltip="{{feat.name}}">{{feat.image}}</mat-icon>
                            </div>
                            <div *ngIf="feat.name && i == 6" class="feat mb-0">
                                <mat-icon matTooltip="">more_horiz</mat-icon>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="place?.maximumAttendeeCapacity > 1 && event.eventType !== 'VIRTUAL'" fxFlex="0 0 auto" fxLayout="row"
                        fxLayoutAlign="center start">
                        <mat-icon matSuffix class="secondary-text">event_seat</mat-icon>
                        <span>{{place.maximumAttendeeCapacity}}</span>
                    </div>
                </div>
                <div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="start top" fxLayoutGap="6px grid">
                    <div *ngIf="event.eventType">
                        <div class="container-name chip accent">{{event.eventType}}</div>
                    </div>    
                    <div *ngFor="let tag of event.tags">
                        <div class="container-name chip secondary">{{tag}}</div>
                    </div>
                        
                </div>
            </div>




        </div>

    </div>
</div>
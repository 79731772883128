import { Directive, ElementRef, Input, OnChanges, SimpleChanges, HostListener, Renderer2 } from '@angular/core';

@Directive({ 
    selector: '[itemDisabled]' 
})

export class ItemDisablerDirective implements OnChanges {

    layer: any;
    hostEl: any;

    // tslint:disable-next-line 
    @Input('itemDisabled') isDisabled = false;


    constructor(private renderer: Renderer2, private el: ElementRef){ 
        console.log('directive');

        this.hostEl = this.el.nativeElement;

        this.renderer.setStyle(this.hostEl, 'position', 'relative');
        this.renderer.setStyle(this.hostEl, 'display', 'block');

        this.layer = this.renderer.createElement('div');
        this.renderer.setStyle(this.layer, 'position', 'absolute');
        this.renderer.setStyle(this.layer, 'top', '0');
        this.renderer.setStyle(this.layer, 'left', '0');
        this.renderer.setStyle(this.layer, 'right', '0');
        this.renderer.setStyle(this.layer, 'bottom', '0');
        this.renderer.setStyle(this.layer, 'background-color', '#ffffff0c');
        this.renderer.setStyle(this.layer, 'z-index', '1000');
        this.renderer.addClass(this.layer, 'disabler-block');

        this.toggleDisabeld();
       
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isDisabled) {
            this.isDisabled = changes.isDisabled.currentValue;
            this.toggleDisabeld();
        }
    }

    toggleDisabeld() {
        if (this.isDisabled) {
            this.renderer.addClass(this.hostEl, 'disabler');
            this.renderer.insertBefore(this.hostEl, this.layer, this.hostEl.firstChild);
        } else {
            this.renderer.removeClass(this.hostEl, 'disabler');
            this.renderer.removeChild(this.hostEl, this.layer);
        }
    }

}
